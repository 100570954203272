import React, { useCallback, useEffect, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from "@mui/icons-material/Add";
import { Formik, FieldArray, ErrorMessage } from "formik";
import {  RecurringInvoiceAddSchema } from "../../Components/Validation";
import ApiSearch from "../../Components/AutoCompleteTextView/ApiSearch";
import { DeleteApiMethod, GetApiMethod, PostApiMethod, PutApiMethod } from "../../Utils/ApiService";
import { errorHandling } from "../../Utils/HelperService";
import { useAppDispatch } from "../../Store/hooks";

import { showLoader } from "../../Store/loaderSlice";
import { UnitInterfaceData } from "../../Dto/unit";
import { DropDown } from "../../Components/DropDown/DropDown";
import {  RecurringInvoiceStatus, YearDateFormat } from "../../Components/Utilities";
import { ErrorTextField } from "../../Components/Error/ErrorTextField";
import { PropertyDetailDTO } from "./NewInvoice";
import RadioButton1 from "../property/components/radioButton";
import { showToaster } from "../../Store/toastSlice";
import cancelIcon from '../../Assets/images/icons/multiply.png';

interface AddRecurringInvoiceProps {
  handleOthers: (type: any) => void;
  refreshPage: () => void;
  isUpdate: boolean; 
  updatPropertyId:any; 
  setUpdate: any;
}

export const AddRecurringInvoice = (props: AddRecurringInvoiceProps) => {
  const [propertySearchLoader, setPropertySearchLoader] = useState(false);
  const [unitSearchLoader, setUnitSearchLoader] = useState(false);
  const [propertyList, setPropertyList] = useState<PropertyDetailDTO[]>([]);
  const [unitList, setUnitList] = useState<UnitInterfaceData[]>([]);
  const [invoiceTypeList, setInvoiceTypeList] = useState<PropertyDetailDTO[]>(
    []
  );
  const [tenantSearchLoader, setTenantSearchLoader] = useState(false);
  const [tenantList, setTenantList] = useState<UnitInterfaceData[]>([]);


  const dispatch = useAppDispatch();
  const [initialValues, setInitialValue] = useState({
    recurringType: "Monthly",
    invoiceId: "",
    propertyId: "",
    ownerUserId: 1,
    type: "Recurring",
    invoicePrefix: "INV-12",
    property: null,
    unit: null,
    propertyUnitId: "",
    dueDateAfter: "",
    tenantId: "",
    tenant: null,
    cycleDay: "",
    recurringStatus: "Active",
    recurring_cycle_day: "",
    data: [
      {
        invoice_type_id: "",
        tax_type: "",
        amount: "",
        description: "",
        invoiceType: null,
      },
    ],
  });
  const [invoiceTypeLoader, setInvoiceTypeLoader] = useState(false);


  const initialApiCall = useCallback(async () => {
    dispatch(showLoader({ showLoader: true }));

    try {
      const [property] = await Promise.all([
        searchProperty(""),
        getInvoiceType(""),
      ]);
      dispatch(showLoader({ showLoader: false }));
    } catch (e) {
      dispatch(showLoader({ showLoader: false }));
    }
  }, []);

  const searchProperty = useCallback((value: string) => {
    setPropertySearchLoader(true);
    PostApiMethod("property/properties/list", {
      searchString: value,
      page: "1",
      limit: "20",
    })
      .then((res) => {
        setPropertySearchLoader(false);
        setPropertyList(res.data.results.data);
        // searchTenant("","","")
      })
      .catch((e) => {
        const error = errorHandling(e);
        setPropertySearchLoader(false);
      });
  }, []);

  const searchUnit = useCallback(
    (value: string, property_id: string, user_id: string) => {
      setUnitSearchLoader(true);
      PostApiMethod("property/unit/getUnitByPropertyId", {
        searchString: value,
        page: "1",
        limit: "10",
        property_id: property_id,
        user_id: user_id,
      })
        .then((res) => {
          setUnitSearchLoader(false);
          setUnitList(res.data.results.data.rows);
        })
        .catch((e) => {
          const error = errorHandling(e);
          setUnitSearchLoader(false);
        });
    },
    []
  );

  const getInvoiceType = useCallback((value: string) => {
    setInvoiceTypeLoader(true);
    PostApiMethod("property/invoice-type/list", {
      searchString: value,
      page: "1",
      limit: "20",
    })
      .then((res) => {
        setInvoiceTypeLoader(false);
        setInvoiceTypeList(res.data.results.data);
      })
      .catch((e) => {
        const error = errorHandling(e);
        setInvoiceTypeLoader(false);
        setInvoiceTypeList([]);
      });
  }, []);

  const searchTenant = useCallback(
    (value: string, unitId: string, user_id: string) => {
      setTenantSearchLoader(true);
      PostApiMethod("property/tenant/getTenantByUnitId", {
        searchString: value,
        unit_id: unitId,
        user_id: 1,
      })
        .then((res) => {
          setTenantSearchLoader(false);
          setUnitSearchLoader(false);
          setTenantList(res.data.results.data);
        })
        .catch((e) => {
          setTenantSearchLoader(false);
          setTenantList([]);
          const error = errorHandling(e);
        });
    },
    []
  );

  useEffect(() => {
    initialApiCall();
  }, []);

  const createInvoice = useCallback((values: any) => {
    dispatch(showLoader({ showLoader: true }));
    PostApiMethod("property/invoice/add", values)
      .then((res) => {
        dispatch(showLoader({ showLoader: false }));
        props.handleOthers({ recurringInvoice: false })
        props.refreshPage();
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "Added successfully",
            showDialog: true,
            timer: "5000",
          })
        );
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
  }, []);

  const updateInvoice = useCallback(
    (values: any) => {
      dispatch(showLoader({ showLoader: true }));
      PutApiMethod("property/invoice/update/" + props.updatPropertyId, values)
        .then((res) => {
          dispatch(showLoader({ showLoader: false }));
          props.setUpdate(false);
          props.handleOthers({ recurringInvoice: false })
          props.refreshPage();
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Updated successfully",
              showDialog: true,
              timer: "5000",
            })
          );
        })
        .catch((e) => {
          dispatch(showLoader({ showLoader: false }));
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
        });
    },
    [props.updatPropertyId]
  );

  useEffect(() => {
    if (props.isUpdate && props.updatPropertyId) {
      GetApiMethod("property/invoice/" + props.updatPropertyId)
        .then((res: any) => {
          const value = res.data.results.data;
           console.log(value,"value");
          const newData = {
            ...res.data.results.data,
            dueDate: YearDateFormat(value.due_date),
            tenantId: value.tenant_id,
            data: res.data.results.data.invoiceItem,
            propertyId: value.property_id,
            propertyUnitId: value.property_unit_id,
            recurringStatus: value.recurring_status,
             recurringType: value.recurring_type,
             recurring_cycle_day: value.recurring_cycle_day ? value.recurring_cycle_day : "",
             dueDateAfter: value.due_date_after,
            // status: 
          };
          delete newData.invoiceItem;
          setInitialValue(newData);
        })
        .catch((e) => {
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
        });
    }
  }, [props.isUpdate, props.updatPropertyId]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={RecurringInvoiceAddSchema}
        enableReinitialize
        onSubmit={(values) => {
          props.isUpdate ? updateInvoice(values) : createInvoice(values);
        }}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className="px-4">
              <Typography className="poppins-family fs-5 fw-bold">
                {`${ props.isUpdate ? "Update " : "Add New"} Recurring Setting`}
              </Typography>
              <Typography className="mt-20 pb-10">Recurring Type</Typography>
              <Box className={`radioBox mt-10`}>
                <RadioButton1
                  name={"monthly"}
                  valueChange={(
                    newValue: string,
                    name: string,
                    indexOfTextField: number
                  ) => {
                    setFieldValue("recurringType", "Monthly");
                  }}
                  label={"Monthly"}
                  value={"monthly"}
                  isChecked={values.recurringType === "Monthly"}
                  indexOfTextField={0}
                />
                <RadioButton1
                  name={"yearly"}
                  valueChange={(
                    newValue: string,
                    name: string,
                    indexOfTextField: number
                  ) => {
                    setFieldValue("recurringType", "Yearly");
                  }}
                  label={"Yearly"}
                  value={"yearly"}
                  isChecked={values.recurringType === "Yearly"}
                  indexOfTextField={0}
                />
                <RadioButton1
                  name={"custom"}
                  valueChange={(
                    newValue: string,
                    name: string,
                    indexOfTextField: number
                  ) => {
                    setFieldValue("recurringType", "Custom");
                  }}
                  label={"Custom"}
                  value={"custom"}
                  isChecked={values.recurringType === "Custom"}
                  indexOfTextField={0}
                />
              </Box>
              <Grid container spacing={4} className="mt-1">
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className="poppins-family">Property</Typography>
                  <ApiSearch
                    value={values.property}
                    onChange={(newValue: any) => {
                      setFieldValue("propertyId", newValue.id);
                      setFieldValue("property", newValue);
                      setFieldValue("unitpropertyUnitIdId", "");
                      setFieldValue("unit", null);
                      searchUnit("", newValue.id, newValue.owner_user_id);
                    }}
                    loading={propertySearchLoader}
                    options={propertyList}
                    searchApi={searchProperty}
                    isOptionEqualToValue={(option, value) => option.property_name === value.property_name}
                    getOptionLabel={(option: PropertyDetailDTO) =>
                      option.property_name
                    }
                  />
                  <ErrorTextField
                    error={errors.propertyId!}
                    touched={touched.propertyId!}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className="poppins-family">Unit Name</Typography>
                  <ApiSearch
                    value={values.unit}
                    onChange={(newValue: any) => {
                      setFieldValue("propertyUnitId", newValue.id);
                      setFieldValue("unit", newValue);
                      searchTenant(
                        "",
                        newValue.id,
                        values.property ? values.property["owner_user_id"] : ""
                      );
                    }}
                    loading={unitSearchLoader}
                    options={unitList}
                    searchApi={(value: string) => {
                      searchUnit(
                        value,
                        values.property ? values.property!["id"] : "",
                        values.property ? values.property["owner_user_id"] : ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => option.unit_name === value.unit_name}
                    getOptionLabel={(option: UnitInterfaceData) =>
                      option.unit_name
                    }
                  />
                  <ErrorTextField
                    error={errors.propertyUnitId!}
                    touched={touched.propertyUnitId!}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className="poppins-family">
                    Select Tenant
                  </Typography>
                  <ApiSearch
                    value={values.tenant}
                    onChange={(newValue: any) => {
                      setFieldValue("tenantId", newValue.id);
                      setFieldValue("tenant", newValue);
                    }}
                    loading={tenantSearchLoader}
                    options={tenantList}
                    searchApi={(value: string) => {
                      searchTenant(
                        "",
                        values.unit ? values.unit!["id"] : "",
                        values.property ? values.property["owner_user_id"] : ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) => option.firstName === value.firstName}
                    getOptionLabel={(option: any) => option.firstName}
                  />
                    <ErrorTextField
                    error={errors.tenantId!}
                    touched={touched.tenantId!}
                  />
                </Grid>
                {
                  values.recurringType === "Custom" && (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography className="poppins-family">
                      Cycle Day
                    </Typography>
                    <TextField
                      className="w-100"
                      variant="standard"
                      name="recurring_cycle_day"
                      placeholder="Type here..."
                      type="number"
                      value={values.recurring_cycle_day}
                      onChange={handleChange}
                    />
  
                    <ErrorTextField
                      error={errors.recurring_cycle_day!}
                      touched={touched.recurring_cycle_day!}
                    />
                  </Grid>
                  )
                }
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography className="poppins-family">
                    Due Date After Invoice Creation
                  </Typography>
                  <TextField
                    className="w-100"
                    variant="standard"
                    name="dueDateAfter"
                    placeholder="Type here..."
                    type="number"
                    value={values.dueDateAfter}
                    onChange={handleChange}
                  />

                  <ErrorTextField
                    error={errors.dueDateAfter!}
                    touched={touched.dueDateAfter!}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography className="poppins-family">Status</Typography>
                    <DropDown
                      value={values.recurringStatus}
                      handleChange={handleChange}
                      name="recurringStatus"
                    >
                      {RecurringInvoiceStatus.map((option: any, index: number) => {
                        return (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </DropDown>
                    <ErrorTextField
                    error={errors.recurringStatus!}
                    touched={touched.recurringStatus!}
                  />
                  </Grid>
              </Grid>
              <FieldArray name="data">
                {({ push, remove }) => (
                  <div>
                    <div>
                      <Box className="mt-4">
                        <Box className="d-flex justify-content-between">
                          <Typography className="poppins-family">
                            Items
                          </Typography>
                          <div
                            onClick={() => {
                              push({
                                invoice_type_id: "",
                                tax_type: "",
                                amount: "",
                                description: "",
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Typography className="poppins-family text-primary">
                              <AddIcon />
                              Add Items
                            </Typography>
                          </div>
                        </Box>
                        {values.data.map((item, index) => {
                          // const error = errors.items![index];
                          return (
                            <Grid
                              container
                              spacing={3}
                              className="mt-2"
                              key={index}
                            >
                              <Grid item xs={12} sm={12} md={3.25} lg={3.25}>
                                <Typography className="poppins-family">
                                  Invoice Type
                                </Typography>

                                <ApiSearch
                                  value={values.data[index].invoiceType}
                                  onChange={(newValue: any) => {
                                    setFieldValue(
                                      `data.${index}.invoice_type_id`,
                                      newValue.id
                                    );
                                    setFieldValue(
                                      `data.${index}.invoiceType`,
                                      newValue
                                    );
                                  }}
                                  loading={invoiceTypeLoader}
                                  options={invoiceTypeList}
                                  searchApi={(value: string) => {
                                    getInvoiceType(value);
                                  }}
                                  isOptionEqualToValue={(option, value) => option.name === value.name}
                                  getOptionLabel={(option: any) => option.name}
                                />
                                <ErrorTextField
                                  error={
                                    <ErrorMessage
                                      name={`data[${index}].invoice_type_id`}
                                    />
                                  }
                                  touched={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3.25} lg={3.25}>
                                <Typography className="poppins-family">
                                  Amount
                                </Typography>
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  type="number"
                                  placeholder="Type here..."
                                  name={`data.${index}.amount`}
                                  value={values.data[index].amount}
                                  onChange={handleChange}
                                />

                                <ErrorTextField
                                  error={
                                    <ErrorMessage
                                      name={`data[${index}].amount`}
                                    />
                                  }
                                  touched={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3.25} lg={3.25}>
                                <Typography className="poppins-family">
                                  Description
                                </Typography>
                                <TextField
                                  className="w-100"
                                  variant="standard"
                                  placeholder="Type here..."
                                  name={`data.${index}.description`}
                                  value={values.data[index].description}
                                  onChange={handleChange}
                                />
                                <ErrorTextField
                                  error={
                                    <ErrorMessage
                                      name={`data[${index}].description`}
                                    />
                                  }
                                  touched={true}
                                />
                              </Grid>
                              {
                                index !== 0 && ( 
                                  <Grid item xs={12} sm={12} md={1} lg={1}>
                                  <img src={cancelIcon} alt="FB" width="25" height="25" style={{cursor:"pointer"}} onClick={async()=>{
                                    const val: any = values.data[index];
                                    if (val.id) {
                                      dispatch(
                                        showLoader({ showLoader: true })
                                      );
                                      await DeleteApiMethod(
                                        "/property/invoice/invoiceItem/" +
                                          val.id
                                      )
                                        .then((res: any) => {
                                          remove(index);
                                          dispatch(
                                            showToaster({
                                              dialogBgColor: "success",
                                              dialogMsg:
                                                "Removed successfully",
                                              showDialog: true,
                                              timer: "5000",
                                            })
                                          );
                                          dispatch(
                                            showLoader({ showLoader: false })
                                          );
                                        })
                                        .catch((e: any) => {
                                          dispatch(
                                            showLoader({ showLoader: false })
                                          );
                                          const error = errorHandling(e);
                                          dispatch(
                                            showToaster({
                                              dialogBgColor: "bg-danger",
                                              dialogMsg: error,
                                              showDialog: true,
                                              timer: "5000",
                                            })
                                          );
                                        });
                                    } else {
                                      remove(index);
                                    }
                                  }}/>
                                </Grid>
                                )
                              }
                             
                            </Grid>
                          );
                        })}
                      </Box>
                    </div>
                  </div>
                )}
              </FieldArray>

              <Box className="mt-4">
                <button
                  className="btn-white border-0"
                  onClick={() =>{ props.handleOthers({ recurringInvoice: false })
                  props.setUpdate(false);
                }}
                >
                  <ChevronLeftIcon sx={{ fontSize: "25px" }} />
                  Back
                </button>
                <button
                  className="btn-danger ms-3"
                  type="submit"
                >
                  Submit
                </button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
