import React, { useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import {
  Grid,
  InputAdornment,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconSearch from "../../Assets/images/search.svg";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import Actions from "../../Components/Actions/Actions";
import IconAssets from "../../Assets/images/icon_assets_red.svg";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import QRCode from "../../Assets/images/qr-code.png";
import BarCode from "../../Assets/images/barcode.png";
import AssetImage from "../../Assets/images/unit-default-image.png";
import IconPdf from "../../Assets/images/pdf-icon.svg";

const AntTabs = styled(Tabs)({
  marginLeft: "15px",
  padding: "0",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D73",
  },
});

const AntTab = styled(Tab)({
  fontSize: "13px",
  fontFamily: "Poppins",
  "&.Mui-selected": {
    color: "#FF7d73",
  },
});

export default function AssetsDetailSubTab() {
  const [tabValue, setTabValue] = useState(0);
  const [addAsset, setAddAsset] = useState(true);
  const [isAddNewAssetVisible, setIsAddNewAssetVisible] = useState(false);

  const handleChange = (e: any, val: any) => {
    setIsAddNewAssetVisible(false);
    setTabValue(val);
    setAddAsset(val === 0);
  };

  return (
    <>
      <Card elevation={0} className="mt-4 radius-12 assets-detail-tab">
        <Box className="d-flex align-items-center">
          <Typography className="psemiboldfont page-header-text block m-0">
            Asset Details
          </Typography>
        </Box>
        <AntTabs value={tabValue} onChange={handleChange} className="p-0 m-0">
          <AntTab label="Basic Information" className="tab-menu" />
          <AntTab label="Asset Valuation" className="tab-menu" />
          <AntTab label="Other Information" className="tab-menu" />
          <AntTab label="Images" className="tab-menu" />
          <AntTab label="Documents" className="tab-menu" />
        </AntTabs>

        <CustomTabPanel value={tabValue} index={0}>
          <Box className="py-4">
            <p className="fw-semibold">Basic Information</p>
            <Box className="d-flex">
              <img src={BarCode} alt="" className="me-4" />
              <img src={QRCode} alt="" />
            </Box>
          </Box>
          <Box className="table-striped table-striped-header">
            <p className="fw-semibold">Basic Information</p>
            <table>
              <tbody>
                <tr>
                  <th>Asset ID</th>
                  <td>ABC123456</td>
                </tr>
                <tr>
                  <th>Asset Model No</th>
                  <td>123456</td>
                </tr>
                <tr>
                  <th>Asset Name</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Asset Status</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Date of Purchase</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>QR Code</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Category</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Sub Category</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Supplier</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Department</th>
                  <td>Lenovo Laptop</td>
                </tr>
                <tr>
                  <th>Sub Department</th>
                  <td>Lenovo Laptop</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={1}>
          <Box className="table-striped table-striped-header">
            <p className="fw-semibold">Asset Valuation</p>
            <table>
              <tbody>
                <tr>
                  <th>Unit Price</th>
                  <td>10000</td>
                </tr>
                <tr>
                  <th>Residual Value</th>
                  <td>90000</td>
                </tr>
                <tr>
                  <th>Salvage Value</th>
                  <td>90000</td>
                </tr>
                <tr>
                  <th>Depreciation Method</th>
                  <td>Straight Line</td>
                </tr>
                <tr>
                  <th>Depreciation Percentage</th>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={2}>
          <Box className="table-striped table-striped-header">
            <p className="fw-semibold">Other Information</p>
            <table>
              <tbody>
                <tr>
                  <th>Unit Price</th>
                  <td>10000</td>
                </tr>
                <tr>
                  <th>Date of Manufacture</th>
                  <td>90000</td>
                </tr>
                <tr>
                  <th>Year of Valuation</th>
                  <td>90000</td>
                </tr>
                <tr>
                  <th>
                    Warranty
                    <small className="fw-normal" style={{ color: "#808080" }}>
                      (In Months)
                    </small>
                  </th>
                  <td>Straight Line</td>
                </tr>
                <tr>
                  <th>Depreciation Percentage</th>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={3}>
          <Box>
            <p className="fw-semibold">Images</p>
            <Box className="d-flex">
              <img
                src={AssetImage}
                alt=""
                width="100px"
                height="100px"
                className="me-3"
              />
              <img src={AssetImage} alt="" width="100px" height="100px" />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={4}>
          <Box>
            <p className="fw-semibold">Documents</p>
            <Box className="d-flex pdfinfo p-4 align-items-center">
              <img src={IconPdf} width="33px" className="me-2" /> Purchase
              Receipt.pdf
            </Box>
          </Box>
        </CustomTabPanel>
      </Card>
    </>
  );
}
