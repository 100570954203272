import React, { useState } from "react";
import styles from "./newProperty.module.css";
import SubHeading1 from "../components/subHeading1";
import PropertyInformationForm from "./forms/propertyInformation";
import LocationForm from "./forms/location";
import ImageForm from "./forms/image";
import { UnitApiResponse } from "../utlity/interface";
import { Box } from "@mui/material";
import UnitScreen from "./forms/UnitScreen";
import { RentScreen } from "./forms/RentScreen";

interface newProperty1Type {
  backBtn: Function;
}

export const currentPageList = [
  "Property Information",
  "Location",
  "Unit",
  "Rent & Charges",
  "Images",
];

const NewProperty1: React.FunctionComponent<newProperty1Type> = ({
  backBtn,
}) => {
  const [CurrentAddPropertySection, setCurrentAddPropertySection] = useState(
    currentPageList[0]
  );
  const [CreatedPropertyIdFromApi, setCreatedPropertyIdFromApi] = useState("");
  const [CreatedUserIdFromApi, setCreatedUserIdFromApi] = useState("");
  const [CreatedCountryIdFromApi, setCreatedCountryIdFromApi] = useState("");
  const [CreatedUnitDetailsFromApi, setCreatedUnitDetailsFromApi] = useState();
  const [CreatedNo_of_unitsFromApi, setCreatedNo_of_unitsFromApi] =
    useState("");
  const [unitDetails, setUnitDetails] = useState();
  const [propertyDetails, setPropertyDetails] = useState();
  function backBtnFunc(event: any) {
    backBtn();
  }

  function submitBtnFunc(currentComponent: any, propertyId: string) {}
  function formChange(currentComponent: any) {
    currentComponent.toString() === "Property Information"
      ? setCurrentAddPropertySection(currentPageList[1])
      : currentComponent.toString() === "Location"
      ? setCurrentAddPropertySection(currentPageList[2])
      : currentComponent.toString() === "Unit"
      ? setCurrentAddPropertySection(currentPageList[3])
      : currentComponent.toString() === "Rent & Charges"
      ? setCurrentAddPropertySection(currentPageList[4])
      : //backBtnFunc("")
        // ? setCurrentAddPropertySection(currentPageList[4])
        setCurrentAddPropertySection("");
  }

  function submitBtnForProperyInformation(
    currentComponent: any,
    propertyId: string,
    propertyUserId: string,
    propertyCountryId: string,
    No_of_unitsFromApi: string,
    propertyDetails: any
  ) {
    setCreatedPropertyIdFromApi(propertyId);
    setCreatedUserIdFromApi(propertyUserId);
    setCreatedCountryIdFromApi(propertyCountryId);
    setCreatedNo_of_unitsFromApi(No_of_unitsFromApi);
    formChange(currentComponent);
    setPropertyDetails(propertyDetails);
  }
  function submitBtnForUnitForm(currentComponent: any, unitId: any) {
    setCreatedUnitDetailsFromApi(unitId);
    formChange(currentComponent);
  }
  function submitBtmForRentAndCharges(currentComponent: string) {
    formChange(currentComponent);
  }
  return (
    <>
      <Box className="my-30" sx={{ margin: "30px 0" }}>
        <SubHeading1
          headingProp="Add New Property"
          subheadingProp={CurrentAddPropertySection}
        />
      </Box>
      {CurrentAddPropertySection === "Property Information" ? (
        <PropertyInformationForm
          backBtn={backBtnFunc}
          submitBtn={submitBtnForProperyInformation}
        />
      ) : CurrentAddPropertySection === "Location" ? (
        <Box>
          <LocationForm
            backBtn={backBtnFunc}
            submitBtn={formChange}
            CreatedPropertyDetailsFromApi={CreatedPropertyIdFromApi}
            CreatedCountryIdFromApi={CreatedCountryIdFromApi}
          />
        </Box>
      ) : CurrentAddPropertySection === "Unit" ? (
        <UnitScreen
          backBtn={backBtnFunc}
          submitBtn={submitBtnForUnitForm}
          CreatedPropertyIdFromApi={CreatedPropertyIdFromApi}
          CreatedUserIdFromApi={CreatedUserIdFromApi}
          CreatedNo_of_unitsFromApi={CreatedNo_of_unitsFromApi}
          propertyDetails={propertyDetails}
          setUnitDetails={(val: any) => {
            setUnitDetails(val);
          }}
        />
      ) : CurrentAddPropertySection === "Rent & Charges" ? (
        // <RentAndChargesForm
        //   backBtn={backBtnFunc}
        //   submitBtn={submitBtmForRentAndCharges}
        //   CreatedUnitDetailsFromApi={CreatedUnitDetailsFromApi}
        //   CreatedNo_of_unitsFromApi={CreatedNo_of_unitsFromApi}
        // />
        <RentScreen
          backBtn={backBtnFunc}
          submitBtn={submitBtmForRentAndCharges}
          unitDetails={CreatedUnitDetailsFromApi}
        />
      ) : CurrentAddPropertySection === "Images" ? (
        <ImageForm
          backBtn={backBtnFunc}
          submitBtn={submitBtnFunc}
          CreatedUnitIdFromApi={CreatedUnitDetailsFromApi}
          CreatedNo_of_unitsFromApi={CreatedNo_of_unitsFromApi}
          CreatedPropertyIdFromApi={CreatedPropertyIdFromApi}
        />
      ) : (
        <p>Property and units added successfully</p>
      )}
    </>
  );
};
export default NewProperty1;
