import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import FormTitle from "../../../Components/FormTitle";
import { Switch, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { HelpDeskTypeAddSchema } from "../../../Components/Validation";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Typography } from "antd";
import { PostApiMethod, PutApiMethod } from "../../../Utils/ApiService";
import { showLoader } from "../../../Store/loaderSlice";
import { errorHandling } from "../../../Utils/HelperService";
import { showToaster } from "../../../Store/toastSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { VisuallyHiddenInput } from "../AddHelpDesk";
import { ButtonPrimary } from "../../../Components/Button/ButtonPrimary";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface HelpDeskTypeFormModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value: { name: string; status: boolean; image: null | string; id: string };
  isUpdate: boolean;
  refreshPage: () => void;
}

interface FormValues {
  name: string;
  status: boolean;
  image: null | string;
  id: string;
}

export default function HelpDeskTypeFormModal(
  props: HelpDeskTypeFormModalProps
) {
  const { isOpen, setIsOpen, value, isUpdate, refreshPage } = props;
  const loader = useAppSelector((state) => state.loader);
  const dispatch = useAppDispatch();

  const [initialValue, setInitialValue] = useState<FormValues>({
    name: "",
    status: true,
    image: null,
    id: "",
  });

  const addType = useCallback(
    (values: { name: string; status: boolean; image: any; id: string }) => {
      dispatch(showLoader({ showLoader: true }));
      const form = new FormData();
      form.append("name", values.name);
      form.append("status", values.status.toString());
      form.append("image", values.image);

      PostApiMethod("helpDeskType/add", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Type added successfully",
              showDialog: true,
              timer: "5000",
            })
          );
          resetForm();
          setIsOpen(false);
          refreshPage();
          dispatch(showLoader({ showLoader: false }));
        })
        .catch((e) => {
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    },
    []
  );

  const updateType = useCallback(
    (values: { name: string; status: boolean; image: any; id: string }) => {
      dispatch(showLoader({ showLoader: true }));
      const form = new FormData();
      form.append("name", values.name);
      form.append("status", values.status.toString());
      form.append("image", values.image);

      PutApiMethod("helpDeskType/update/" + values.id, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Type updated successfully",
              showDialog: true,
              timer: "5000",
            })
          );
          resetForm();
          setIsOpen(false);
          refreshPage();
          dispatch(showLoader({ showLoader: false }));
        })
        .catch((e) => {
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    },
    []
  );

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: HelpDeskTypeAddSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (isUpdate) {
        updateType(values);
      } else {
        addType(values);
      }
    },
  });

  useEffect(() => {
    if (value) setInitialValue(value);
  }, [value]);
  return (
    <div style={{ margin: "25%" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <form onSubmit={handleSubmit}>
             
                                          <FormTitle text={`${isUpdate ? "Update" : "Add"} Ticket Type`} extenalCss="poppins-family fw-bold mb" />


              <FormTitle text="Name" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here..."
                className="form-control"
                name="name"
                onChange={handleChange}
                value={values.name}
              />
              {errors.name && touched.name && (
                <Typography className="text-danger">{errors.name}</Typography>
              )}
              {isUpdate && (
                <>
                  <FormTitle text="Status" extenalCss="mt" />
                  <Switch
                    checked={values.status}
                    name="status"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </>
              )}
              <FormTitle text="Thumbnail" extenalCss="mt" />

              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={(e: any) => {
                  setFieldValue("image", e.target.files[0]);
                }}
              >
                Upload file
                <VisuallyHiddenInput type="file" multiple />
              </Button>
              {errors.image && touched.image && (
                <Typography className="text-danger">{errors.image}</Typography>
              )}
              <Box
                sx={{ mt: 4, display: "flex", justifyContent: "flex-start" }}
                className="d-flex"
              >
                <Button
                  className="btn-white btn-back-default"
                  onClick={() => {
                    setIsOpen(false);
                    resetForm();
                  }}
                >
                  <ChevronLeftIcon className="fs-20" />
                  Back
                </Button>

                <ButtonPrimary
                  type="submit"
                  text={isUpdate ? "Update" : "Add"}
                  className="ms-2 modal-submit-btn"
                />
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
