import React, { useCallback, useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import {DisplayErrorMessages, isNotEmpty } from "../../../Utils/HelperService";
import TableRow from "@mui/material/TableRow";
import Profile from "../../../Assets/images/profile.png"
import styles from "./tables.module.css";
import IconSearch from "../../../Assets/images/search.svg";
import { useAppDispatch } from "../../../Store/hooks";
import { useNavigate } from "react-router-dom";
import { showLoader } from "../../../Store/loaderSlice";
import { DeleteApiMethod1, GetApiMethod1 } from "../api_service";
import { showToaster } from "../../../Store/toastSlice";
import RentAndChargesForm from "../addNewProperty/forms/rendAndCharges";
import UnitForm from "../addNewProperty/forms/unit";
import Pagination from "../../../Components/Pagination/Pagination"
import Actions from "../../../Components/Actions/Actions";
import { Box, InputAdornment, TextField, Typography,IconButton,Menu,MenuItem,TableFooter } from "@mui/material";
import { GetApiMethod, PostApiMethod } from "../../../Utils/ApiService";

import Button from "@mui/material/Button";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
interface DataType {
  key: String;
  sIno: String;
  unit: String;
  property: String;
  image: string;
  tenant: String;
  user_id: string;
  property_id: string;
}
const ITEM_HEIGHT = 48;

const PropertyTable2 = (props: any) => {
  const [unitList, setunitList] = useState<any[]>([]);
  const [totalUnitCount, settotalUnitCount] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [screenType, setScreenType] = useState("unitlist");
  const [CreatedUnitDetailsFromApi, setCreatedUnitDetailsFromApi] = useState();
  const [CreatedNo_of_unitsFromApi, setCreatedNo_of_unitsFromApi] =useState("1");
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState();
  const [CreatedUserIdFromApi, setCreatedUserIdFromApi] = useState("");
  const [CreatedPropertyIdFromApi, setCreatedPropertyIdFromApi] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [cOpen, setcOpen] = useState<Number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const options = ["Edit unit", "Edit rent" , "Delete"];
  const [page, setPage] = useState({ page: 1, limit: 5, count: 0 });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
 
  const handleClose = (e: any, data: any) => {
    setcOpen(0);
    setAnchorEl(null);
    if (e === "Edit rent") {
      rentEdit(data)
    }
    if (e === "Edit unit") {
    unitEdit(data)
    }
    if (e === "Delete") {
    unitDelete(data)
    }
  };
  const unitEdit = useCallback((record: any) => {
    setCreatedUserIdFromApi(record.user_id);
    setCreatedPropertyIdFromApi(record.property_id);
    setScreenType("unitscreen");
    setSelectedValues(record);
  }, []);
  const handleClick = (id: Number, event: React.MouseEvent<HTMLElement>) => {
    setcOpen(cOpen === id ? 0 : id);
    setAnchorEl(event.currentTarget);
  };
  const rentEdit = useCallback((record: any) => {
    setCreatedUnitDetailsFromApi(record.id);
    setSelectedValues(record);
    setScreenType("rent");
  }, []);
  async function unitDelete(unitList: any) {
    try {
      const unitId = unitList.id;
      const UserId = unitList.user_id;
      const PropertyId = unitList.property_id;
      const unit_name = unitList.unit_name;
      const apiBody = {
        property_id: PropertyId,
        UserId: UserId,
      };

      const res = await DeleteApiMethod1(
        "property/unit/delete/" + unitId,
        apiBody
      );
      if (res["data"]["code"] === 201) {
        console.log("res: " + JSON.stringify(res["data"]["results"]["data"]));
        fetchPropertyList();
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: unit_name + " Unit Deleted successfully",
            showDialog: true,
            timer: "3000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      }
    } catch (e: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          // dialogMsg: e.response.data.message,
          dialogMsg: "Error, Try again",
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  }
  const fetchPropertyList = async () => {
    dispatch(showLoader({ showLoader: true }));
    try {
   
      const res = await PostApiMethod("property/unit/list",{searchString: searchValue, page: pagination.current,limit: pagination.pageSize});
      if (res["data"]["code"] === 200) {
        const PropertyDetails = res["data"]["results"]["data"];
        const totalCount = res["data"]["results"]["totalCount"];
        settotalUnitCount(totalCount);
        setunitList(PropertyDetails);
        dispatch(showLoader({ showLoader: false }));
      }
    } catch (e: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: "Error, Try again",
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };

  useEffect(() => {
    getUsers(page.page, page.limit);
  }, [searchValue]);


  const rowsPerPage = useCallback(
    (val: any) => {
      setPage({ ...page, page: val.page, limit: val.limit });
      getUsers(val.page, val.limit);
    },
    [page]
  );
  const getUsers = async (page: any, limit: any) => {
    dispatch(showLoader({ showLoader: true }));
    setLoading(true);
    try {
      let res = await PostApiMethod("property/unit/list", {
        page: page,
        limit: limit,
        searchString: searchValue,
      });
      if (res["data"]["code"] === 200) {
        setunitList(res["data"]["results"]["data"]);
        setPage((prevPage) => ({
          ...prevPage,
          count: res["data"]["results"]["totalCount"],
        }));
      }
      settotalUnitCount(res["data"]["results"]["totalCount"])
      dispatch(showLoader({ showLoader: false }));
    } catch (e) {
      const error = DisplayErrorMessages(e);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
    setLoading(false);
  };


  return (
    <div>
      {screenType === "unitlist" ? (
        <Box className="table-striped">
          <Box
            className="w-full max-w-400 my-30"
            sx={{
              width: "400px",
              margin: "30px 0",
            }}
          >
            <Typography variant="body2">Search</Typography>
            <TextField
              variant="standard"
              placeholder="Type here"
              sx={{
                width: "100%",
              }}
              onChange={(e: any) => {
                setSearchValue(e.target.value)
              }}
              className="w-full"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconSearch} width="18px" height="18px" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {totalUnitCount > 0 ? (
 <TableContainer className="table-striped">
 <Table>
   <TableHead>
     <TableRow>
       <TableCell>Unit</TableCell>
       <TableCell>Property</TableCell>
       <TableCell>Image</TableCell>
       <TableCell>Tenant</TableCell>
       <TableCell>Action</TableCell>
     </TableRow>
   </TableHead>
   <TableBody>
     {unitList &&
       unitList.map((unit: any, index: any) => (
         <TableRow key={index}>
           <TableCell>
           {isNotEmpty(unit.unit_name) ? unit.unit_name : ""}
           </TableCell>
           <TableCell>
             {isNotEmpty(unit.property)
               ? unit.property.property_name
               : ""}
           </TableCell>
           <TableCell>
                        {unit.image != "" ? (
                          <img
                            src={process.env.REACT_APP_API_URL+ '/'+unit.image}
                            alt="profile"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={Profile}
                            alt="profile"
                            width={40}
                            height={40}
                          />
                        )}
                      </TableCell>
           <TableCell>
             {isNotEmpty(unit.tenantName) ? unit.tenantName : ""}
           </TableCell>
           <TableCell>
             <IconButton
               aria-label="more"
               id={"long-button_" + unit.id}
               aria-controls={
                 cOpen === unit.id
                   ? "long-menu_" + unit.id
                   : undefined
               }
               aria-expanded={
                 cOpen === unit.id ? "true" : undefined
               }
               aria-haspopup="true"
               onClick={(e) => {
                 handleClick(unit.id, e);
               }}
             >
               <Typography
                 className="poppins-family"
                 color="primary"
               >
                 Action
               </Typography>
               <KeyboardArrowDownTwoToneIcon color="primary" />
             </IconButton>
             <Menu
               id={"long-menu_" + unit.id}
               MenuListProps={{
                 "aria-labelledby": "long-button_" + unit.id,
               }}
               anchorEl={anchorEl}
               open={cOpen === unit.id}
               onClose={handleClose}
               PaperProps={{
                 style: {
                   maxHeight: ITEM_HEIGHT * 4.5,
                   width: "20ch",
                 },
               }}
             >
               {options.map((option: any) => (
                 <MenuItem
                   key={option}
                   onClick={() => {
                     handleClose(option, unit);
                   }}
                 >
                   {option}
                 </MenuItem>
               ))}
             </Menu>
           </TableCell>
         </TableRow>
       ))}
   </TableBody>
   {unitList.length !== 0 && (
                <TableFooter>
                  <TableRow>
                    <Pagination
                      pagination={page}
                      colSpan={6}
                      rowsPerPageChange={rowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              )}
 </Table>
</TableContainer>
          ): (
          <div style={{ textAlign: "center" }}>
            <Typography>No data</Typography>
          </div>
          )}
        </Box>
      ) : screenType === "unitscreen" ? (
        <>
          <UnitForm
            backBtn={() => {
              setScreenType("unitlist");
            }}
            submitBtn={() => {
              fetchPropertyList();
              setScreenType("unitlist");
            }}
            CreatedPropertyDetailsFromApi={CreatedPropertyIdFromApi}
            CreatedUserIdFromApi={CreatedUserIdFromApi}
            CreatedNo_of_unitsFromApi={CreatedNo_of_unitsFromApi}
            forUpdatePropertyValues={selectedValues}
            isForUpdate={true}
          />
        </>
      ) : (
        <>
          <RentAndChargesForm
            backBtn={() => {
              setScreenType("unitlist");
            }}
            submitBtn={() => {
              fetchPropertyList();
              setScreenType("unitlist");
            }}
            CreatedUnitDetailsFromApi={CreatedUnitDetailsFromApi}
            CreatedNo_of_unitsFromApi={CreatedNo_of_unitsFromApi}
            updateValues={selectedValues}
            isUpdate={true}
          />
        </>
      )}
    </div>
  );
};

export default PropertyTable2;
