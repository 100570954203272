import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../Store/hooks';
import { DeleteApiMethod, PostApiMethod } from '../../Utils/ApiService';
import { showLoader } from '../../Store/loaderSlice';
import { errorHandling } from '../../Utils/HelperService';
import { showToaster } from '../../Store/toastSlice';
import { InvoiceList } from './InvoiceList';

interface AllProps {
    status: string;
    searchString: string;
    propertyId: string;
    editInvoice: (val:any) => void;
}

const All = (props:AllProps) => {
    const [allInvoices, setAllInvoices] = useState([]);
    const [page, setPage] = useState({ page: 1, limit: 10, count: 0 })
    const dispatch = useAppDispatch();

    useEffect(() => {
        getAllInvoices(1, 10);
    }, [props.searchString,props.propertyId]);
    const getAllInvoices = useCallback((page: number, limit: number) => {
        dispatch(showLoader({showLoader:true}));
        let params:any = { page: page, limit: limit, searchString:props.searchString,propertyId: props.propertyId, type: "Normal" };
        if(props.status !== "All") {
            params.status = props.status
        }
        PostApiMethod("property/invoice/list",params ).then((response) => {
            setAllInvoices(response.data.results.data);
            dispatch(showLoader({ showLoader: false }));
            setPage((prevPage) => ({
                ...prevPage,
                count: response["data"]["results"]["totalCount"]
            }))
        }).catch((e) => {
            dispatch(showLoader({ showLoader: false }));

        })
    }, [props]);

    const deleteInvoice = useCallback((deleteId:String) =>{
        dispatch(showLoader({showLoader:true}));
        DeleteApiMethod("property/invoice/"+deleteId).then((res) => {
            getAllInvoices(page.page,page.limit);
            dispatch(showLoader({ showLoader: false }));
            dispatch(
                showToaster({
                    dialogBgColor: "success",
                    dialogMsg: "Deleted successfully",
                    showDialog: true,
                    timer: "5000",
                }));

        }).catch((e:any)=>{
            const error = errorHandling(e);
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    dialogMsg: error,
                    showDialog: true,
                    timer: "5000",
                })
            );
            dispatch(showLoader({ showLoader: false }));

        })
    },[])

    const rowsPerPage = useCallback((val: any) => {
        setPage({ ...page, page: val.page, limit: val.limit })
        getAllInvoices(val.page, val.limit);
    }, [page])
    return (
        <>
            <Box>
                <InvoiceList listValues={allInvoices} rowsPerPage={rowsPerPage} page={page} deleteInvoice={deleteInvoice} editInvoice={props.editInvoice}/>
            </Box>
        </>
    )
}

export default All