import React, { useContext, useState } from 'react';
import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography, TextField, Chip, IconButton, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import { PropertyContext } from './Main';
import Pagination from '../../Components/Pagination/Pagination';
import Profile from "../../Assets/images/profile.png"

const Unit = () => {
  const property = useContext(PropertyContext);
  const [anchorEl, setAnchorEl] = useState(null)
  const [cOpen, setcOpen] = useState(0);
  const handleClose = () => {
    setcOpen(0);
    setAnchorEl(null);
  }

  const handleClick = (id,event) => {
    setcOpen(cOpen === id ? 0 : id);
    setAnchorEl(event.currentTarget);
  }
  return (
    <>
      <Box className="mt-3">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography className='fw-bold'>Search</Typography>
            <TextField type="text" variant="standard" className='form-control' placeholder='Type here...' onChange={(e) => {property.unitSearchString(e.target.value)}} /> 
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} className='mt-3'>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>SI.no</TableCell> */}
              <TableCell>Unit</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Tenant</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {property.unitList.length !== 0 && property.unitList.map((val,key) => (
              <TableRow key={key}>
                {/* <TableCell></TableCell> */}
                <TableCell>{val.unit_name}</TableCell>
                <TableCell>{val.property && val.property.property_name}</TableCell>
                <TableCell>
                  {val.image ? <img src={process.env.REACT_APP_API_URL+ '/'+val.image} alt="unitImages" width={40} height={40}/> 
                  : <img src={Profile} alt="profile" width={40} height={40} /> }</TableCell>
                <TableCell>{val.tenantName}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="more"
                    id={"long-button_" + val.id}
                    aria-controls={
                      cOpen === val.id
                        ? "long-menu_" + val.id
                        : undefined
                    }
                    aria-expanded={
                      cOpen === val.id ? "true" : undefined
                    }
                    aria-haspopup="true"
                    onClick={(e) => {
                      handleClick(val.id, e);
                    }}
                  >
                    <Typography
                      className="poppins-family"
                      color="primary"
                    >
                      Action
                    </Typography>
                    <KeyboardArrowDownTwoToneIcon color="primary" />
                  </IconButton>
                  <Menu
                    id={"long-menu_" + val.id}
                    MenuListProps={{
                      "aria-labelledby": "long-button_" + val.id,
                    }}
                    anchorEl={anchorEl}
                    open={cOpen === val.id}
                    onClose={handleClose}
                  >
                    <MenuItem className='text-primary' onClick={() => property.onEditUnit(val)}>Edit</MenuItem>
                    <MenuItem className='text-danger'>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
            {property.propertyList.length === 0 && <TableRow>
              <TableCell colSpan={7}>No Data</TableCell>
            </TableRow>}
          </TableBody>
          <Pagination pagination={property.unitPage} rowsPerPage={10} rowsPerPageChange={property.handleUnitNextPage}/>
        </Table>
      </TableContainer>
    </>
  )
}

export default Unit