import React, { useState, useContext, createContext, useCallback } from 'react';
import { TextField, Box, Typography } from '@mui/material';
import RequestTable from './RequestTable';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { VisitorContext } from './Visitor';
import VisitorRequestDetails from './VisitorRequestDetails';
import { GetApiMethod, PostApiMethod, PutApiMethod } from "../../Utils/ApiService";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import Grid from '@mui/material/Grid';

export const VisitorReqDetails = createContext()

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height:325,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
  

const VisitorRequest = () => {
    const visitor = useContext(VisitorContext);
    const [view, setView] = useState(true);
    const [visitReqDetails, setVisitReqDetails] = useState({}) 
    const [modalOpen, setModalOpen] = useState(false);
    const [updateDetails, setUpdateDetails] = useState({});
    const [history, setHistory] = useState([]);
    const dispatch = useAppDispatch();

    const viewShow = async(val) => {
        dispatch(showLoader({ showLoader: true }));
        let response = await GetApiMethod(`property/visitor/${val.id}`,{});
        if(response["data"]["error"] === false){
            setVisitReqDetails(response["data"]["results"]["data"])   
        }

        let res = await PostApiMethod(`property/visitor/list`,{user_id:val.user_id});
        if(res["data"]["error"] === false){
            setHistory(res["data"]["results"]["data"])
            setView(false)
        }
        dispatch(showLoader({ showLoader: false }));
    }

    const statusChanged = (params) => {
       
        var in_time = null, out_time = null;
        if(params.in_time){
            in_time  = params.in_time.split("T")
            in_time = in_time[1].split(".")[0]
        }

        if(params.out_time){
            out_time = params.out_time.split("T")
            out_time = out_time[1].split(".")[0]
        }
        setUpdateDetails({...params,in_time:in_time,out_time:out_time})
        setModalOpen(true)
    }

    const handleUpdate = async() => {
        dispatch(showLoader({ showLoader: true }));
        setModalOpen(false)
        let formData = new FormData();
        formData.append('status',updateDetails["status"])
        formData.append('in_time',updateDetails["in_time"])
        formData.append('out_time',updateDetails["out_time"])
        let response = await PutApiMethod(`property/visitor/update/${updateDetails["id"]}`,formData)
        if(response["data"]["error"] === false){
            dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: "Status Updated",
                    showDialog: true,
                    timer: "3000",}))
            visitor.getRequestList(visitor.page);
        }else{
            dispatch(showToaster({dialogBgColor: "bg-danger",
                    dialogMsg: "Failed",
                    showDialog: true,
                    timer: "3000",}))
        }
        dispatch(showLoader({ showLoader: false }));
    }

    const onBackVisitorReqList = useCallback(() => {
        setView(true)
    },[view])
    return (
    <>
        <VisitorReqDetails.Provider value={{
            visitReqDetails:visitReqDetails,
            onBackVisitorReqList:onBackVisitorReqList,
            history:history
        }}>
            {view ? <>
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold mt-3'>Search</Typography>
                        <TextField 
                            placeholder='Type here'
                            variant='standard'
                            className='w-100'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => visitor.searchString(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="mt-3">
                <RequestTable data={visitor.reqData} view={viewShow} statusChanged={statusChanged} handleEdit={visitor.handleEdit} />
            </Box>
            </>:<VisitorRequestDetails />}

            
        </VisitorReqDetails.Provider>
        {/* Status Changed */}
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        >
            <Box sx={style}>
                <Box className="border-bottom p-3 d-flex justify-content-between align-items-center">
                    <Typography className='fw-bold fs-5'>Status Changed</Typography>
                    <CloseIcon sx={{cursor:'pointer'}} onClick={() => setModalOpen(false)}/>
                </Box>
                <Box className="p-4" >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography className='fw-bold'>Status:</Typography>
                            <Select className='w-100' variant="standard" value={updateDetails["status"]} onChange={(e) => setUpdateDetails({...updateDetails,status:e.target.value})}>
                                <MenuItem value="Upcoming">Upcoming</MenuItem>
                                <MenuItem value="Closed">Closed</MenuItem>
                                <MenuItem value="Expired">Expired</MenuItem>
                                <MenuItem value="Cancelled">Cancelled</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Typography className='fw-bold'>In Time</Typography>
                            <TextField 
                                variant='standard'
                                className="w-100"
                                type="time"
                                name="in_time"
                                value={updateDetails["in_time"]}
                                onChange={(e) => setUpdateDetails({...updateDetails,in_time:e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Typography className='fw-bold'>Out Time</Typography>
                            <TextField 
                                variant='standard'
                                className="w-100"
                                type="time"
                                name="out_time"
                                value={updateDetails["out_time"]}
                                onChange={(e) => setUpdateDetails({...updateDetails,out_time:e.target.value})}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box className="border-top p-3 d-flex justify-content-end ">
                    <Button variant="contained" onClick={handleUpdate}>Update</Button>
                </Box>
            </Box>
        </Modal>
    </>
    )
}

export default VisitorRequest