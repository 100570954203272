import * as Yup from "yup";

export const parkingSchema = Yup.object().shape({
    parking_no: Yup.string().required("Parking No field is required"),
    unit_id: Yup.string().required("Unit field is required"),
    // vehicle_4_wheeler: Yup.string().required("Vehicle 4 wheeler field is required"),
    // vehicle_2_wheeler: Yup.string().required("Vehicle 2 wheeler field is required"),
    billing_cycle: Yup.string().required("Billing cycle field is required"),
    // visitor_name: Yup.string().required("Visitor Name is required"),
    fees: Yup.string().required("No of people field is require")
}) 