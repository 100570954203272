import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./datePicker.module.css";
import styled from "@emotion/styled";
import IconCalendar from "../../../Assets/images/calendar-outline.svg";
import { display } from "@mui/system";

interface BasicDatePickerProps {
  label: string;
  name: string;
  val?: string;
  valueChange: Function;
  index?: number;
}
export default function BasicDatePicker(props: BasicDatePickerProps) {
  const [DatePick, setDatePick] = useState<Dayjs | null>();
  const [DateSelect, setDateSelect] = useState();
  function setvalues(date: any) {
    // setDatePick(date);
    setDateSelect(date);
    props.valueChange(date, props.name);
  }
  const OpenPickerIcon = styled.img({
    width: "24px", // Adjust icon size if needed
    height: "24px",
    cursor: "pointer",
  });
  return (
    <>
      <div className={styles.box} style={{ marginTop: "12px" }}>
        <label className="">{props.label}</label>
        <div className={styles.datePickBox}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                "& .MuiInputAdornment-root": {
                  display: "contents !important",
                },
                "& .MuiIconButton-root": {
                  padding: "0 !important",
                  width: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                  borderBottom: "1px solid #c7c7c7 !important",
                  borderRadius: 0,
                },
              }}
              label=""
              value={DatePick}
              onChange={(newVal) => setvalues(newVal)}
              defaultValue={dayjs(props.val)}
              slots={{
                openPickerIcon: () => (
                  <OpenPickerIcon src={IconCalendar} alt="Calendar Icon" />
                ),
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </>
  );
}
