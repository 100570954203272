import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface BackButtonProps {
    onClick : () => void;
}

export const BackButton = (props:BackButtonProps) => {
    return (
        <Button
        className="btn-white btn-back-default"
        onClick={props.onClick}
      >
        <ChevronLeftIcon className="fs-20" />
        Back
      </Button>
    )
}