export const ModalStyle = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: props.width,
        height:props.height,
        bgcolor: 'white',//'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
    };
    return style;
}
