import React, { memo, useContext } from 'react';
import { ParkingContext } from './Parking';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ViewParking = () => {
    const parking = useContext(ParkingContext);
    return (
        <>
            <h4 className='mt-3'><ArrowBackIcon size={25} className='me-1' sx={{cursor:'pointer'}} onClick={parking.onBackList}/>Parking Details</h4>
            <table className="table table-striped">
                <tbody>
                    <tr>
                        <td className='fw-bold p-3'>Parking No</td>
                        <td className='p-3'>{parking.viewParkingData.parking_no}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Parking Type</td>
                        <td className='p-3'>{parking.viewParkingData.parking_type}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Vehicle(2 Wheeler)</td>
                        <td className='p-3'>{parking.viewParkingData.vehicle_2_wheeler}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Vehicle(4 Wheeler)</td>
                        <td className='p-3'>{parking.viewParkingData.vehicle_4_wheeler}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Unit</td>
                        <td className='p-3'>{parking.viewParkingData.unit !== null && parking.viewParkingData.unit.unit_name}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Assign Status</td>
                        <td className='p-3'>{parking.viewParkingData.assign_status}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Billing Cycle</td>
                        <td className='p-3'>{parking.viewParkingData.billing_cycle}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Fess</td>
                        <td className='p-3'>{parking.viewParkingData.fees}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Tenant Name</td>
                        <td className='p-3'>{parking.viewParkingData.tenantDetail && parking.viewParkingData.tenantDetail.firstName + '' +  parking.viewParkingData.tenantDetail.lastName }</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Department</td>
                        <td className='p-3'>{parking.viewParkingData.department && parking.viewParkingData.department.name}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Staff</td>
                        <td className='p-3'></td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Start Date</td>
                        <td className='p-3'>{parking.viewParkingData.start_date && parking.viewParkingData.start_date.split("T")[0]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Description</td>
                        <td className='p-3'>{parking.viewParkingData.description}</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default memo(ViewParking);