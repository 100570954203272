import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import TitleText from "../../Components/TitleText";
import FormTitle from "../../Components/FormTitle";
import SelectComponent from "../../Components/SelectComponent";
import {
  GetApiMethod,
  PostApiMethod,
  PutApiMethod,
} from "../../Utils/ApiService";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { showToaster } from "../../Store/toastSlice";
import { showLoader } from "../../Store/loaderSlice";
import { priorityList, statusList } from "./AddHelpDesk";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useFormik } from "formik";
import { editTicketSchema } from "../../Components/Validation";
import { errorHandling } from "../../Utils/HelperService";
import { ErrorTextField } from "../../Components/Error/ErrorTextField";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { styled } from "@mui/material/styles";


export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});



const EditHelpDesk = (props: {
  helpDeskdata: any;
  pageStatus: (value: string) => void;
  isUpdate: boolean;
}) => {
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const dispatch = useAppDispatch();
  const loaderState = useAppSelector((state) => state.loader);
  const [images, setImages] = useState<File[]>([]);
  const [imgArray, setImgArray] = useState<File[]>([]);

  const [initialValue, setInitialValiue] = useState({
    property: null,
    unit: null,
    typeDetail: null,
    status: null,
    priority: null,
    description: "",
    propertyId: "",
    unitId: "",
    typeId: "",
    id: "",
  });
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: editTicketSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      //   console.log("vaaaaaa",values);
      props.isUpdate ? submit(values) : createTicket(values);
      // {edit ? handleEditMaintainers(values) : handleSubmitMaintainers(values)}
    },
  });
  const fetchProperty = useCallback(async () => {
    let response: any;
    try {
      response = await PostApiMethod("property/properties/list", {});
      if (response["data"]["error"] === false) {
        setPropertyList(response.data.results.data);
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  }, []);
  const handleDeleteImage=(index:any)=>{
    try{
    const newImages = imgArray.filter((_, i) => i !== index);
      setImgArray(newImages);
      setImages(newImages);
    }
    catch(e){
      console.log(e)
    }
  }
  const fetchType = async () => {
    let response: any;
    try {
      response = await PostApiMethod("helpDeskType/list", {});
      if (response["data"]["error"] === false) {
        setTypeList(response.data.results.data);
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };
  const fetchUnit = async () => {
    let response: any;
    try {
      response = await PostApiMethod("property/unit/list", {});
      if (response["data"]["error"] === false) {
        setUnitList(response.data.results.data);
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };

  useEffect(() => {
    if (props.helpDeskdata) {
      setInitialValiue(props.helpDeskdata);
    }
  }, [props.helpDeskdata]);

  const getAllProperty = useCallback(async () => {
    dispatch(showLoader({ showLoader: true }));

    try {
      const [property, unit, type] = await Promise.all([
        fetchProperty(),
        fetchUnit(),
        fetchType(),
      ]);
      dispatch(showLoader({ showLoader: false }));
    } catch (e) {
      dispatch(showLoader({ showLoader: false }));
    }
  }, []);

  useEffect(() => {
    getAllProperty();
  }, [getAllProperty]);

  const handleImageChange = (e: any) => {
    setImages(e.target.files);
    const files = Array.from(e.target.files || []) as File[];
    setImgArray(files);
  };

  const createTicket = useCallback((values: any) => {
    try {
      dispatch(showLoader({ showLoader: true }));
      // let form = new FormData();
      let tState: any = { ...values };

      const form = new FormData();
      console.log(images, "imageUploadimageUploadimageUpload");

      form.append("description", values.description);
      form.append("property_id", values.propertyId);
      form.append("unit_id", values.unitId);
      form.append("type_id", values.typeId);
      form.append("status", values.status);
      form.append("priority", values.priority);
      form.append("attachement[0]", images[0]);
      const val: any = {
        description: values.description,
      };

      PostApiMethod("property/help-desk/add", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          dispatch(showLoader({ showLoader: false }));
          props.pageStatus("listHelpDesk");
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Helpdesk created successfully",
              showDialog: true,
              timer: "5000",
            })
          );
        })
        .catch((e) => {
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    } catch (e) {
      const error = errorHandling(e);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  }, []);

  const submit = async (values: any) => {
    try {
      dispatch(showLoader({ showLoader: true }));
      // let form = new FormData();
      let tState: any = { ...values };

      const form = new FormData();

      form.append("description", values.description);
      form.append("property_id", values.propertyId);
      form.append("unit_id", values.unitId);
      form.append("type_id", values.typeId);
      form.append("status", values.status);
      form.append("priority", values.priority);
      form.append("attachement[0]", images[0]);
      const val: any = {
        description: values.description,
      };

      PutApiMethod("property/help-desk/" + values.id, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          dispatch(showLoader({ showLoader: false }));
          props.pageStatus("listHelpDesk");
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Updated successfully",
              showDialog: true,
              timer: "5000",
            })
          );
        })
        .catch((e) => {
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    } catch (e) {}
  };
  return (
    <>
      <Box sx={{ mt: 5, mx: 3 }}>
        <Box className="card-title">
          <Typography variant="h5">
            {props.isUpdate ? "Update" : "Add"} Helpdesk
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Property" />
              <SelectComponent
                value={values.property}
                options={propertyList}
                getOptionLabel={(option) => option.property_name}
                name={"propertyId"}
                onChange={(newValue: any) => {
                  setFieldValue("propertyId", newValue.id);
                  setFieldValue("property", newValue);
                }}
              />
              <ErrorTextField
                error={errors.propertyId}
                touched={touched.propertyId!}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Unit" />
              <SelectComponent
                value={values.unit}
                options={unitList}
                getOptionLabel={(option) => option.unit_name}
                name={"unitId"}
                onChange={(newValue: any) => {
                  setFieldValue("unitId", newValue.id);
                  setFieldValue("unit", newValue);
                }}
              />
              <ErrorTextField error={errors.unitId} touched={touched.unitId!} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Type" />
              <SelectComponent
                value={values.typeDetail}
                options={typeList}
                getOptionLabel={(option: any) => option.name}
                name={"typeId"}
                onChange={(newValue: any) => {
                  setFieldValue("typeId", newValue.id);
                  setFieldValue("typeDetail", newValue);
                }}
              />
              <ErrorTextField error={errors.typeId} touched={touched.typeId!} />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Status" />
              <SelectComponent
                value={
                  statusList.find((v: any) => v.id === values.status) || {
                    name: "",
                  }
                }
                // value={props.helpDeskdata.status}
                options={statusList}
                getOptionLabel={(option: any) => option.name}
                name={"status"}
                onChange={(newValue: any) => {
                  setFieldValue("status", newValue.id);
                }}
              />
              <ErrorTextField error={errors.status} touched={touched.status!} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Priority" />
              <SelectComponent
                value={
                  priorityList.find((v: any) => v.id === values.priority) || {
                    name: "",
                  }
                }
                // value={priorityListprops.helpDeskdata.priority}
                options={priorityList}
                getOptionLabel={(option: any) => option.name}
                name={"priority"}
                onChange={(newValue: any) => {
                  setFieldValue("priority", newValue.id);
                }}
              />
              <ErrorTextField
                error={errors.priority}
                touched={touched.priority!}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Attachement" />

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={(e:any) =>handleImageChange(e)}
              >
                Upload file
                <VisuallyHiddenInput type="file" multiple />
              </Button>
              {imgArray.map((file, index) => (
                  <div className="border border-danger p-1">
                {file.name}
        <CancelTwoToneIcon className="float-end" onClick={()=>{handleDeleteImage(index)}}></CancelTwoToneIcon>
                </div>
              ))}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Description" />

              <TextField
                type="text"
                variant="standard"
                placeholder="Type here..."
                className="form-control"
                name="description"
                onChange={handleChange}
                value={values.description}
              />
              <ErrorTextField
                error={errors.description}
                touched={touched.description!}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }} className="d-flex">
            <Button
              onClick={(e) => {
                props.pageStatus("listHelpDesk");
              }}
            >
              <ChevronLeftIcon className="fs-20" />
              Back
            </Button>
            <Button
              className="btn-danger ms-2"
              type="submit"
              disabled={loaderState.showLoader}
            >
              {`${props.isUpdate ? "Update " : "Add "}Helpdesk`}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditHelpDesk;
