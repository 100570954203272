import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Profile from '../../Assets/images/profile.png';
import { MenuItem, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GetApiMethod, PostApiMethod } from '../../Utils/ApiService';
import { isNotEmpty } from '../../Utils/HelperService';
import EditCalendarTwoToneIcon from '@mui/icons-material/EditCalendarTwoTone';
import { useLocation, useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';

const AddVendors = (props: any) => {
    // const [property, setProperty] = useState("")
    let navigate = useNavigate();
    const [state, setState] = useState({ email: "", firstName: "", lastName: "", phoneNumber: "" })
    const [loading, setLoading] = useState(false);
    const [addVendors, setAddVendors] = useState(false);
    const [vendorsList, setVendorsList] = useState(props.vendorsList);
    const [vendorsCount, setVendorsCount] = useState(0);
    const [label, setLabel] = useState("Add");
    const [btnLabel, setBtnLabel] = useState("Add");

    useEffect(() => {
        setAddVendors(props.addVendors);
        setVendorsList(props.vendorsList);
        setVendorsCount(props.vendorsCount);
        if (props.addVendors) {
            setLabel("Add")
        }
    }, [props])

    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true)
        let res = await PostApiMethod('auth/create-user', state)
        if (res["data"]["code"] === 201) {
            console.log("success");
            setState({ email: "", firstName: "", lastName: "", phoneNumber: "" })
        }
        setLoading(false)
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
      ) => {
        setPage(newPage);
        props.parentData(newPage);
      };
    
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.parentData(event.target.value);
        props.limit(parseInt(event.target.value, 10));
      };
    return (
        <>
                <Box sx={{ mt: 3 }}>
                    <Typography className="poppins-family">{label} New Vendor</Typography>
                    <Typography className="poppins-family text-gray" sx={{ fontSize: '12px' }}>Vendor Information</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} sx={{ mt: 1 }}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography className='poppins-family'>First Name</Typography>
                                <TextField type="text" variant='standard' placeholder='Type here...' className='form-control' name="firstName" onChange={handleChange} value={state["firstName"]} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography className='poppins-family'>Last Name</Typography>
                                <TextField type="text" variant='standard' placeholder='Type here...' className='form-control' name="lastName" onChange={handleChange} value={state["lastName"]} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography className='poppins-family'>Contact Number</Typography>
                                <TextField type="number" variant='standard' placeholder='Type here...' className='form-control' name="phoneNumber" onChange={handleChange} value={state["phoneNumber"]} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography className='poppins-family'>Email</Typography>
                                <TextField type="email" variant='standard' placeholder='Type here...' className='form-control' name="email" onChange={handleChange} value={state["email"]} />
                            </Grid>
                            {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className='poppins-family'>Password</Typography>
                        <TextField type="password" variant='standard' placeholder='Type here...' className='form-control'/>
                    </Grid> */}
                            {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className='poppins-family'>Assign Property</Typography>
                        <Select value={property} displayEmpty className="w-100" variant='standard' onChange={handleProperty}>
                            <MenuItem value="" disabled>Choose</MenuItem>
                            <MenuItem value={1}>Property1</MenuItem>
                        </Select>
                    </Grid> */}
                        </Grid>

                        <Box sx={{ mt: 2 }} className="d-flex">
                            <Button className='btn-white' ><ChevronLeftIcon className='fs-20' />Back</Button>
                            <Button className='btn-danger ms-2' type="submit" disabled={loading}>{btnLabel} Vendor</Button>
                        </Box>
                    </form>
                </Box>
        </>
    )
}

export default AddVendors