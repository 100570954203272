import React, { useCallback, useEffect, useState } from "react";
import CustomTextfield, {
  TextFieldForArray,
} from "../../components/textfield1";
import styles from "./unit.module.css";
import RadioButton1 from "../../components/radioButton";
import { radioButton1 } from "../../types/interface";
import { FormButton1 } from "../../components/formButton";
import ImageSelectField from "../../components/imageField";
import { isApiConnected } from "../../controls";
import { useAppDispatch } from "../../../../Store/hooks";
import { showLoader } from "../../../../Store/loaderSlice";
import { PostApiMethod, PutApiMethod } from "../../../../Utils/ApiService";
import { showToaster } from "../../../../Store/toastSlice";
import documentImg from "../../../../Assets/images/icons/document.png";
import { logFormdata, logNormal } from "../../utlity/logging";
import { PostApiMethod1, PutApiMethod1 } from "../../api_service";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface unitFormType {
  backBtn: Function;
  submitBtn: Function;
  CreatedPropertyDetailsFromApi: string;
  CreatedUserIdFromApi: string;
  CreatedNo_of_unitsFromApi: string;
  forUpdatePropertyValues?: any;
  isForUpdate?: boolean;
}
// interface ApiBody {
//   [key: string]: string; // Index signature to allow additional key-value pairs
// }
interface ApiData {
  user_id?: string;
  property_id?: string;
  unit_name?: string;
  bedroom?: string;
  Bathroom?: string;
  amentities?: string;
  square_feet?: string;
  kitchen?: string;
  condition?: string;
  parking?: string;
  description?: string;
  image?: File[];
}
interface ApiBody {
  data: ApiData[];
}

const UnitForm: React.FunctionComponent<unitFormType> = ({
  backBtn,
  submitBtn,
  CreatedPropertyDetailsFromApi,
  CreatedUserIdFromApi,
  CreatedNo_of_unitsFromApi,
  forUpdatePropertyValues,
  isForUpdate,
}) => {
  const [FormContents, setFormContents] = useState(UnitContents); //leasePropertyContents

  const [apiFormData, setApiFormData] = useState(new FormData());

  const [SelectedImg, setSelectedImg] = useState();
  const dispatch = useAppDispatch();

  function setInputValue(inputValue: string, inputName: string, index: number) {
    const key = `data[${index}][${inputName}]`;
    const PropertyIdKey = `data[${index}][property_id]`;
    const userIdKey = `data[${index}][user_id]`;
    if(isForUpdate) {
      apiFormData.delete(`data[${index}][id]`);
      apiFormData.append(`data[${index}][id]`, forUpdatePropertyValues.id);
    }
    apiFormData.delete(PropertyIdKey);
    apiFormData.delete(userIdKey);
    apiFormData.delete(key);
    apiFormData.append(PropertyIdKey, CreatedPropertyDetailsFromApi);
    apiFormData.append(userIdKey, CreatedUserIdFromApi);
    apiFormData.append(key, inputValue);
    setApiFormData(apiFormData);
    for (let pair of apiFormData.entries()) {
      console.log("unit apiFormData: " + pair[0] + ": " + pair[1]);
    }
  }

  function setImage(InputValue: any, inputName: string) {
    console.log("inputName: " + inputName + "InputValue: " + InputValue);

    setSelectedImg(InputValue);
    console.log("apiFormData: " + JSON.stringify(apiFormData));
  }

  function backBtnFunc() {
    console.log("back btn called");
    backBtn("Location");
  }

  async function submmitForm(event: any) {
    event.preventDefault();
    // console.log(event, "12345");

    if (isApiConnected) {
    } else {
      console.log("api off in controler");
      submitBtn("Rent & Charges");
      return;
    }
    dispatch(showLoader({ showLoader: true }));

    try {
      let length = 0;

      // Iterate through the entries and count them
      for (let entry of apiFormData.entries()) {
          length++;
      }
      // Object.entries(apiFormData).forEach(([key, value]) => {
      //   console.log("value",key,value)
      // });
      // sampleRealDataForDevlopment
      // apiFormData
      if (isForUpdate) {
        if(length !== 0){
          await PutApiMethod1(
            "property/unit/updateRate",
            apiFormData
          ).then((res)=>{
            dispatch(
              showToaster({
                dialogBgColor: "bg-success",
                dialogMsg: "Unit Updated Successfully",
                showDialog: true,
                timer: "5000",
              })
            );
            submitBtn("Rent & Charges", res);
            dispatch(showLoader({ showLoader: false }));
          }).catch((e)=>{
            dispatch(
              showToaster({
                dialogBgColor: "bg-danger",
                // dialogMsg: e.response.data.message,
                dialogMsg: "Error, Try again",
                showDialog: true,
                timer: "5000",
              })
            );
            dispatch(showLoader({ showLoader: false }));
          });
        }else{
          submitBtn("Rent & Charges");
          dispatch(showLoader({ showLoader: false }));
        }
      } else {
        const res = await PostApiMethod1("property/unit/create", apiFormData);
        if (res["data"]["code"] === 201) {
          console.log("res: " + JSON.stringify(res["data"]["results"]["data"]));
          const UnitDetails = res["data"]["results"]["data"];
          dispatch(
            showToaster({
              dialogBgColor: "bg-success",
              dialogMsg: "Unit created success",
              showDialog: true,
              timer: "5000",
            })
          );
          submitBtn("Unit", UnitDetails);
          dispatch(showLoader({ showLoader: false }));
        } else {
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: "Unable to process now, Try again",
              showDialog: true,
              timer: "5000",
            })
          );
        }
      }
    } catch (e: any) {
      // submitBtn("Unit", 2);

      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          // dialogMsg: e.response.data.message,
          dialogMsg: "Error, Try again",
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  }

  const geUnitDetail = useCallback(async () => {
    dispatch(showLoader({ showLoader: true }));
    PostApiMethod1("property/unit/getUnitByPropertyId", {
      property_id: forUpdatePropertyValues.id,
      user_id: forUpdatePropertyValues.owner_user_id,
    })
      .then((res) => {
        dispatch(showLoader({ showLoader: false }));
        if (res.data.results.data.rows.length > 0) {
        }
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
      });
  }, [dispatch]);

  useEffect(() => {
    if (forUpdatePropertyValues) {
      geUnitDetail();
    }
  }, [forUpdatePropertyValues]);

  const generateForms = () => {
    const forms = [];
    for (let i = 0; i < Number(CreatedNo_of_unitsFromApi); i++) {
      forms.push(
        <div>
          <p>Unit - {i + 1}</p>

          <div className={styles.form}>
            {FormContents.map((val, index) => (
              <div key={index} className={styles.inputTotalBox}>
                {val.type === "text" ? (
                  <TextFieldForArray
                    label={val.lable}
                    name={val.name}
                    type={val.type}
                    value={isForUpdate ? forUpdatePropertyValues[val.name] : ""}
                    valueChange={setInputValue}
                    indexOfTextField={i}
                  />
                ) : val.type === "number" ? (
                  <TextFieldForArray
                    label={val.lable}
                    name={val.name}
                    type={val.type}
                    value={isForUpdate ? forUpdatePropertyValues[val.name] : ""}
                    valueChange={setInputValue}
                    indexOfTextField={i}
                  />
                ) : val.type === "file" ? (
                  <TextFieldForArray
                    label={val.lable}
                    name={val.name}
                    type={val.type}
                    valueChange={setInputValue}
                    indexOfTextField={i}
                  />
                ) : null}
              </div>
            ))}
            {/* <ImageSelectField
              label={imageFieldContent.lable}
              name={imageFieldContent.name}
              valueChange={setImage}
              currentIndex={i}
            />
            {SelectedImg === undefined ? (
              <span></span>
            ) : (
              <div className={styles.imageOuterBox}>
                <div className={styles.imageBox}>
                  <img src={documentImg} alt="docImg" width={40} />
                  <p>{SelectedImg}</p>
                </div>
              </div>
            )} */}
          </div>
        </div>
      );
    }
    return forms;
  };

  return (
    <>
      <div className={styles.topBox}>
        {/* <p>Total Units - {CreatedNo_of_unitsFromApi}</p> */}

        <form onSubmit={submmitForm} className={styles.formTag}>
          {generateForms()}

          {/* <div className={styles.bottomBtnBox}>
            <FormButton1 backBtnClick={backBtnFunc} />
          </div> */}
          <div className="mt-3">
            <button type="button" className="btn btn-white" onClick={backBtnFunc}><KeyboardArrowLeftIcon /> Back</button>
            <button type="submit" className="btn btn-danger ms-3">Update</button>
          </div>
        </form>
      </div>
    </>
  );
};
export default UnitForm;

interface unitContent {
  type: "text" | "number" | "file" | "radio" | "dropdowm" | "date";
  lable: string;
  name: string;
}
const UnitContents: unitContent[] = [
  {
    type: "text",
    lable: "Unit Name",
    name: "unit_name",
  },
  {
    type: "number",
    lable: "Bedroom",
    name: "bedroom",
  },
  {
    type: "number",
    lable: "Bathroom",
    name: "bath",
  },
  {
    type: "number",
    lable: "Kitchen",
    name: "kitchen",
  },
  {
    type: "text",
    lable: "Square Feet",
    name: "square_feet",
  },
  {
    type: "text",
    lable: "Amentities",
    name: "amenities",
  },
  {
    type: "text",
    lable: "Condition",
    name: "condition",
  },
  {
    type: "text",
    lable: "Parking",
    name: "parking",
  },
  {
    type: "text",
    lable: "Description",
    name: "description",
  },
];
const imageFieldContent = {
  type: "file",
  lable: "Upload Images",
  name: "image",
};

const sampleRealDataForDevlopment = {
  "data[0][unit_name]": "assas",
  "data[0][bedroom]": "2",
  "data[0][Bathroom]": "4",
  "data[0][amentities]": "dd",
  "data[0][square_feet]": "444",
  "data[0][kitchen]": "443",
  "data[0][parking]": "ffdf",
  "data[0][description]": "fdf",
  "data[1][unit_name]": "dffd",
  "data[1][bedroom]": "44",
  "data[1][Bathroom]": "33",
  "data[1][amentities]": "ffd",
  "data[1][square_feet]": "fddf",
  "data[1][kitchen]": "52",
  "data[1][parking]": "fdfd",
  "data[1][description]": "dffd",
  "data[1][property_id]": "94",
  "data[1][user_id]": "8",
  "data[1][condition]": "44",
  "data[0][property_id]": "94",
  "data[0][user_id]": "8",
  "data[0][condition]": "78",
};
