export const convertToAmPm = (time24) => {
    let [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
};

export const convertTo24HourFormat = (time12) => {
    // let [time, period] = time12.split(' ');
    let [hours, minutes, period] = time12.split(':');

    hours = parseInt(hours, 10);
    if (period === "PM" && hours < 12) {
        hours += 12;
    } else if (period === "AM" && hours === 12) {
        hours = 0;
    }

    // Pad hours and minutes with leading zero if they are single digits
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ?  minutes : minutes;
    console.log("hours",hours,"minutes",minutes)
    return `${hours}:${minutes}`;
};
