import React, { useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import {
  Grid,
  InputAdornment,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconSearch from "../../Assets/images/search.svg";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import Actions from "../../Components/Actions/Actions";
import IconAssets from "../../Assets/images/icon_assets_red.svg";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import IconEdit from "../../Assets/images/icon_edit.svg";
import AssetsDetailSubTab from "./AssetDetailsSubTab";

const AntTabs = styled(Tabs)({
  marginLeft: "15px",
  padding: "0",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D73",
  },
});

const AntTab = styled(Tab)({
  fontSize: "13px",
  fontFamily: "Poppins",
  "&.Mui-selected": {
    color: "#FF7d73",
  },
});

export default function AssetsDetail() {
  const [tabValue, setTabValue] = useState(0);
  const [addAsset, setAddAsset] = useState(true);
  const [isAddNewAssetVisible, setIsAddNewAssetVisible] = useState(false);
  const [searchString, setSearchString] = useState("");

  const handleChange = (e: any, val: any) => {
    setIsAddNewAssetVisible(false);
    setTabValue(val);
    setAddAsset(val === 0);
  };

  return (
    <AuthorizedLayout>
      <Card elevation={0} sx={{ borderRadius: "12px" }}>
        <Box className="d-flex justify-content-between align-items-center">
          <Box className="d-flex align-items-center">
            <img src={IconAssets} alt="Property" width={20} />
            <Typography className="psemiboldfont page-header-text">
              Assets
            </Typography>
            <AntTabs value={tabValue} onChange={handleChange}>
              <AntTab label="Asset Details" className="tab-menu" />
              <AntTab label="Assets History" className="tab-menu" />
            </AntTabs>
          </Box>
          <Box>
            <ButtonPrimary text="Reassign" imgSrc={IconEdit} className="me-2" />
            <ButtonPrimary text="Edit Info" imgSrc={IconEdit} />
          </Box>
        </Box>

        <CustomTabPanel value={tabValue} index={0}>
          <AssetsDetailSubTab />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          Message
        </CustomTabPanel>
      </Card>
    </AuthorizedLayout>
  );
}
