import { useState } from "react";
import styles from "./textfield1.module.css";

interface TextFieldProps {
  label: string;
  name: string;
  type?: "file";
  valueChange: Function;
  currentIndex: number;
}

const ImageSelectField: React.FC<TextFieldProps> = ({
  label,
  name,
  type = "file",
  valueChange,
  currentIndex,
}) => {
  const [CurrentTextFieldValue, setCurrentTextFieldValue] = useState("");
  const bool = true;
  function inputFieldValueChanges(event: any) {
    const newValue = event.target.value;
    setCurrentTextFieldValue(newValue);
    valueChange(event.target.files, name);
  }

  return (
    <div className={`${styles.textField}`}>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
        type={type}
        value={CurrentTextFieldValue}
        onChange={inputFieldValueChanges}
        placeholder="Upload image"
        className={styles.input}
        accept="image/*"
        multiple
        // required
      />
    </div>
  );
};

export default ImageSelectField;
