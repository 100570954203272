import { borderRadius, minHeight } from "@mui/system";
import React from "react";

interface ButtonPrimaryProps {
  text: string;
  type?: "submit" | "reset" | "button";
  className?: string;
  onClick?: () => void;
  imgSrc?: string;
  imgAlt?: string;
}

export const ButtonPrimary = (props: ButtonPrimaryProps) => {
  const className = props.className ? props.className : "";
  // const hasBtnLinkClass = className && className.includes("btn-link");
  // const computedClassName = `btn ${className || ""} ${hasBtnLinkClass ? "" : "button-effect"
  //     }`;
  // const hasArrowClass = className && className.includes("with-arrow");

  // const buttonStyle = hasBtnLinkClass
  //     ? { border: "none" }
  //     : { border: "1px solid var(--green-variant1)" };

  const buttonStyle = {
    background: "#FF7D73",
    border: "transparent",
    fontSize: "12px",
    padding: "6px 10px",
    color: "#fff",
    borderRadius: "8px",
    minHeight: "38px",
  };
  return (
    <button
      type={props.type}
      style={buttonStyle}
      // className={computedClassName.trim()}
      className={props.className}
      onClick={props.onClick}
    >
      {props.imgSrc && (
        <img
          src={props.imgSrc}
          alt={props.imgAlt || "Button Image"}
          style={{ marginRight: "10px", width: "21px", height: "21px" }}
        />
      )}
      {props.text}
      {/* {hasArrowClass && (
                <img
                    src={IconRightArrow}
                    className="arrow-icon"
                    alt="Right Arrow Icon"
                />
            )} */}
    </button>
  );
};
