import React, { memo, useContext } from 'react';
import { AmenitiesContext } from './Amenities';
import { amenitiesSchema } from './AmenitiesSchema';
import { Typography, Box, Grid, MenuItem, TextField, Checkbox, FormControl, FormLabel, FormGroup, FormControlLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Select from '@mui/material/Select';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';

const AddAmenities = () => {
    const amenities = useContext(AmenitiesContext);
    const dispatch = useAppDispatch();

    const apiResponse = (color,msg) => {
        dispatch(showToaster({dialogBgColor: color,
        dialogMsg: msg,
        showDialog: true,
        timer: "3000",}))
    }

    const createAmenities = async(formData,resetForm) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let response = await PostApiMethod("property/amenities/add",formData);
            if(response['data']['error'] === false){
                resetForm({values:''})
                apiResponse("bg-success","Amenities has been created");
                amenities.getAmenitiesList(amenities.alPage)
                amenities.onAmenitiesBack();
            }
        }catch(error){
            if(error.response !== undefined){
                apiResponse("bg-danger",error.response.data.errors[0].msg)
            }else{
                apiResponse("bg-danger","Failed to create amenities")
            }
        }
        dispatch(showLoader({showLoader:false}))
    }

    const editAmenities= async(formData) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let response = await PutApiMethod("property/amenities/"+amenities.editAmenities.id,formData)
            if(response["data"]["error"] === false){
                apiResponse("bg-success","Amenities has been created");
                amenities.getAmenitiesList(amenities.alPage)
                amenities.onAmenitiesBack();
            }
        }catch(error){
            if(error.response !== undefined){
                apiResponse("bg-danger",error.response.data.errors[0].msg)
            }else{
                apiResponse("bg-danger","Failed to create amenities")
            }
        }
        dispatch(showLoader({showLoader:false}))
    }
    return (
        <>
            <Box className="mt-4">
                <Typography className='fw-bold fs-3'>{Object.entries(amenities.editAmenities).length === 0 ? "Add New" : "Edit"} Amenity</Typography>
            </Box>
            <Box>
                <Formik
                    initialValues={{
                        name: amenities.editAmenities.name || '',
                        propertyId: amenities.editAmenities.propertyId || '',
                        //availableDays: amenities.editAmenities.availableDays ? Number(amenities.editAmenities.availableDays.split(",")[0]) : '',
                        openingTime: amenities.editAmenities.openingTime || '',
                        closingTime: amenities.editAmenities.closingTime || '',
                        no_of_people: amenities.editAmenities.no_of_people || '',
                        isOpenFullTime:amenities.editAmenities.isOpenFullTime ?
                        amenities.editAmenities.isOpenFullTime === 0 ? false :true :
                        false,
			            availableDays: amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",") : []
                    }}
                    validationSchema={amenitiesSchema}
                    onSubmit={(values, {resetForm}) => {
                        const formData = new FormData();
                        console.log(values)
                        Object.keys(values).map((val,key) => {
                            if(values[val] !== ""){
                                if(val === "availableDays"){
                                    values[val].map((v,k) => {
                                        formData.append(`availableDays[${k}]`,v)
                                    })
                                }else{
                                    formData.append(val,values[val])
                                }
                            }
                            return true
                        })
                        if(Object.entries(amenities.editAmenities).length === 0){
                            createAmenities(formData,resetForm)
                        }else{
                            editAmenities(formData)
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} className="mt-3"> 
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Amenity</Typography>
                                    <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    {touched.name && errors.name && (<div className='text-danger'>{errors.name}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Property</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="propertyId" variant='standard' value={values.propertyId} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {amenities.propertyList.length !== 0 && amenities.propertyList.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.property_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {touched.propertyId && errors.propertyId && (<div className='text-danger'>{errors.propertyId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Open Timings</Typography>
                                    <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="openingTime"
                                        value={values.openingTime}
                                        onChange={handleChange}
                                        type="time"
                                    />
                                    {touched.openingTime && errors.openingTime && (<div className='text-danger'>{errors.openingTime}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Close Timings</Typography>
                                    <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="closingTime"
                                        value={values.closingTime}
                                        onChange={handleChange}
                                        type="time"
                                    />
                                    {touched.closingTime && errors.closingTime && (<div className='text-danger'>{errors.closingTime}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">No. of People</Typography>
                                    <TextField
                                        type="text"
                                        className='w-100'
                                        variant="standard"
                                        value={values.no_of_people}
                                        name="no_of_people"
                                        placeholder="Type here"
                                        onChange={handleChange}
                                    />
                                    {touched.no_of_people && errors.no_of_people && (<div className='text-danger'>{errors.no_of_people}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={values.isOpenFullTime} onChange={handleChange} name="isOpenFullTime" />
                                        }
                                        label="Open Full Time"
                                    />
                                </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Available Days</FormLabel>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox name="availableDays" onChange={handleChange} value="1" defaultChecked={amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",").some(val => val === "1") : false}/>} label="Sunday" />
                                    <FormControlLabel control={<Checkbox name="availableDays" onChange={handleChange} value="2" defaultChecked={amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",").some(val => val === "2") : false}/>} label="Monday" />
                                    <FormControlLabel control={<Checkbox name="availableDays" onChange={handleChange} value="3" defaultChecked={amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",").some(val => val === "3") : false}/>} label="Tuesday" />
                                    <FormControlLabel control={<Checkbox name="availableDays" onChange={handleChange} value="4" defaultChecked={amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",").some(val => val === "4") : false}/>} label="Wednesday" />
                                    <FormControlLabel control={<Checkbox name="availableDays" onChange={handleChange} value="5" defaultChecked={amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",").some(val => val === "5") : false}/>} label="Thursday" />
                                    <FormControlLabel control={<Checkbox name="availableDays" onChange={handleChange} value="6" defaultChecked={amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",").some(val => val === "6") : false}/>} label="Friday" />
                                    <FormControlLabel control={<Checkbox name="availableDays" onChange={handleChange} value="7" defaultChecked={amenities.editAmenities.availableDays ? amenities.editAmenities.availableDays.split(",").some(val => val === "7") : false}/>} label="Saturday" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                            <Box className="mt-5">
                                <button type="button" className='btn btn-white' onClick={amenities.onAmenitiesBack}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                                <button type="submit" className='btn btn-danger ms-2'>Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    )
}

export default memo(AddAmenities);