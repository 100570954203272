import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Profile from "../../Assets/images/profile.png";
import IconSearch from "../../Assets/images/search.svg";
import Pagination from "../../Components/Pagination/Pagination";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TableFooter,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DeleteApiMethod,
  GetApiMethod,
  PostApiMethod,
  PutApiMethod,
} from "../../Utils/ApiService";
import { DisplayErrorMessages, isNotEmpty } from "../../Utils/HelperService";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import { useLocation, useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import Upload from "../../Components/Upload/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import ShowTenant from "./ShowTenant";
import AssignVendorsList from "../Vendors/AssignVendorsList";
import styles from "../tables.module.css";
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";
import { ButtonSecondary } from "../../Components/Button/ButtonSecondary";
import { showToaster } from "../../Store/toastSlice";

const ITEM_HEIGHT = 48;
const ListTenant = (props: any) => {
  // const [property, setProperty] = useState("")
  let navigate = useNavigate();
  const getInitialState = () => ({
    id: "",
    type: "",
    description: "",
    attachement: "",
    status: true,
    userId: "",
    property_id: {},
    unit_id: {},
  });
  const [state, setState] = useState<any>(getInitialState);
  const [addTenant, setAddTenant] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [tenantCount, setTenantCount] = useState(0);
  const [label, setLabel] = useState("Add");
  const [btnLabel, setBtnLabel] = useState("Add");
  const [upData, setUpData] = useState<any>([]);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  const [showTenant, setShowTenant] = useState(false);
  const [assignVendors, setAssignVendors] = useState(false);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState({ page: 1, limit: 5, count: 0 });
  const dispatch = useAppDispatch();
  const [unitId, setUnitId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  // useEffect(() => {
  //     setState({ ...state, "attachement": upData[0] })
  // }, [upData])
  const fetchProperty = async () => {
    let response = await PostApiMethod("property/properties/list", {});
    if (response["data"]["error"] === false) {
      setPropertyList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchUnit = async () => {
    let response = await PostApiMethod("property/unit/list", {});
    if (response["data"]["error"] === false) {
      setUnitList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  useEffect(() => {
    fetchTenantSearchList(page.page, page.limit);
  }, [search]);
  const fetchTenantSearchList = useCallback(async (page: any , limit:any) => {
    dispatch(showLoader({ showLoader: true }));
    try {
      const formdata: any = {
        page: page,
        limit: limit,
        searchString: search,
        role: "PMU",
        unitId: unitId,
        propertyId: propertyId,
      };
      let response = await PostApiMethod("user/getUser", formdata);
      if (response["data"]["error"] === false) {
        setTenantList(response.data.results.data);
        setTenantCount(response.data.results.totalCount);
        setPage((prevPage) => ({
          ...prevPage,
          count: response["data"]["results"]["totalCount"],
        }));
      }
      dispatch(showLoader({ showLoader: false }));
    } catch (e) {
      dispatch(showLoader({ showLoader: false }));
    }
  }, [page, limit, search, unitId, propertyId]);

  useEffect(() => {
    fetchProperty();
    fetchUnit();
  }, []);

  const handleChange = (e: any, data: any) => {
    setState({ ...state, [e]: data });
  };


  const rowsPerPage = useCallback(
    (val: any) => {
      setPage({ ...page, page: val.page, limit: val.limit });
      fetchTenantSearchList(val.page, val.limit);
    },
    [page]
  );

  const fetchTenantById = async (id: any) => {
    let response = await GetApiMethod("property/help-desk/" + id);
    if (response["data"]["error"] === false) {
      setState(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  // const handleTenantEdit = async (data: any) => {
  //     setLabel("Edit");
  //     setBtnLabel("Save");
  //     setAddTenant(true);
  //     let option = await fetchTenantById(data.id)
  // }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [cOpen, setcOpen] = useState<Number>(0);
  const handleClick = (id: Number, event: React.MouseEvent<HTMLElement>) => {
    setcOpen(cOpen === id ? 0 : id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any, data: any) => {
    setcOpen(0);
    setAnchorEl(null);
    if (e === "View") {
      props.pageStatus("showTenant");
      // fetchTenantById(data)
      let tmpdata = {
        page: e,
        data: data,
      };
      props.pageData(tmpdata);
    }
    if (e === "Edit") {
      props.pageStatus("editTenant");
      let tmpdata = {
        page: e,
        data: data,
      };
      props.pageData(tmpdata);
    }
    if (e === "Delete") {
      deleteTenant(data.id);
    }
  };

  const deleteTenant = async (id: any) => {
    DeleteApiMethod("user/" + id)
      .then((res) => {
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "Deleted successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        fetchTenantSearchList(page.page, page.limit);
      })
      .catch((e) => {
        const error = DisplayErrorMessages(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
  };
  const options = ["Edit", "Delete"];
  const handleFilterChange = () => {
    // console.log("state",state)
    // let property_id = "";
    // let unit_id = "";
    // if (isNotEmpty(state.property_id)) {
    //   property_id = state.property_id.id;
    // }
    // if (isNotEmpty(state.unit_id)) {
    //   unit_id = state.unit_id.id;
    // }
    fetchTenantSearchList(page.page, page.limit);
  };

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Box
          className="w-full d-flex my-30"
          sx={{
            margin: "30px 0",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={4} sm={12} xs={12}>
              <Typography variant="body2">Search</Typography>
              <TextField
                variant="standard"
                placeholder="Type here"
                sx={{
                  width: "100%",
                }}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                className="w-full"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={IconSearch} width="18px" height="18px" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Typography className="poppins-family">
                Select Property
              </Typography>
              <Autocomplete
                id="size-small-standard1"
                size="small"
                options={propertyList.map((data: any) => ({
                  id: data.id,
                  property_name: data.property_name,
                }))}
                getOptionLabel={(option: any) =>
                  option.property_name.toString()
                }
                onChange={(event: any, newValue: any) => {
                  newValue !== null
                    ? handleChange("property_id", newValue)
                    : handleChange("property_id", {});
                  setPropertyId(newValue ? newValue.id: "");
                }}
                // value={options && options.find((option:any) => option.id === state["property_id"])}
                inputValue={
                  Object.keys(state["property_id"]).length
                    ? state["property_id"].property_name
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    // label="Property"
                    placeholder="Choose"
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Select Unit</Typography>
              <Autocomplete
                id="size-small-standard1"
                size="small"
                options={unitList.map((data: any) => ({
                  id: data.id,
                  unit_name: data.unit_name,
                }))}
                getOptionLabel={(option: any) => option.unit_name.toString()}
                onChange={(event: any, newValue: any) => {
                  newValue !== null
                    ? handleChange("unit_id", newValue)
                    : handleChange("unit_id", {});
                  setUnitId(newValue ? newValue.id: "");
                
                }}
                // value={options && options.find((option:any) => option.id === state["unit_id"])}
                inputValue={
                  Object.keys(state["unit_id"]).length
                    ? state["unit_id"].unit_name
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    // label="Property"
                    placeholder="Choose"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button
            sx={{ marginLeft: "30px" }}
            variant="outlined"
            color="error"
            className="mt-4 ml-30"
            onClick={handleFilterChange}
          >
            Apply
          </Button>
        </Box>
          <TableContainer className="table-striped">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Profile Picture</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Property</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Family Members</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>View Details</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenantList &&
                  tenantList.map((tenant: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell>
                        {isNotEmpty(tenant.profilePicture) ? (
                          <img
                            src={tenant.profilePicture}
                            alt="profile"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={Profile}
                            alt="profile"
                            width={40}
                            height={40}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(tenant.firstName)
                          ? tenant.firstName
                          : "" + " " + isNotEmpty(tenant.lastName)
                          ? tenant.lastName
                          : ""}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(tenant.property)
                          ? tenant.property.property_name
                          : ""}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(tenant.unit) ? tenant.unit.unit_name : ""}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(tenant.familyMember) ? tenant.familyMember : ""}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(tenant.email) ? tenant.email : ""}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(tenant.phoneNumber)
                          ? tenant.phoneNumber
                          : ""}
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => handleClose("View", tenant)}
                          style={{ borderRadius: 25 }}
                        >
                          view
                        </Button>
                        {/* <ButtonSecondary
                          text="View"
                          onClick={() => handleClose("View", tenant)}
                          className={styles.viewBtn}
                        /> */}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          id={"long-button_" + tenant.id}
                          aria-controls={
                            cOpen === tenant.id
                              ? "long-menu_" + tenant.id
                              : undefined
                          }
                          aria-expanded={
                            cOpen === tenant.id ? "true" : undefined
                          }
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(tenant.id, e);
                          }}
                        >
                          <Typography
                            className="poppins-family"
                            color="primary"
                          >
                            Action
                          </Typography>
                          <KeyboardArrowDownTwoToneIcon color="primary" />
                        </IconButton>
                        <Menu
                          id={"long-menu_" + tenant.id}
                          MenuListProps={{
                            "aria-labelledby": "long-button_" + tenant.id,
                          }}
                          anchorEl={anchorEl}
                          open={cOpen === tenant.id}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          {options.map((option: any) => (
                            <MenuItem
                              key={option}
                              onClick={() => {
                                handleClose(option, tenant);
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
           
        {tenantCount ? (
        <TableFooter>
        <TableRow>
          <Pagination
            pagination={page}
            colSpan={6}
            rowsPerPageChange={rowsPerPage}
          />
        </TableRow>
      </TableFooter>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Typography>No data</Typography>
          </div>
        )}
         </Table>
          </TableContainer>
      </Box>
    </>
  );
};

export default ListTenant;
