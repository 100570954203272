import { useState } from "react";
import styles from "./textfield1.module.css";

interface TextFieldProps {
  label: string;
  name: string;
  type?: "text" | "number" | "file";
  valueChange: Function;
  placeHolder?: string;
  value?: string;
  disable?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  name,
  type = "text",
  valueChange,
  value,
  disable,
}) => {
  function inputFieldValueChanges(event: any) {
    const newValue = event.target.value;
    let newError = "";
    valueChange(newValue, name);
  }

  return (
    <div className={`${styles.textField}`}>
      <label
      //  htmlFor={name}
      >
        {label}
      </label>
      <input
        id={name}
        name={name}
        disabled={disable}
        type={type}
        value={value}
        onChange={inputFieldValueChanges}
        placeholder="Type here"
        className={styles.input}
        min={1}
        // minLength={type === "text" ? 3 : 1}
        // required
      />
    </div>
  );
};

export default TextField;

interface TextField2Props {
  label: string;
  name: string;
  type?: "text" | "number" | "file";
  valueChange: Function;
  indexOfTextField: number;
  placeHolder?: string;
  nonEditable?: boolean;
  value?: string;
}

export const TextFieldForArray: React.FC<TextField2Props> = ({
  label,
  name,
  type = "text",
  valueChange,
  indexOfTextField,
  placeHolder = "Type Here",
  nonEditable = false,
  value,
}) => {
  const [CurrentTextFieldValue, setCurrentTextFieldValue] = useState(value);

  function inputFieldValueChanges(event: any) {
    const newValue = event.target.value;
    let newError = "";
    setCurrentTextFieldValue(newValue);
    valueChange(newValue, name, indexOfTextField);
  }

  return (
    <div className={`${styles.textField}`}>
      <label
      // htmlFor={name}
      >
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        value={CurrentTextFieldValue}
        onChange={inputFieldValueChanges}
        placeholder={placeHolder}
        className={styles.input}
        min={1}
        disabled={nonEditable}
        // minLength={type === "text" ? 3 : 1}
      />
    </div>
  );
};
