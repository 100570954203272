import React from "react";
import { DtoRoutesDefinition } from "../Dto/auth";

import LoginPage from "../Pages/AuthPages/LoginPage";
import CustomerPage from "../Pages/HomePages/Customer";
import CustomerTable from "../Pages/HomePages/CustomerTable";
import UserTable from "../Pages/HomePages/UserTable";
import Maintainers from "../Pages/Maintainers/Maintainers";
import Property from "../Pages/PropertyManagement/Main";
// import Property from "../Pages/property/property";
import PropertyDetails from "../Pages/property/propertyDetails";
import DashBoard from "../Pages/Dashboard/dashboard";
import Vendors from "../Pages/Vendors/Vendors";
import HelpDesk from "../Pages/HelpDesk/HelpDesk";
import ShowHelpDesk from "../Pages/HelpDesk/ShowHelpDesk";
import AssignVendorsList from "../Pages/Vendors/AssignVendorsList";
import Tenant from "../Pages/Tenant/Tenant";
import UpdateForms from "../Pages/property/updateForms";
import BillingCenter from "../Pages/BillingCenter/BillingCenter";
import Expenses from "../Pages/Expenses/Expenses";
import HelpDeskTypeList from "../Pages/HelpDesk/HelpDeskType/HelpDeskTypeList";
import InvoiceType from "../Pages/InvoiceType/InvoiceType";
import Settings from "../Pages/Settings/Settings";
import Document from "../Pages/Document/document";
import Assets from "../Pages/Assets/Assets";
import AssetsDetail from "../Pages/Assets/AssetDetails";
import NoticeBoard from "../Pages/notice-board/NoticeBoard";
import Maintenance from "../Pages/maintenance/Maintenance"
import Visitor from "../Pages/Visitor/Visitor";
import Amenities from "../Pages/Amenities/Amenities";
import Parking from "../Pages/Parking/Parking";

export const ProtectedRoutes: DtoRoutesDefinition[] = [
  {
    path: "/",
    element: <></>,
    redirectTo: "/dashboard",
  },
  {
    path: "/dashboard",
    element: <DashBoard />,
  },
  {
    path: "/customer-admin",
    element: <CustomerTable />,
    permission: "super-admin",
  },
  {
    path: "/user",
    element: <UserTable />,
    permission: "super-admin",
  },
  {
    path: "/user/table",
    element: <UserTable />,
    permission: "admin",
  },
  {
    path: "/maintainers",
    element: <Maintainers />,
    permission: "admin",
  },
  {
    path: "/expenses",
    element: <Expenses />,
    permission: "admin",
  },
  {
    path: "/property",
    element: <Property />,
  },
  {
    path: "/Property/details",
    element: <PropertyDetails />,
  },
  {
    path: "/Property/:id",
    element: <UpdateForms />,
  },
  {
    path: "/billing-center",
    element: <BillingCenter />,
    permission: "admin",
  },
  {
    path: "/invoice-type",
    element: <InvoiceType />,
    permission: "admin",
  },
  {
    path: "/vendors",
    element: <Vendors />,
  },

  {
    path: "/helpdesk",
    element: <HelpDesk />,
  },
  {
    path: "/maintenance",
    element: <Maintenance />,
  },
  {
    path: "/assign-vendors",
    element: <AssignVendorsList />,
  },
  {
    path: "/tenant",
    element: <Tenant />,
  },
  {
    path: "/document",
    element: <Document />,
  },
  {
    path: "/assets",
    element: <Assets />,
  },
  {
    path: "/assets/assets-details",
    element: <AssetsDetail />,
  },
  {
    path: "/notice-board",
    element: <NoticeBoard />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/report",
    element: <DashBoard />,
  },
  {
    path: "/helpdesk-type",
    element: <HelpDeskTypeList />,
  },
  {
    path: "/visitor-management",
    element: <Visitor/>
  },
  {
    path: "/amenities",
    element: <Amenities />
  },
  { 
    path: "/parking",
    element: <Parking />
  }
];

export const AuthRoutes: DtoRoutesDefinition[] = [
    {
        path: "/sign-in",
        element: <LoginPage />
    },
    {
        path: "/sign-up",
        element: <></>
    },
    {
        path: "/set-password",
        element: <></>
    }
];
