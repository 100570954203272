import React, { useState } from "react";
import CustomTextfield, {
  TextFieldForArray,
} from "../../components/textfield1";
import styles from "./rendAndCharges.module.css";
import RadioButton1 from "../../components/radioButton";
import { radioButton1 } from "../../types/interface";
import { FormButton1 } from "../../components/formButton";
import { useAppDispatch } from "../../../../Store/hooks";
import { DropdownOptionType, UnitApiResponse } from "../../utlity/interface";
import DropdownSelect from "../../components/dropdown1";
import BasicDatePicker from "../../components/datePicker";
import { showToaster } from "../../../../Store/toastSlice";
import { showLoader } from "../../../../Store/loaderSlice";
import { isApiConnected } from "../../controls";
import { PostApiMethod1, PutApiMethod1 } from "../../api_service";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface PropertyInformationFormType {
  backBtn: Function;
  submitBtn: Function;
  CreatedUnitDetailsFromApi: any;
  CreatedNo_of_unitsFromApi: string;
  updateValues?:any,
  isUpdate?: boolean;
}

interface ApiBody {
  [key: string]: string; // Index signature to allow additional key-value pairs
}
const RentAndChargesForm: React.FunctionComponent<
  PropertyInformationFormType
> = ({
  backBtn,
  submitBtn,
  CreatedUnitDetailsFromApi,
  CreatedNo_of_unitsFromApi,
  updateValues,
  isUpdate
}) => {
  const [ApiBodyFinal, setApiBodyFinal] = useState<ApiBody>({});
  const [formDataFinal, setformDataFinal] = useState(new FormData());
  const [month_or_year_or_custom, setmonth_or_year_or_custom] =
    useState("monthly");
  const [unitNameList, setunitNameList] = useState<DropdownOptionType[]>();
  const dispatch = useAppDispatch();
  const staticDropDown: DropdownOptionType[] = [{ value: "", label: "Choose" }];

  function setInputValue(InputValue: any, inputName: string, Index: number) {
    setApiBodyFinal({ ...ApiBodyFinal, [inputName]: InputValue });
    const unitIdValue = isUpdate ? updateValues.id : CreatedUnitDetailsFromApi[Index]["id"];
    const unitIdKey = `data[${Index}][id]`;
    formDataFinal.delete(unitIdKey);
    formDataFinal.delete(inputName);
    formDataFinal.delete(`data[${Index}][rent_type]`);
    formDataFinal.append(`data[${Index}][rent_type]`, "monthly");
    formDataFinal.append(unitIdKey, unitIdValue);
    formDataFinal.append(inputName, InputValue);
    setformDataFinal(formDataFinal);
    // Object.entries(formDataFinal).forEach(([key, value]) => {
    //   console.log("2334formaata", key, value);
    //   console.log("woddddd");
    // });
    console.log("ApiBodyFinal: " + JSON.stringify(ApiBodyFinal));
  }

  function changePropertyType(InputValue: any, inputName: string, Index: number) {
    console.log("ApiBodyFinal: " + JSON.stringify(ApiBodyFinal));
    setmonth_or_year_or_custom(InputValue);
    setApiBodyFinal({ ...ApiBodyFinal, [inputName]: InputValue });
    setInputValue(InputValue,inputName,Index);
  }

  function backBtnFunc() {
    console.log("back btn called");
    backBtn("Rent & Charges");
  }

  async function submmitForm(event: any) {
    event.preventDefault();
    console.log("submit form: " + JSON.stringify(ApiBodyFinal));
    if (isApiConnected) {
    } else {
      console.log("api off in controler");
      submitBtn("Rent & Charges");
      return;
    }
    dispatch(showLoader({ showLoader: true }));

    try {
      Object.entries(formDataFinal).forEach(([key, value]) => {
        console.log("fform data111111", key, value);
      });
      //
      // ApiBodyFinal
      const res = await PutApiMethod1(
        "property/unit/updateRate",
        formDataFinal
      );

      if (res["data"]["code"] === 201) {
        console.log("res: " + JSON.stringify(res["data"]["results"]["data"]));
        const rentUpdateResponse = res["data"]["results"]["data"];
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: "Rates Updated Successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        submitBtn("Rent & Charges", rentUpdateResponse);
        dispatch(showLoader({ showLoader: false }));
      } else {
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: "Unable to process now, Try again",
            showDialog: true,
            timer: "5000",
          })
        );
      }
    } catch (e: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          // dialogMsg: e.response.data.message,
          dialogMsg: "Error, Try again",
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  }
  console.log(updateValues);
  const generateForms = () => {
    const forms = [];
    for (let i = 0; i < Number(CreatedNo_of_unitsFromApi); i++) {
      // console.log(CreatedUnitDetailsFromApi);
      // setunitNameList([
      //   {
      //     value: CreatedUnitDetailsFromApi[i]["unit_name"],
      //     label: CreatedUnitDetailsFromApi[i]["unit_name"],
      //   },
      // ]);
      forms.push(
        <div>
          <div className={styles.formItem}>
            <TextFieldForArray
              label={"Unit Name"}
              name={isUpdate ?"data[" + i + "][unit_name]" : CreatedUnitDetailsFromApi[i]["unit_name"]}
              type={"text"}
              value={isUpdate ? updateValues.unit_name: ""}
              valueChange={setInputValue}
              indexOfTextField={i}
              nonEditable={!isUpdate}
            />
          </div>
          <div className={styles.rentAmountBox}>
            <TextFieldForArray
              label={"General Rent"}
              name={"data[" + i + "][general_rent]"}
              type={"number"}
              valueChange={setInputValue}
              value={isUpdate ? updateValues.general_rent: ""}
              indexOfTextField={i}
            />
            <TextFieldForArray
              label={"Security Deposit"}
              name={"data[" + i + "][security_deposit]"}
              type={"number"}
              value={isUpdate ? updateValues.security_deposit: ""}
              valueChange={setInputValue}
              indexOfTextField={i}
            />
            <TextFieldForArray
              label={"Late Fee"}
              name={"data[" + i + "][late_fee]"}
              type={"number"}
              valueChange={setInputValue}
              value={isUpdate ? updateValues.late_fee: ""}
              indexOfTextField={i}
            />
          </div>
          <div className={styles.formItem}>
            <TextFieldForArray
              label={"Incident Happen"}
              name={"data[" + i + "][incident_receipt]"}
              type={"text"}
              valueChange={setInputValue}
              value={isUpdate ? updateValues.incident_receipt: ""}
              indexOfTextField={i}
            />
          </div>
          {/* <div className={styles.rentType}>
            <label htmlFor="rent_type">Rent Type</label>
            <div className={styles.radioItem}>
              <RadioButton1
                name={"data[" + i + "][rent_type]"}
                valueChange={changePropertyType}
                // valueChange={setInputValue}
                label={"Monthly"}
                value={"monthly"}
                indexOfTextField={i}
                isChecked={month_or_year_or_custom === "monthly"}
              />
              <RadioButton1
                name={"data[" + i + "][rent_type]"}
                valueChange={changePropertyType}
                // valueChange={setInputValue}
                label={"Yearly"}
                value={"yearly"}
                isChecked={month_or_year_or_custom === "yearly"}
                indexOfTextField={i}

              />
              <RadioButton1
                name={"data[" + i + "][rent_type]"}
                valueChange={changePropertyType}
                label={"Custom"}
                value={"custom"}
                isChecked={month_or_year_or_custom === "custom"}
              />
            </div>
          </div>
          <div className={styles.dueDetails}>
            {month_or_year_or_custom === "monthly" ? (
              <TextFieldForArray
                label={"Due Date"}
                name={"data[" + i + "][monthly_due_day]"}
                type={"number"}
                valueChange={setInputValue}
                indexOfTextField={0}
                placeHolder="Type date of month: 1 to 30"
              />
            ) : month_or_year_or_custom === "yearly" ? (
              <TextFieldForArray
                label={"Due Month"}
                name={"data[" + i + "][yearly_due_day]"}
                type={"number"}
                valueChange={setInputValue}
                indexOfTextField={0}
                placeHolder="Type month of year: 1 to 12"
              />
            ) : month_or_year_or_custom === "custom" ? (
              <div>
                <BasicDatePicker
                  label={"Lease Start Date"}
                  name={"data[" + i + "][lease_start_date]"}
                  valueChange={setInputValue}
                  index={0}
                />
                <BasicDatePicker
                  label={"Lease End Date"}
                  name={"data[" + i + "][lease_end_date]"}
                  valueChange={setInputValue}
                  index={0}
                />
                <TextFieldForArray
                  label={"Payment Due on Date"}
                  name={"data[" + i + "][lease_payment_due_day]"}
                  type={"text"}
                  valueChange={setInputValue}
                  indexOfTextField={0}
                  placeHolder="Type month of year: 1 to 12"
                />
              </div>
            ) : null}
          </div> */}
        </div>

        // <div>
        //   <p>Unit No - {i + 1}</p>
        //   <div className={styles.formItem}>
        //     <TextFieldForArray
        //       label={"Unit Name"}
        //       name={CreatedUnitDetailsFromApi[i]["unit_name"]}
        //       type={"text"}
        //       valueChange={() => {}}
        //       indexOfTextField={0}
        //       nonEditable={true}
        //     />
        //   </div>
        //   <div className={styles.rentAmountBox}>
        //     <TextFieldForArray
        //       label={"General Rent"}
        //       name={"[general_rent]"}
        //       type={"number"}
        //       valueChange={setInputValue}
        //       indexOfTextField={0}
        //     />
        //     <TextFieldForArray
        //       label={"Security Deposit"}
        //       name={"security_deposit"}
        //       type={"number"}
        //       valueChange={setInputValue}
        //       indexOfTextField={0}
        //     />
        //     <TextFieldForArray
        //       label={"Late Fee"}
        //       name={"late_fee"}
        //       type={"number"}
        //       valueChange={setInputValue}
        //       indexOfTextField={0}
        //     />{" "}
        //   </div>
        //   <div className={styles.formItem}>
        //     <TextFieldForArray
        //       label={"Incident Happen"}
        //       name={"incident_receipt"}
        //       type={"text"}
        //       valueChange={setInputValue}
        //       indexOfTextField={0}
        //     />
        //   </div>
        //   <div className={styles.rentType}>
        //     <label htmlFor="rent_type">Rent Type</label>
        //     <div className={styles.radioItem}>
        //       <RadioButton1
        //         name={"rent_type"}
        //         valueChange={changePropertyType}
        //         label={"Monthly"}
        //         value={"monthly"}
        //         isChecked={month_or_year_or_custom === "monthly"}
        //       />
        //       <RadioButton1
        //         name={"rent_type"}
        //         valueChange={changePropertyType}
        //         label={"Yearly"}
        //         value={"yearly"}
        //         isChecked={month_or_year_or_custom === "yearly"}
        //       />{" "}
        //       <RadioButton1
        //         name={"rent_type"}
        //         valueChange={changePropertyType}
        //         label={"Custom"}
        //         value={"custom"}
        //         isChecked={month_or_year_or_custom === "custom"}
        //       />
        //     </div>
        //   </div>
        //   <div className={styles.dueDetails}>
        //     {month_or_year_or_custom === "monthly" ? (
        //       <TextFieldForArray
        //         label={"Due Date"}
        //         name={"monthly_due_day"}
        //         type={"number"}
        //         valueChange={setInputValue}
        //         indexOfTextField={0}
        //         placeHolder="Type date of month: 1 to 30"
        //       />
        //     ) : month_or_year_or_custom === "yearly" ? (
        //       <TextFieldForArray
        //         label={"Due Month"}
        //         name={"yearly_due_day"}
        //         type={"number"}
        //         valueChange={setInputValue}
        //         indexOfTextField={0}
        //         placeHolder="Type month of year: 1 to 12"
        //       />
        //     ) : month_or_year_or_custom === "custom" ? (
        //       <div>
        //         <BasicDatePicker
        //           label={"Lease Start Date"}
        //           name={"lease_start_date"}
        //           valueChange={setInputValue}
        //           index={0}
        //         />
        //         <BasicDatePicker
        //           label={"Lease End Date"}
        //           name={"lease_end_date"}
        //           valueChange={setInputValue}
        //           index={0}
        //         />
        //         <TextFieldForArray
        //           label={"Payment Due on Date"}
        //           name={"lease_payment_due_day"}
        //           type={"text"}
        //           valueChange={setInputValue}
        //           indexOfTextField={0}
        //           placeHolder="Type month of year: 1 to 12"
        //         />
        //       </div>
        //     ) : null}
        //   </div>
        //   <hr />
        // </div>
      );
    }
    return forms;
  };

  return (
    <>
      <div className={styles.topBox}>
        <form onSubmit={submmitForm} className={styles.form}>
          {/* <div className={styles.formItem}>
            <DropdownSelect
              options={
                unitNameList === undefined ? staticDropDown : unitNameList
              }
              onChange={setInputValue}
              lable={"Unit Name"}
            />
          </div> */}
          {generateForms()}

          {/* <FormButton1 backBtnClick={backBtnFunc} /> */}
          <div className="mt-3">
            <button type="button" className="btn btn-white" onClick={backBtnFunc}><KeyboardArrowLeftIcon /> Back</button>
            <button type="submit" className="btn btn-danger ms-3">Update</button>
          </div>
        </form>
        {/* <input type="text" placeholder="place" name="lllll" /> */}
      </div>
    </>
  );
};
export default RentAndChargesForm;

interface PropertyContent {
  type: "text" | "number";
  lable: string;
  name: string;
}
const rentContents2: PropertyContent[] = [
  {
    type: "text",
    lable: "General Rent",
    name: "property_name",
  },
  {
    type: "number",
    lable: "Security Deposit",
    name: "number_of_unit",
  },
  {
    type: "text",
    lable: "Late Fee",
    name: "description",
  },
];
const rentContents1: PropertyContent[] = [
  {
    type: "text",
    lable: "Unit Name",
    name: "property_name",
  },
];
const rentContents3: PropertyContent[] = [
  {
    type: "text",
    lable: "Incident Receipt",
    name: "property_name",
  },
];

const leasePropertyContents: PropertyContent[] = [
  {
    type: "text",
    lable: "Property Name",
    name: "property_name",
  },
  {
    type: "number",
    lable: "Number of Units",
    name: "number_of_unit",
  },
  {
    type: "number",
    lable: "Lease Amount",
    name: "lease_amount",
  },
  {
    type: "text",
    lable: "Property Name",
    name: "property_name",
  },
  {
    type: "number",
    lable: "Number of Units",
    name: "number_of_unit",
  },
  {
    type: "text",
    lable: "Description",
    name: "description",
  },
];

//    <div>
// <p>Unit No - {i + 1}</p>
// <div className={styles.formItem}>
//   <TextFieldForArray
//     label={"Unit Name"}
//     name={CreatedUnitDetailsFromApi[i]["unit_name"]}
//     type={"text"}
//     valueChange={() => {}}
//     indexOfTextField={0}
//     nonEditable={true}
//   />
// </div>
// <div className={styles.rentAmountBox}>
//   <TextFieldForArray
//     label={"General Rent"}
//     name={'data['+i+'][general_rent]'}
//     type={"number"}
//     valueChange={setInputValue}
//     indexOfTextField={0}
//   />
//   <TextFieldForArray
//     label={"Security Deposit"}
//     name={"security_deposit"}
//     type={"number"}
//     valueChange={setInputValue}
//     indexOfTextField={0}
//   />
//   <TextFieldForArray
//     label={"Late Fee"}
//     name={"late_fee"}
//     type={"number"}
//     valueChange={setInputValue}
//     indexOfTextField={0}
//   />{" "}
// </div>
// <div className={styles.formItem}>
//   <TextFieldForArray
//     label={"Incident Happen"}
//     name={"incident_receipt"}
//     type={"text"}
//     valueChange={setInputValue}
//     indexOfTextField={0}
//   />
// </div>
// <div className={styles.rentType}>
//   <label htmlFor="rent_type">Rent Type</label>
//   <div className={styles.radioItem}>
//     <RadioButton1
//       name={"rent_type"}
//       valueChange={changePropertyType}
//       label={"Monthly"}
//       value={"monthly"}
//       isChecked={month_or_year_or_custom === "monthly"}
//     />
//     <RadioButton1
//       name={"rent_type"}
//       valueChange={changePropertyType}
//       label={"Yearly"}
//       value={"yearly"}
//       isChecked={month_or_year_or_custom === "yearly"}
//     />{" "}
//     <RadioButton1
//       name={"rent_type"}
//       valueChange={changePropertyType}
//       label={"Custom"}
//       value={"custom"}
//       isChecked={month_or_year_or_custom === "custom"}
//     />
//   </div>
// </div>
// <div className={styles.dueDetails}>
//   {month_or_year_or_custom === "monthly" ? (
//     <TextFieldForArray
//       label={"Due Date"}
//       name={"monthly_due_day"}
//       type={"number"}
//       valueChange={setInputValue}
//       indexOfTextField={0}
//       placeHolder="Type date of month: 1 to 30"
//     />
//   ) : month_or_year_or_custom === "yearly" ? (
//     <TextFieldForArray
//       label={"Due Month"}
//       name={"yearly_due_day"}
//       type={"number"}
//       valueChange={setInputValue}
//       indexOfTextField={0}
//       placeHolder="Type month of year: 1 to 12"
//     />
//   ) : month_or_year_or_custom === "custom" ? (
//     <div>
//       <BasicDatePicker
//         label={"Lease Start Date"}
//         name={"lease_start_date"}
//         valueChange={setInputValue}
//         index={0}
//       />
//       <BasicDatePicker
//         label={"Lease End Date"}
//         name={"lease_end_date"}
//         valueChange={setInputValue}
//         index={0}
//       />
//       <TextFieldForArray
//         label={"Payment Due on Date"}
//         name={"lease_payment_due_day"}
//         type={"text"}
//         valueChange={setInputValue}
//         indexOfTextField={0}
//         placeHolder="Type month of year: 1 to 12"
//       />
//     </div>
//   ) : null}
// </div>
// <hr />
// </div>

{
  /* <div>
          <p>Unit No - {i + 1}</p>
          <div className={styles.formItem}>
            <TextFieldForArray
              label={"Unit Name"}
              name={CreatedUnitDetailsFromApi[i]["unit_name"]}
              type={"text"}
              valueChange={() => {}}
              indexOfTextField={i}
              nonEditable={true}
            />
          </div>
          <div className={styles.rentAmountBox}>
            <TextFieldForArray
              label={"General Rent"}
              name={"data[" + i + "][general_rent]"}
              type={"number"}
              valueChange={setInputValue}
              indexOfTextField={i}
            />
            <TextFieldForArray
              label={"Security Deposit"}
              name={"data[" + i + "][security_deposit]"}
              type={"number"}
              valueChange={setInputValue}
              indexOfTextField={i}
            />
            <TextFieldForArray
              label={"Late Fee"}
              name={"data[" + i + "][late_fee]"}
              type={"number"}
              valueChange={setInputValue}
              indexOfTextField={i}
            />{" "}
          </div>
          <div className={styles.formItem}>
            <TextFieldForArray
              label={"Incident Happen"}
              name={"data[" + i + "][incident_receipt]"}
              type={"text"}
              valueChange={setInputValue}
              indexOfTextField={i}
            />
          </div>
          <div className={styles.rentType}>
            <label htmlFor="rent_type">Rent Type</label>
            <div className={styles.radioItem}>
              <RadioButton1
                name={"data[" + i + "][rent_type]"}
                valueChange={changePropertyType}
                label={"Monthly"}
                value={"monthly"}
                isChecked={month_or_year_or_custom === "monthly"}
              />
              <RadioButton1
                name={"data[" + i + "][rent_type]"}
                valueChange={changePropertyType}
                label={"Yearly"}
                value={"yearly"}
                isChecked={month_or_year_or_custom === "yearly"}
              />{" "}
              <RadioButton1
                name={"data[" + i + "][rent_type]"}
                valueChange={changePropertyType}
                label={"Custom"}
                value={"custom"}
                isChecked={month_or_year_or_custom === "custom"}
              />
            </div>
          </div>
          <div className={styles.dueDetails}>
            {month_or_year_or_custom === "monthly" ? (
              <TextFieldForArray
                label={"Due Date"}
                name={"data[" + i + "][monthly_due_day]"}
                type={"number"}
                valueChange={setInputValue}
                indexOfTextField={0}
                placeHolder="Type date of month: 1 to 30"
              />
            ) : month_or_year_or_custom === "yearly" ? (
              <TextFieldForArray
                label={"Due Month"}
                name={"data[" + i + "][yearly_due_day]"}
                type={"number"}
                valueChange={setInputValue}
                indexOfTextField={0}
                placeHolder="Type month of year: 1 to 12"
              />
            ) : month_or_year_or_custom === "custom" ? (
              <div>
                <BasicDatePicker
                  label={"Lease Start Date"}
                  name={"data[" + i + "][lease_start_date]"}
                  valueChange={setInputValue}
                  index={0}
                />
                <BasicDatePicker
                  label={"Lease End Date"}
                  name={"data[" + i + "][lease_end_date]"}
                  valueChange={setInputValue}
                  index={0}
                />
                <TextFieldForArray
                  label={"Payment Due on Date"}
                  name={"data[" + i + "][lease_payment_due_day]"}
                  type={"text"}
                  valueChange={setInputValue}
                  indexOfTextField={0}
                  placeHolder="Type month of year: 1 to 12"
                />
              </div>
            ) : null}
          </div>
          <hr />
        </div> */
}
