import React from "react";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import Stack from "@mui/material/Stack";

const data = [
  { label: "Group A", value: 400, color: "#FFA95D" },
  { label: "Group B", value: 300, color: "#60A3F9" },
];
const OffChart = () => {
  return (
    <>
      <Card className="h-100 custom-card dashboard-card">
        <Typography
          className="poppins-family fw-bold"
          sx={{ fontSize: "14px" }}
        >
          Leases Which are Expiring
        </Typography>
        <Stack direction="row">
          <PieChart
            series={[
              {
                innerRadius: 50,
                outerRadius: 80,
                paddingAngle: 5,
                cornerRadius: 0,
                startAngle: -100,
                endAngle: 100,
                cx: 92,
                cy: 100,
                data,
              },
            ]}
            margin={{ right: 5 }}
            width={200}
            height={200}
            legend={{ hidden: true }}
          />
        </Stack>
      </Card>
    </>
  );
};

export default OffChart;
