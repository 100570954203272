import React, { useCallback, useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { Box } from "@mui/material";
import IconDocument from "../../Assets/images/icon_document.svg";
import "./document.css";
import DocumentFile from "../../Assets/images/document-file.png";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import { PostApiMethod } from "../../Utils/ApiService";
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";

const Document = () => {
  const [selectedLevelOne, setSelectedLevelOne] = useState(null);
  const [selectedLevelTwo, setSelectedLevelTwo] = useState(null);
  const [selectedLevelThree, setSelectedLevelThree] = useState(0);

  const levelOneItems = ["Property", "Tenants", "Tickets"];
  const levelTwoItems = ["Subdoc 1", "Subdoc 2", "Subdoc 3"];
  const levelThreeItems = ["File 1", "File 2", "File 3"];
  const [tenantList, setTenantList] = useState([]);
  const [PropertyList, setPropertyList] = useState<any[]>([]);
  const [ticketList, setTicketList] = useState<any[]>([]);
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  const dispatch = useAppDispatch();
  const [selectedMenu, setSelecteedMenu] = useState("");
  const fetchTenantList = useCallback(() => {
    const formdata: any = {
      role: "PMU",
    };
    dispatch(showLoader({ showLoader: true }));
    PostApiMethod("user/getUser", formdata)
      .then((response) => {
        dispatch(showLoader({ showLoader: false }));
        setTenantList(response.data.results.data);
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
      });
  }, []);

  const fetchProperty = useCallback(() => {
    dispatch(showLoader({ showLoader: true }));
    PostApiMethod("property/properties/list", {})
      .then((response) => {
        dispatch(showLoader({ showLoader: false }));
        setPropertyList(response.data.results.data);
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
      });
  }, []);

  const fetchHelpDesk = useCallback(() => {
    dispatch(showLoader({ showLoader: true }));
    PostApiMethod("property/help-desk/list", {})
      .then((response) => {
        dispatch(showLoader({ showLoader: false }));
        setTicketList(response.data.results.data);
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
      });
  }, []);

  const handleLevelOneClick = (index: any, item: string) => {
    setSelectedLevelOne(index);
    setSelectedLevelTwo(null); // Reset level two selection
    setSelecteedMenu(item);
    setPropertyDocuments([]);
    if (item === "Tenants" && tenantList.length <= 0) {
      fetchTenantList();
    } else if (item === "Property" && PropertyList.length <= 0) {
      fetchProperty();
    } else if (item === "Tickets" && ticketList.length <= 0) {
      fetchHelpDesk();
    }
  };

  const handleLevelTwoClick = (index: any, type: string) => {
    setSelectedLevelTwo(index);
  };
  console.log(propertyDocuments);
  return (
    <AuthorizedLayout>
      <div className="row document-listing">
        <div className="col-md-3">
          <Box className="d-flex align-items-center mb-3 document-header">
            <img
              src={IconDocument}
              alt="Firebridge"
              className="page-header-icon"
            />
            <p className="psemiboldfont page-header-text mb-0">Documents</p>
          </Box>
          <ul className="level-one">
            {levelOneItems.map((item, index) => (
              <li
                key={index}
                className={index === selectedLevelOne ? "active" : ""}
                onClick={() => handleLevelOneClick(index, item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        {selectedLevelOne !== null && (
          <div className="col-md-3">
            <ol className="level-two">
              {selectedMenu === "Tenants" ? (
                <>
                  {tenantList.map((item: any, index: number) => (
                    <li
                      key={index}
                      className={index === selectedLevelTwo ? "active" : ""}
                      onClick={() => handleLevelTwoClick(index, item.firstName)}
                    >
                      {item.firstName}
                    </li>
                  ))}
                </>
              ) : selectedMenu === "Property" ? (
                <>
                  {PropertyList.map((item: any, index: number) => (
                    <li
                      key={index}
                      className={index === selectedLevelTwo ? "active" : ""}
                      onClick={() => {
                        setPropertyDocuments(item.FileManagers);
                        handleLevelTwoClick(index, item.property_name);
                        setSelectedLevelThree(0);
                      }}
                    >
                      {item.property_name}
                    </li>
                  ))}
                </>
              ) : selectedMenu === "Tickets" ? (
                <>
                  {ticketList.map((item: any, index: number) => (
                    <li
                      key={index}
                      className={index === selectedLevelTwo ? "active" : ""}
                      onClick={() => {
                        setPropertyDocuments(item.FileManagers);
                        handleLevelTwoClick(index, item.ticketNo);
                        setSelectedLevelThree(0);
                      }}
                    >
                      {item.ticketNo}
                    </li>
                  ))}
                </>
              ) : (
                <></>
              )}
            </ol>
          </div>
        )}

        {selectedLevelTwo !== null && (
          <div className="col-md-3">
            <ul className="level-three">
              {/* {selectedMenu === "Property" ? ( */}
              <>
                {propertyDocuments.map((item: any, index: number) => (
                  <li
                    key={index}
                    className={index === selectedLevelThree ? "active" : ""}
                    onClick={() => {
                      setSelectedLevelThree(index);
                    }}
                  >
                    {item.folderPath.split("/").pop()}
                  </li>
                ))}
              </>
              {/* ) : (
                <></>
              )} */}
              {/* {levelThreeItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))} */}
            </ul>
          </div>
        )}

        {selectedLevelTwo !== null && propertyDocuments.length > 0 && (
          <div className="col-md-3">
            <div className="document-file">
              <img
                src={
                  propertyDocuments.length > 0
                    ? process.env.REACT_APP_API_URL +
                      "/" +
                      propertyDocuments[selectedLevelThree]["folderPath"]
                    : ""
                }
                alt=""
                width={100}
                height={200}
              />
              <ButtonPrimary
                text="View File"
                className=""
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_API_URL +
                      "/" +
                      propertyDocuments[selectedLevelThree]["folderPath"],
                    "_blank"
                  );
                }}
              />
              <div className="table-striped table-striped-header">
                <table>
                  <tbody>
                    <tr>
                      <th>File Name</th>
                      <td>
                        {(
                          propertyDocuments[selectedLevelThree][
                            "folderPath"
                          ] as string
                        )
                          .split("/")
                          .pop()}
                      </td>
                    </tr>
                    <tr>
                      <th>File Size</th>
                      <td>3.2MB</td>
                    </tr>
                    <tr>
                      <th>Created on</th>
                      <td>12 April 2024</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default Document;
