import * as React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { NextWeek } from "@mui/icons-material";

interface SelectComponentProps {
  value: any;
  options: any;
  getOptionLabel: (option: any) => string;
  name: string;
  onChange: (newValue: any) => void;
}

const SelectComponent = (props: SelectComponentProps) => {
  const { value, options, getOptionLabel } = props;
  return (
    <>
      <Autocomplete
        key={options.length < 0 ? options[0].id: 0}
        id="size-small-standard"
        size="small"
        value={value}
        onChange={(event, newValue) => {
          props.onChange(newValue);
        }}
        options={options}
        getOptionLabel={getOptionLabel}
        disableClearable={true} 
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Choose"
            name={props.name}
          />
        )}
      />
    </>
  );
};

export default SelectComponent;
