import React, { useState, useCallback, useEffect, createContext } from 'react';
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import { Card, Box, Typography } from '@mui/material';
import propertyImg from "../../Assets/images/icons/property.png";
import AddIcon from '@mui/icons-material/Add';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import Property from './Property';
import Unit from './Unit';
import { DeleteApiMethod, GetApiMethod, PostApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import AddProperty from './AddProperty';
import ViewProperty from './ViewProperty';
import DeleteDialog from '../../Components/DeleteDialog';

export const PropertyContext = createContext();

const Main = () => {
    const [tabValue, setTabValue] = useState(0);
    const [propertyPage, setPropertyPage] = useState({page:1,limit:5,count:0});
    const [propertyData, setPropertyData] = useState([]);
    const [unitPage, setUnitPage] = useState({page:1,limit:5,count:0});
    const [unitData, setUnitData] = useState([]);
    const [isPropertyList, setIsPropertyList] = useState(true);
    const [isUnitList, setIsUnitList] = useState(true);
    const [amenitiesList, setAmenitiesList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isUnitEdit, setIsUnitEdit] = useState(false);
    const [isUnitEditData, setIsUnitEditData] = useState({});
    const [propertyEditData, setPropertyEditData] = useState({});
    const [isViewList, setIsViewList] = useState(false);
    const [viewData, setViewData] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);
    const [deleteDetails, setDeleteDetails] = useState({});
    const dispatch = useAppDispatch();

    const handleChange = useCallback((e,newValue) => {
        setTabValue(newValue);
        setIsEdit(false);
        setIsUnitEdit(false);
    },[tabValue])

    useEffect(() => {
        getPropertyList(propertyPage);
        getUnitList(unitPage);
        getAmenities();
    },[])

    const getPropertyList = useCallback(async(params) => {
        dispatch(showLoader({showLoader:true}))
        var parameters = params.limit === -1 ? {} : {page:params.page,limit:params.limit};
        try{
            let response = await PostApiMethod("property/properties/list",parameters);
            if(response["data"]["error"] === false){
                setPropertyData(response["data"]["results"]["data"])
                setPropertyPage((prevPage) => ({...prevPage,count: response["data"]["results"]["totalCount"],}))
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to fetch the property list",
                showDialog: true,
                timer: "3000"}))
        }
        dispatch(showLoader({showLoader:false}))
    },[propertyData,propertyPage])

    const getUnitList = useCallback(async(params) => {
        dispatch(showLoader({showLoader:true}))
        var parameters = params.limit === -1 ? {} : {page:params.page,limit:params.limit};
        try{
            let response = await PostApiMethod("property/unit/list",parameters);
            if(response["data"]["error"] === false){
                setUnitData(response["data"]["results"]["data"])
                setUnitPage((prevPage) => ({...prevPage,count: response["data"]["results"]["totalCount"],}))
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to fetch the unit list",
                showDialog: true,
                timer: "3000"}))
        }
        dispatch(showLoader({showLoader:false}))
    },[unitData,unitPage]);

    const getAmenities = useCallback(async() => {
        dispatch(showLoader({showLoader:true}))
        try{
            let response = await PostApiMethod("property/amenities/list",{});
            if(response["data"]["error"] === false){
                setAmenitiesList(response["data"]["results"]["data"])
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to fetch the amenities list",
                showDialog: true,
                timer: "3000"}))
        }
        dispatch(showLoader({showLoader:false}))
    },[amenitiesList])

    const handlePropertyNextPage = useCallback((val) => {
        setPropertyPage({...propertyPage,page:val.page,limit:val.limit});
        getPropertyList(val);
    },[propertyPage])

    const handleUnitNextPage = useCallback((val) => {
        setUnitPage({...unitPage,page:val.page,limit:val.limit});
        getUnitList(val);
    },[unitPage])

    const propertySearchString = useCallback(async(string) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let response = await PostApiMethod("property/properties/list",{page:propertyPage.page,limit:propertyPage.limit,searchString:string});
            if(response["data"]["error"] === false){
                setPropertyData(response["data"]["results"]["data"])
                setPropertyPage((prevPage) => ({...prevPage,count: response["data"]["results"]["totalCount"],}))
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to fetch search string value",
                showDialog: true,
                timer: "3000"}))
        }
        dispatch(showLoader({showLoader:false}))
    },[propertyData])

    const unitSearchString = useCallback(async(string) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let response = await PostApiMethod("property/unit/list",{page:unitPage.page,limit:unitPage.limit,searchString:string})
            if(response["data"]["error"] === false){
                setUnitData(response["data"]["results"]["data"])
                setUnitPage((prevPage) => ({...prevPage,count: response["data"]["results"]["totalCount"],}))
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to fetch unit search string value",
                showDialog: true,
                timer: "3000"}))
        }
        dispatch(showLoader({showLoader:false}))
    },[unitData])

    const addNewProperty = useCallback(() => {
        setIsPropertyList(false);
        setIsEdit(false);
    },[isPropertyList])

    const onBackPropertyList = useCallback(() => {
        setIsPropertyList(true)
    },[isPropertyList]);

    const onEdit = useCallback(async(val) => {
        dispatch(showLoader({showLoader:true}))
        let unit = unitData.filter((v) => val.id === v.property_id)
        setIsUnitEditData(unit)
        setPropertyEditData(val);
        setIsPropertyList(false);
        setIsViewList(false);
        setIsEdit(true);
        dispatch(showLoader({showLoader:false}))
    },[isEdit]);

    const onEditUnit = useCallback(async(val) => {
        setIsUnitEditData([val])
        setIsUnitEdit(true);
        setIsUnitList(false);
    },[isUnitEdit])

    const onBackUnitList = useCallback(() => {
        setIsUnitEdit(false);
        setIsUnitList(true);
        setIsUnitEditData([]);
    },[isUnitEdit])

    const onViewList = useCallback(async(val) => {
        console.log(val)
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await Promise.all([PostApiMethod("/property/unit/getUnitByPropertyId",{property_id:val.id,user_id:val.owner_user_id}),GetApiMethod("property/properties/"+val.id)])
            if(res[0]["data"]["error"] === false){
                setViewData((prev) => ({...prev,...{unit:res[0]["data"]["results"]["data"]["rows"]}}))
            }
            if(res[1]["data"]["error"] === false){
                setViewData((prev) => ({...prev,...{property:res[1]["data"]["results"]["data"]}}))
            }
            setIsViewList(true)
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to fetch property & unit",
                showDialog: true,
                timer: "3000"}))
        }
        dispatch(showLoader({showLoader:false}))
    },[isViewList])

    const onDeleteProperty = useCallback(async(id) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await DeleteApiMethod("property/properties/"+id);
        }catch(error){
            if(error.response){
                setDeletePopup(true);
                setDeleteDetails({message:error.response.data.message})
            }
        }
        dispatch(showLoader({showLoader:false}))
    },[propertyData])
    
    return (
        <>
            <AuthorizedLayout>
                <PropertyContext.Provider value={{
                    propertyList:propertyData,
                    propertyPage:propertyPage,
                    unitList:unitData,
                    unitPage:unitPage,
                    handlePropertyNextPage:handlePropertyNextPage,
                    propertySearchString:propertySearchString,
                    handleUnitNextPage:handleUnitNextPage,
                    unitSearchString:unitSearchString,
                    onBackPropertyList:onBackPropertyList,
                    amenitiesList:amenitiesList,
                    onEdit:onEdit,
                    isEdit:isEdit,
                    onEditUnit:onEditUnit,
                    isUnitEdit:isUnitEdit,
                    onBackUnitList:onBackUnitList,
                    isUnitEditData:isUnitEditData,
                    propertyEditData:propertyEditData,
                    getPropertyList:getPropertyList,
                    getUnitList:getUnitList,
                    setIsViewList:setIsViewList,
                    onViewList:onViewList,
                    viewData:viewData,
                    onDeleteProperty:onDeleteProperty
                }}>
                    <Card elevation={0}>
                        <Box className="d-flex justify-content-between align-items-center flex-wrap">
                            <Box className="d-flex align-items-center flex-wrap">
                                <img src={propertyImg} alt="Property"  />
                                <Typography className="fw-bold fs-5">Property</Typography>
                                <AntTabs value={tabValue} onChange={handleChange}>
                                    <AntTab label="All Property" className='tab-menu'/>
                                    <AntTab label="All Units" className='tab-menu' />
                                </AntTabs>
                            </Box>
                            {!isViewList ? tabValue === 0 && isPropertyList && <button className='btn btn-danger' onClick={addNewProperty}><AddIcon />Add New Property</button>
                            : <button className='btn btn-danger' onClick={() => onEdit(viewData.property)}>Edit Info</button>}
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                            { !isViewList ? isPropertyList ? <Property /> : <AddProperty /> : <ViewProperty /> }
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            { isUnitList ? <Unit /> : <AddProperty /> }
                        </CustomTabPanel>
                    </Card>
                    <DeleteDialog open={deletePopup} handleClose={() => setDeletePopup(false)} message={deleteDetails} />
                </PropertyContext.Provider>
            </AuthorizedLayout>
        
        </>
    )
}



export default Main;