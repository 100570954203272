import React, { useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";

const ColumnChart = () => {
  const chartSetting = {
    width: 500,
    height: 300,
  };
  const dataset = [
    {
      london: 59,
      paris: 40,
      month: "Jan",
    },
    {
      london: 50,
      paris: 70,
      month: "Feb",
    },
    {
      london: 50,
      paris: 70,
      month: "Mar",
    },
    {
      london: 50,
      paris: 70,
      month: "Apr",
    },
    {
      london: 50,
      paris: 70,
      month: "May",
    },
    {
      london: 50,
      paris: 70,
      month: "Jun",
    },
    {
      london: 50,
      paris: 70,
      month: "Jul",
    },
    {
      london: 50,
      paris: 70,
      month: "Aug",
    },
    {
      london: 50,
      paris: 70,
      month: "Sep",
    },
    {
      london: 50,
      paris: 70,
      month: "Oct",
    },
    {
      london: 50,
      paris: 70,
      month: "Nov",
    },
    {
      london: 50,
      paris: 70,
      month: "Dec",
    },
  ];
  const valueFormatter = (value: number | null) => `${value}`;
  return (
    <>
      <Card className="h-100 custom-card dashboard-card">
        <Typography variant="body1">Property Revenue Overview</Typography>
        <Typography variant="subtitle1">Overview of last 12 months</Typography>
        <BarChart
          dataset={dataset}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "month",
              colorMap: {
                type: "ordinal",
                colors: ["#FFA95D", "#FF7D73"],
              },
            },
          ]}
          series={[
            { dataKey: "london", label: "London", valueFormatter },
            { dataKey: "paris", label: "Paris", valueFormatter },
          ]}
          legend={{ hidden: true }}
          {...chartSetting}
        />
      </Card>
    </>
  );
};

export default ColumnChart;
