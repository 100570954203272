import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Profile from "../../Assets/images/profile.png";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  GetApiMethod,
  PostApiMethod,
  PutApiMethod,
} from "../../Utils/ApiService";
import { isNotEmpty } from "../../Utils/HelperService";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import { useLocation, useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import Upload from "../../Components/Upload/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import IconEdit from "../../Assets/images/icon_edit.svg";
import { deepOrange, deepPurple } from "@mui/material/colors";
import SubHeading1 from "../property/components/subHeading1";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";

const ITEM_HEIGHT = 48;
const ShowTenant = (props: any) => {
  // const [property, setProperty] = useState("")
  let navigate = useNavigate();
  const getInitialState = () => ({
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: "",
    parentId: "",
    email: "",
    job: "",
    age: "",
    familyMember: "",
  });
  const [state, setState] = useState(getInitialState);
  const [loading, setLoading] = useState(false);
  const [addTenant, setShowTenant] = useState(false);
  const [tenantList, setTenantList] = useState(props.tenantList);
  const [tenantCount, setTenantCount] = useState(0);
  const [label, setLabel] = useState("Add");
  const [btnLabel, setBtnLabel] = useState("Add");
  const [upData, setUpData] = useState<any>([]);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    setState(props.tenantdata);
  }, [props]);
  const handleTenantEdit = (e: any) => {
    props.pageStatus("editTenant");
    let tmpdata = {
      page: e,
      data: props.tenantdata,
    };
    props.pageData(tmpdata);
  };
  console.log(state)
  return (
    <>
      <Box
        className="my-30 d-flex justify-content-between"
        sx={{ margin: "30px 0" }}
      >
        <Box className="card-title">
          <Typography variant="h5">Tenant Information</Typography>
        </Box>
        <ButtonPrimary
          text="Edit Info"
          className=""
          onClick={() => {
            handleTenantEdit("Add");
          }}
          imgSrc={IconEdit}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box className="d-flex justify-content-between"></Box>
        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item lg={12} md={4} sm={12} xs={12}>
            {state && isNotEmpty(state.firstName) ? (
              // <Avatar name={state && (isNotEmpty(state.firstName)?state.firstName : "")} maxInitials={1}/>
              <Avatar sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }}>
                {state &&
                  (isNotEmpty(state.firstName)
                    ? state.firstName
                        .split(" ")
                        .map((name) => name[0])
                        .join("")
                        .toUpperCase()
                    : "")}
              </Avatar>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Box className="table-striped table-striped-header">
          <table>
            <tr>
              <th>Name</th>
              <td>
                {state &&
                  (isNotEmpty(state.firstName) ? state.firstName : "") +
                    " " +
                    (isNotEmpty(state.lastName) ? state.lastName : "")}
              </td>
            </tr>
            <tr>
              <th>Contact Number</th>
              <td>
                {" "}
                {state && isNotEmpty(state.phoneNumber)
                  ? state.phoneNumber
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td> {state && isNotEmpty(state.email) ? state.email : ""}</td>
            </tr>
            <tr>
              <th>Age</th>
              <td> {state && isNotEmpty(state.age) ? state.age : ""}</td>
            </tr>
            <tr>
              <th>Family Members</th>
              <td>
                {" "}
                {state && isNotEmpty(state.familyMember)
                  ? state.familyMember
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Job</th>
              <td> {state && isNotEmpty(state.job) ? state.job : ""}</td>
            </tr>
          </table>
        </Box>

        <Button
          className="btn-white btn-back"
          onClick={() => {
            props.pageStatus("listTenant");
          }}
        >
          <ChevronLeftIcon className="fs-20" />
          Back
        </Button>
      </Box>
    </>
  );
};

export default ShowTenant;
