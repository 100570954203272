import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Autocomplete, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { showToaster } from '../../Store/toastSlice';
import { showLoader } from '../../Store/loaderSlice';
import { PostApiMethod } from '../../Utils/ApiService';
import { useDispatch } from 'react-redux';

const AssignVendors = (props: any) => {
    // const [property, setProperty] = useState("")
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

    const getInitialState = () => ({ id: "", type: "", description: "", attachement: "", status: true, userId: 1, property_id: "", unit_id: "", vendor_id: {} })
    const [state, setState] = useState<any>(getInitialState)
    const [loading, setLoading] = useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [vendorsList, setVendorsList] = useState([]);
    const [vendorsCount, setVendorsCount] = useState([]);
    const [vendorAssignData, setVendorAssignData] = useState<any>({vendor_id:{}});
    const [vendorAssignErrors, setVendorAssignErrors] = useState(false);
    const [vendorAssignErrorMessage, setVendorAssignErrorMessage] = useState("");
    useEffect(() => {
        fetchVendors();
    }, [props.popup])
    useEffect(() => {
        setOpen1(props.popup)
        // setVendorAssignData(props.vendorAssignData)
    }, [props.popup])
    const fetchVendors = async () => {
        setLoading(true)
        let formdata = {
            role: 'PMH',
            // page: page,
            // limit: limit,
            // searchString: search
        }
        let response = await PostApiMethod('user/getUser', formdata)
        if (response["data"]["error"] === false) {
            setVendorsList(response.data.results.data)
            setVendorsCount(response.data.results.totalCount)
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleVendorChange = (e: any, data: any) => {
        setVendorAssignErrors(false)
        setVendorAssignData({ ...vendorAssignData, [e]: data })
    }

    const handleVendorSubmit = async (e: any) => {
        setLoading(true)
        // vendorAssignErrors
        if (vendorAssignData) {
            console.log(vendorAssignData,!vendorAssignData.vendor_id.id,"vendorAssignData");
            if (!props.vendorAssigndata.id) {
                setVendorAssignErrors(true)
                setVendorAssignErrorMessage("Helpdesk Id Missing")
                return;
            }
            if (!vendorAssignData.vendor_id.id) {
                setVendorAssignErrors(true)
                setVendorAssignErrorMessage("Kindly Select Vendor")
                return;
            }
        }
        dispatch(showLoader({ showLoader: true }));
        let formdata = {
            help_desk_id:props.vendorAssigndata.id,
            vendor_id: vendorAssignData.vendor_id.id.toString(),
            message: vendorAssignData.message ? vendorAssignData.message.toString() : "",
            priority: props.vendorAssigndata.priority,
        }
        let response: any;
        try {
            response = await PostApiMethod('property/help-desk/assign-help-desk', formdata)
            if (response && response["data"]["error"] === false) {
                dispatch(
                    showToaster({
                        dialogBgColor: "bg-success",
                        dialogMsg: response["data"]["message"],
                        showDialog: true,
                        timer: "3000",
                    })
                );
                setVendorAssignData({vendor_id:{}});
                dispatch(showLoader({ showLoader: false }));
                setLoading(false)
                setOpen1(false);
                props.submit();

            }
            else {
                dispatch(
                    showToaster({
                        dialogBgColor: "bg-danger",
                        dialogMsg: response["data"]["message"],
                        showDialog: true,
                        timer: "5000",
                    })
                );
                dispatch(showLoader({ showLoader: false }));
            }
        } catch (err: any) {
            console.log(err);
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    dialogMsg: err,
                    showDialog: true,
                    timer: "5000",
                })
            );
            dispatch(showLoader({ showLoader: false }));
        }
    }
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Paper>
                    <Grid container spacing={2} sx={{ m: 1 }}>
                        <Dialog
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="xs"
                            fullWidth={true}
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Assign Vendor"}
                            </DialogTitle>
                            <DialogContent>

                                <Typography className='poppins-family'>Vendors List</Typography>
                                
                                <Autocomplete
                                    id="size-small-standard"
                                    size="small"
                                    options={vendorsList && vendorsList}
                                    getOptionLabel={(option: any) => option.firstName.toString()}
                                    onChange={(event: any, newValue: any) => { newValue !== null ? handleVendorChange("vendor_id", newValue) : handleVendorChange("vendor_id", {}) }}
                                    inputValue={vendorAssignData && Object.keys(vendorAssignData["vendor_id"]).length ? vendorAssignData["vendor_id"].firstName : ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            // label="Property"
                                            placeholder="Choose"
                                        />
                                    )}
                                />
                                <Grid item lg={12} sx={{ mt: 3 }}>
                                    <Typography className='poppins-family'>Message</Typography>
                                    <TextField type="text" variant='standard' placeholder='Type here...' className='form-control' name="message" onChange={(e) => { handleVendorChange(e.target.name, e.target.value) }} value={state["message"]} />
                                </Grid>
                                {vendorAssignErrors ? <Typography className='poppins-family text-danger' sx={{ mt: 3 }}>{vendorAssignErrorMessage}</Typography> : ""}
                            </DialogContent>
                            <DialogActions>
                                <Button className="btn-white" type="button" onClick={() => { setVendorAssignData(""); setOpen1(false); setVendorAssignErrors(false); props.popupClose(false) }} >Cancel</Button>
                                <Button className='btn-danger ms-2' type="button" onClick={handleVendorSubmit} disabled={vendorAssignErrors}>Assign</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Paper>
            </Box>
        </>
    )
}

export default AssignVendors