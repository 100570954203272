import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginSchema = yup.object().shape({
  username: yup
    .string()
    .email("Entered valid email")
    .required("Please entered email"),
  password: yup.string().required("Please entered password"),
});

export const maintainerSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enteref valid email")
    .required("Please entered email"),
  firstName: yup.string().required("Please entered first name"),
  lastName: yup.string().required("Please entered last name"),
  phoneNumber: yup
    .string()
    .required("Please entered phone number")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10)
    .max(10),
});

export const tenantSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enteref valid email")
    .required("Please entered email"),
  firstName: yup.string().required("Please entered first name"),
  lastName: yup.string().required("Please entered last name"),
  phoneNumber: yup
    .string()
    .required("Please entered phone number")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10)
    .max(10),
  job: yup.string().required("Please entered Job"),
  age: yup.string().required("Please entered Age"),
  familyMembers: yup.string().required("Please entered Family Members"),
});
export const homeDetailsSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Please entered email"),
  firstName: yup.string().required("Please entered first name"),
  lastName: yup.string().required("Please entered last name"),
  phoneNumber: yup
    .string()
    .required("Please entered phone number")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10)
    .max(10),
});
export const helpDeskSchema = yup.object().shape({
  property_id: yup.string().required("Please select Property"),
  unit_id: yup.string().required("Please select Unit"),
  type_id: yup.string().required("Please select Type"),
  status: yup.string().required("Please select Status"),
  priority: yup.string().required("Please select Priority"),
  description: yup.string().required("Please entered Description"),
});

export const addTenantSchema = yup.object().shape({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10)
    .max(10),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Please entered email"),
  job: yup.string().required("Job is required"),
  age: yup.string().required("Age is required"),
  familyMember: yup.string().required("Family member is required"),
});

export const addTenantPropertySchema = yup.object().shape({
  leaseStartDate: yup
    .date()
    .required("Lease start date is required")
    .nullable(),
  leaseEndDate: yup.date().required("Lease end date is required").nullable(),
  leaseReminderDays: yup.string().required("Lease reminder days is required"),
  propertyId: yup.object().required("Property id is required"),
  unitId: yup.object().required("Unit id is required"),
  general_rent: yup.string().required("General rent is required"),
  security_deposit: yup.string().required("Security deposit is required"),
  late_fee: yup.string().required("Late fee is required"),
  incident_receipt: yup.string().required("Incident receipt is required"),
});

export const editTicketSchema = yup.object().shape({
  propertyId: yup.string().required("Property is required").nullable(),
  unitId: yup.string().required("Unit is required").nullable(),
  typeId: yup.string().required("Type is required"),
  status: yup.string().required("Status is required"),
  priority: yup.string().required("Priority is required"),
  description: yup.string().required("Description is required"),
  // security_deposit: yup.string().required("Security deposit is required"),
  // late_fee: yup.string().required("Late fee is required"),
  // incident_receipt: yup.string().required("Incident receipt is required"),
});

export const documentValidation = yup.object().shape({
  pdfFile: yup
    .mixed()
    .test(
      "fileType",
      "Invalid file format. Please upload a PDF file.",
      (value: any) => value && value.type === "application/pdf"
    )
    .test(
      "fileSize",
      "File size too large. Max size is 5MB.",
      (value: any) => value && value.size <= 5 * 1024 * 1024
    )
    .required("PDF file is required"),
});

export const HelpDeskTypeAddSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  image: yup.mixed().required("Image is required"),
});

export const InvoiceTypeAddSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  tax: yup.mixed().required("Tax is required"),
});

export const InvoiceAddSchema = yup.object().shape({
  propertyId: yup.string().required("Property is required"),
  propertyUnitId: yup.string().required("Unit  is required"),
  month: yup.string().required("Month is required"),
  dueDate: yup.string().required("Due date is required"),
  tenantId: yup.string().required("Tenant is required"),
  data: yup.array().of(
    yup.object().shape({
      invoice_type_id: yup.string().required("Invoice type is required"),
      //   tax_type: yup.string().required('Tax type is required'),
      amount: yup.string().required("Amount is required"),
      description: yup.string().required("Description is required"),
    })
  ),
});

export const RecurringInvoiceAddSchema = yup.object().shape({
  propertyId: yup.string().required("Property is required"),
  recurringType: yup.string().required("Recurring type is required"),
  propertyUnitId: yup.string().required("Unit  is required"),
  tenantId: yup.string().required("Tenant is required"),
  dueDateAfter: yup.string().required("Due date is required"),
  recurring_cycle_day: yup.string().when("recurringType", {
    is: "Custom",
    then: (schema) => schema.required("Cycle day is required"),
  }),
  data: yup.array().of(
    yup.object().shape({
      invoice_type_id: yup.string().required("Invoice type is required"),
      amount: yup.string().required("Amount is required"),
      description: yup.string().required("Description is required"),
    })
  ),
});

export const InvoiceReminder = yup.object().shape({
  property_id: yup.string().when("property_all", {
    is: false,
    then: (schema) => schema.required("Property is required"),
  }),
  unit_id: yup.string().when("unit_all", {
    is: false,
    then: (schema) => schema.required("Unit is required"),
  }),
  title:  yup.string().required("Title is required"),
  details: yup.string().required("Description is required"),
});


export const UnitAddAndEditSchema = yup.object().shape({
  property_type: yup.string().required("Property type is required"),
  property_name: yup.string().required("Property name is required"),
  description: yup.string().required("Description is required"),
  // lease_amount:  yup.string().when("property_type", {
  //   is: "lease",
  //   then: (schema) => schema.required("Lease amount is required"),
  // }),
  // lease_end_date:  yup.string().when("property_type", {
  //   is: "lease",
  //   then: (schema) => schema.required("Lease End date is required"),
  // }),
  // lease_start_date:  yup.string().when("property_type", {
  //   is: "lease",
  //   then: (schema) => schema.required("Lease start date is required"),
  // }),
  units: yup.array().of(
    yup.object().shape({
      unit_name: yup.string().required("Unit name is required"),
      bedroom: yup.string().required("Bedroom is required"),
      bath: yup.string().required("Bathroom is required"),
      kitchen: yup.string().required("Kitchen is required"),
      square_feet: yup.string().required("Square Feet is required"),
      amenities: yup.string().required("Amenities is required"),
      condition: yup.string().required("Condition is required"),
      parking: yup.string().required("Parking is required"),
      description: yup.string().required("Description is required"),
      image: yup
      .mixed()
      .optional()
      .test('fileType', 'Only PNG and JPEG files are allowed', (value:any) => {
        if (!value) return true; // Allow empty value
        return (
          value && ['image/png', 'image/jpeg'].includes(value.type)
        );
      }),
    })
  ),
});

export const RentAddAndEditSchema = yup.object().shape({
  rents: yup.array().of(
    yup.object().shape({
      general_rent: yup.string().required("General rent is required"),
      security_deposit: yup.string().required("Security deposit is required"),
      late_fee: yup.string().required("Late is required"),
      incident_receipt: yup.string().required("Incident receipt is required"),

     })
  )

});

export const AddExpenseSchema = yup.object().shape({
      name: yup.string().required("Name is required"),
      propertyId: yup.string().required("Please select Property"),
      unitId: yup.string().required("Please select Unit"),
      expenseTypeId: yup.string().required("Please select Expense Type"),
      description: yup.string().required("Description is required"),
      responsibility: yup.string().required("Please select a responsibility type"),
      amount: yup.string().required("Amount is required"),
      tagId: yup.string().required("Please select a tag"),
    });
export const UpdateExpenseSchema = yup.object().shape({
      name: yup.string().required("Name is required"),
      property_id: yup.string().required("Please select Property"),
      unit_id: yup.string().required("Please select Unit"),
      expense_type_id: yup.string().required("Please select Expense Type"),
      description: yup.string().required("Description is required"),
      responsibility: yup.string().required("Please select a responsibility type"),
      amount: yup.string().required("Amount is required"),
      tag_id: yup.string().required("Please select a tag"),
    });

export const AddNoticeBoardSchema = yup.object().shape({
      title: yup.string().required("Title is required"),
      details: yup.string().required("Detail is required"),
      property_id: yup.string().required("Property Name is required"),
      unit_id: yup.string().required("unit Name is required")
    });

    export const AddMaintenanceSchema = yup.object().shape({
      type: yup.string().required("Type is required"),
      job_type_id: yup.string().required("Please select Job type"),
      area_type_id: yup.string().required("Please select Area type"),
      issue: yup.string().required("Please select issue"),
      timings: yup.string().required("Timings is required"),
      status: yup.string().required("Please select a status"),
      priority: yup.string().required("Priority is required"),
      no_of_people: yup.string().required("No of people is required"),
      assign_to: yup.string().required("Assign to is required"),
      date: yup.string().when('type', {
        is: (val: string) => val === 'Corrective',
        then: schema => schema.required("Date is required"),
        otherwise: schema => schema.notRequired()
      }),
      start_date: yup.string().when('type', {
        is: (val: string) => val === 'Preventive',
        then: schema => schema.required("Start Date is required"),
        otherwise: schema => schema.notRequired()
      }),
      maintenance_remainder: yup.string().when('type', {
        is: (val: string) => val === 'Preventive',
        then: schema => schema.required("Maintenance Remainder is required"),
        otherwise: schema => schema.notRequired()
      }),
      recurring_type: yup.string().when('type', {
        is: (val: string) => val === 'Preventive',
        then: schema => schema.required("Recurring Type is required"),
        otherwise: schema => schema.notRequired()
      }),
    });