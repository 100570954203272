import React, { memo, useContext } from 'react';
import { Typography, Box, Grid, MenuItem, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { bookingSchema } from './AmenitiesSchema';
import Select from '@mui/material/Select';
import { AmenitiesContext } from './Amenities';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AddBooking = () => {
    const amenities = useContext(AmenitiesContext);
    return (
        <>
            <Box className="mt-4">
                <Typography className='fw-bold fs-3'>Add New Booking</Typography>
            </Box>
            <Box>
                <Formik
                    initialValues={{
                        property_id:'',
                        unit_id:'',
                        name:'',
                        amenities:'',
                        date:'',
                        timings:'',
                        no_of_people:''
                    }}
                    validationSchema={bookingSchema}
                    onSubmit={(values) => {
                        alert(JSON.stringify(values))
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} className="mt-3"> 
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Property</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="property_id" variant='standard'value={values.property_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {amenities.propertyList.length !== 0 && amenities.propertyList.map((val,key) => (
                                            <MenuItem value={val.id}>{val.property_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.property_id && errors.property_id && (<div className='text-danger'>{errors.property_id}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Unit Name</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="unit_id" variant='standard'value={values.unit_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {amenities.unitList.length !== 0 && amenities.unitList.map((val,key) => (
                                            <MenuItem value={val.id}>{val.unit_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.unit_id && errors.unit_id && (<div className='text-danger'>{errors.unit_id}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">First Name</Typography>
                                    <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="name"
                                        onChange={handleChange}
                                    />
                                    {touched.name && errors.name && (<div className='text-danger'>{errors.name}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Amenities</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="amenities" variant='standard' value={values.amenities} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {amenities.amenityList.length !== 0 && amenities.amenityList.map((val,key) => (
                                            <MenuItem value={val.id}>{val.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.amenities && errors.amenities && (<div className='text-danger'>{errors.amenities}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Date</Typography>
                                    <TextField
                                        type="date"
                                        className='w-100'
                                        variant="standard"
                                        value={values.data}
                                        name="date"
                                        onChange={handleChange}
                                    />
                                    {touched.date && errors.date && (<div className='text-danger'>{errors.date}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Timings</Typography>
                                    <TextField
                                        type="time"
                                        className='w-100'
                                        variant="standard"
                                        value={values.time}
                                        name="time"
                                        onChange={handleChange}
                                    />
                                    {touched.time && errors.time && (<div className='text-danger'>{errors.time}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">No. of People</Typography>
                                    <TextField
                                        type="text"
                                        className='w-100'
                                        variant="standard"
                                        value={values.no_of_people}
                                        name="no_of_people"
                                        placeholder="Type here"
                                        onChange={handleChange}
                                    />
                                    {touched.no_of_people && errors.no_of_people && (<div className='text-danger'>{errors.no_of_people}</div>)}
                                </Grid>
                            </Grid>
                            <Box className="mt-5">
                                <button type="button" className='btn btn-white' onClick={amenities.onBack}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                                <button type="submit" className='btn btn-danger ms-2'>Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    )
}

export default memo(AddBooking);