import React, { useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AntTabs, AntTab } from "../../Components/Tab/AntTab";
import Grid from "@mui/material/Grid";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import AddIcon from "../../Assets/images/add_plus_icon.svg"
import FormControl from "@mui/material/FormControl";
import AddNewExpense from "./AddNewExpense";
import AllExpense from "./AllExpense";
import ShowExpense from "./ShowExpense"
import EditExpense from "./EditExpense";

interface ExpenseOptions {
  listExpense: boolean;
  addExpense: boolean;
  addRequest: boolean;
  showExpense: boolean;
  assignVendors: boolean;
  editExpense: boolean;
  [key: string]: boolean; // Index signature
}


const Expenses = () => {
  const [tabValue, setTabValue] = useState(0);
  const [addNew, setAddNew] = useState({
    listExpense: true,
    addExpense: false,
    addRequest: false,
    showExpense: false,
    assignVendors: false,
    editExpense: false,
  });
  const [expenseData, setExpenseData] = useState("");
  const [listExpense, setListExpense] = useState(true);
  const [search, setSearch] = useState("");


  const handlePageDataChange = (data: any) => {
    setExpenseData(data.data);
  };

  const handleChange = (e: any, val: any) => {
    console.log("val", val);
    setTabValue(val);
  };

  const pageStatus = (data: any) => {
    let tmpData: ExpenseOptions = { ...addNew };
    for (let prop in tmpData) {
      if (prop !== data) {
        tmpData[prop] = false;
      } else {
        tmpData[prop] = true;
      }
    }
    setAddNew(tmpData);
  };

  const handleSearchChange = (e: any) => {
    setSearch(e);
  };
  return (
      <>
      <AuthorizedLayout>
        <Card
          variant="outlined"
          sx={{ borderRadius: "8px" }}
          className="border-0 default-page-height"
        >
          <Box className="d-flex justify-content-between align-items-center">
            <Box className="d-flex align-items-center">
             
              <Typography className="psemiboldfont page-header-text">
                Expense
              </Typography>
              <AntTabs value={tabValue} onChange={handleChange}>
                <AntTab
                  className="tab-menu"
                  label={
                    (addNew.addExpense && !expenseData
                      ? "Add"
                      : addNew.addExpense && expenseData
                      ? "Edit"
                      : addNew.showExpense
                      ? "Show"
                      : "All") + " Expense"
                  }
                />
              </AntTabs>
            </Box>
            {addNew["listExpense"] && (
              <ButtonPrimary
                text="Add New Expense"
                onClick={() => pageStatus("addExpense")}
                imgSrc={AddIcon}
              />
            )}
          </Box>
          {!Object.values(addNew).includes(true) && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {listExpense ? (
                  <Grid item lg={2} md={6} xs={12} sm={12}>
                    <FormControl variant="standard" className="w-100">
                      <TextField
                        type="text"
                        variant="standard"
                        placeholder="Search here..."
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          handleSearchChange(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Box>
          )}
          <Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box>
                {addNew["listExpense"] ? (
                  <AllExpense
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                  />
                ) : addNew["addExpense"] ? (
                  <AddNewExpense
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                    expenseData={expenseData}
                    tenantsearch={search}
                  />
                ) : addNew["showExpense"] ? (
                  <ShowExpense
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                    expenseData={expenseData}
                  />
                ) : (
                  addNew["editExpense"] && (
                    <EditExpense
                      pageStatus={pageStatus}
                      pageData={(e: any) => {
                        handlePageDataChange(e);
                      }}
                      expenseData={expenseData}
                    />
                  )
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
           </CustomTabPanel>
          </Box>
        </Card>
      </AuthorizedLayout>
    </>
  );
};

export default Expenses;
