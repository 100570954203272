import React from "react";
import styles from "./subHeading1.module.css";
import arrow from "../../../Assets/images/arrow-right.svg";
import { Box, fontFamily } from "@mui/system";
import { Typography } from "antd";

export default function SubHeading1(props: any) {
  const leftTextContent = [
    "Property Information",
    "Location",
    "Unit",
    "Rent & Charges",
    // "Images",
  ];

  return (
    <>
      <Box className={styles.box}>
        <Box className={styles.right}>
          <h1 className={styles.heading}>{props.headingProp}</h1>
          <Typography className={styles.subheading}>
            {props.subheadingProp}
          </Typography>
        </Box>
        <Box className={`${styles.left} breadcrumb`}>
          {leftTextContent.map((val) => (
            <Box
              key={val}
              className={styles.leftContent}
              style={{
                color: val === props.subheadingProp ? "#FFA95D" : "#808080",
              }}
            >
              <p className={styles.leftText}>{val}</p>
              <img
                src={arrow}
                alt="arrow"
                className={styles.arrow}
                width="18px"
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
