import React from "react";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
// const baseUrl = "https://assetmanagementapi.firebridge.co.za/";

export const GetApiMethod = (
  routeName: string,
  headers: { [index: string]: { [index: string]: string } } = { headers: {} }
) => {
  return axios.get(`${baseUrl}/${routeName}`, headers);
};

export const PostApiMethod = (
  routeName: string,
  body: object,

  headers: { [index: string]: { [index: string]: string } } = { headers: {} }
) => {
  return axios.post(`${baseUrl}/${routeName}`, body, headers);
};

export const PutApiMethod = (routeName: string, body: object,   headers: { [index: string]: { [index: string]: string } } = { headers: {} }
  ) => {
  return axios.put(`${baseUrl}/${routeName}`, body, headers);
};

export const DeleteApiMethod = (routeName: string) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/${routeName}`);
};
