import { Typography } from "@mui/material";

interface ErrorTextFieldProps {
    error: any;
    touched: boolean;
}

export const ErrorTextField = (props: ErrorTextFieldProps) => {
    const { error, touched } = props

    return (
        <>
            {error && touched && (
                <Typography className="text-danger">
                    {error}
                </Typography>
            )}
        </>
    )
}

