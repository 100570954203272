import React, { useCallback, useEffect, useState } from "react";
import CustomTextfield from "../../components/textfield1";
import styles from "./propertyInformation.module.css";
import RadioButton1 from "../../components/radioButton";
import { FormButton1 } from "../../components/formButton";
import BasicDatePicker from "../../components/datePicker";
import { Box } from "@mui/system";
import { Typography, Grid, TextField } from "@mui/material";
import { Formik, FieldArray, ErrorMessage } from "formik";
import cancelIcon from "../../../../Assets/images/icons/multiply.png";
import { UnitAddAndEditSchema } from "../../../../Components/Validation";
import AddIcon from "@mui/icons-material/Add";
import { ErrorTextField } from "../../../../Components/Error/ErrorTextField";
import { useAppDispatch } from "../../../../Store/hooks";
import { showLoader } from "../../../../Store/loaderSlice";
import { PostApiMethod, PutApiMethod } from "../../../../Utils/ApiService";
import { showToaster } from "../../../../Store/toastSlice";
import { errorHandling } from "../../../../Utils/HelperService";
import ImageSelectField from "../../components/imageField";

interface UnitScreenInterface {
  backBtn: (val: any) => void;
  submitBtn: (currentComponent: any, unitId: any) => void;
  CreatedPropertyIdFromApi: any;
  CreatedUserIdFromApi: any;
  CreatedNo_of_unitsFromApi: any;
  propertyDetails: any;
  setUnitDetails: (val:any) => void;
}

const UnitScreen = (props: UnitScreenInterface) => {
  const [initialValue, setInitialValue] = useState({
    property_type: "own",
    property_name: "",
    number_of_unit: "",
    description: "",
    lease_amount: "",
    lease_start_date: "",
    lease_end_date: "",
    units: [
      {
        unit_name: "",
        bedroom: "",
        bath: "",
        kitchen: "",
        square_feet: "",
        amenities: "",
        condition: "",
        parking: "",
        description: "",
        image: "",
        user_id: "",
        property_id: "",
        general_rent: "",
        security_deposit: "",
        late_fee: "",
        incident_receipt: "",
      },
    ],
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInitialValue((prevValue) => ({
      ...prevValue,
      property_type: props.propertyDetails.property_type,
      property_name: props.propertyDetails.property_name,
      number_of_unit: props.propertyDetails.number_of_unit,
      description: props.propertyDetails.description,
      lease_amount: props.propertyDetails.lease_amount,
      lease_start_date: props.propertyDetails.lease_start_date,
      lease_end_date: props.propertyDetails.lease_end_date,
      units: Array.from({ length: props.CreatedNo_of_unitsFromApi }, () => ({
        unit_name: "",
        bedroom: "",
        bath: "",
        kitchen: "",
        square_feet: "",
        amenities: "",
        condition: "",
        parking: "",
        description: "",
        user_id: props.CreatedUserIdFromApi,
        property_id: props.CreatedPropertyIdFromApi,
        image: "",
        general_rent: "",
        security_deposit: "",
        late_fee: "",
        incident_receipt: "",
      })),
    }));
  }, [props.CreatedNo_of_unitsFromApi]);

  const propertyUpdate = useCallback(
    (values: any) => {
      dispatch(showLoader({ showLoader: true }));
      values.number_of_unit = values.units.length;
      PutApiMethod(
        "property/properties/" + props.CreatedPropertyIdFromApi,
        values
      )
        .then((res) => {
          unitAdd(values);
        })
        .catch((e) => {
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    },
    [props.CreatedPropertyIdFromApi]
  );

  const unitAdd = useCallback(async (values: any) => {
    const formData = new FormData();
    values.units.forEach((obj: any, index: number) => {
      Object.entries(obj).forEach(([key, value]) => {
        formData.append(`data[${index}][${key}]`, value as string | Blob);
      });
    });
    PostApiMethod("property/unit/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        dispatch(showLoader({ showLoader: false }));
        const UnitDetails = res["data"]["results"]["data"];
          dispatch(
            showToaster({
              dialogBgColor: "bg-success",
              dialogMsg: "Unit created success",
              showDialog: true,
              timer: "5000",
            })
          );
          props.submitBtn("Unit", UnitDetails);
          props.setUnitDetails(values);
          dispatch(showLoader({ showLoader: false }));
      })
      .catch((e) => {
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "50000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      });
  }, []);

  return (
    <>
      <div className={styles.topBox}>
        <Typography className="pb-10">Recurring Type</Typography>
        <Formik
          initialValues={initialValue}
          validationSchema={UnitAddAndEditSchema}
          enableReinitialize
          onSubmit={(values) => {
            propertyUpdate(values);
            console.log(values);
          }}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
          }) => {
            console.log(errors)
            return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <Box className={`${styles.radioBox} pb-20`}>
                <RadioButton1
                  name={"property_type"}
                  valueChange={(
                    InputValue: any,
                    inputName: string,
                    currentIndex: number
                  ) => {
                    setFieldValue(inputName, InputValue);
                  }}
                  label={"Own Property"}
                  value={"own"}
                  isChecked={values.property_type.toLowerCase() === "own" ? true : false}
                  indexOfTextField={0}
                />
                <RadioButton1
                  name={"property_type"}
                  valueChange={(
                    InputValue: any,
                    inputName: string,
                    currentIndex: number
                  ) => {
                    setFieldValue(inputName, InputValue);
                  }}
                  label={"Lease Property"}
                  value={"lease"}
                  isChecked={values.property_type.toLowerCase() === "lease" ? true : false}
                  indexOfTextField={0}
                />
              </Box>
              <div className={styles.inputTotalBox}>
                <div>
                  <CustomTextfield
                    label="Property Name"
                    name="property_name"
                    type="text"
                    valueChange={(
                      InputValue: any,
                      inputName: string,
                      currentIndex: number
                    ) => {
                      setFieldValue(inputName, InputValue);
                    }}
                    value={values.property_name}
                  />
                  <ErrorTextField
                    error={errors.property_name}
                    touched={touched.property_name!}
                  />
                </div>
                {values.property_type.toLowerCase() === "lease" ? (
                  <>
                    <div>
                      <CustomTextfield
                        label="Lease Amount"
                        name="lease_amount"
                        type="number"
                        valueChange={(
                          InputValue: any,
                          inputName: string,
                          currentIndex: number
                        ) => {
                          setFieldValue(inputName, InputValue);
                        }}
                        value={values.lease_amount}
                      />
                      <ErrorTextField
                        error={errors.lease_amount}
                        touched={touched.lease_amount!}
                      />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <BasicDatePicker
                        label={"Lease Start Date"}
                        name={"lease_start_date"}
                        val={values.lease_start_date}
                        valueChange={(
                          InputValue: any,
                          inputName: string,
                          currentIndex: number
                        ) => {
                          setFieldValue(inputName, InputValue);
                        }}
                      />
                      <ErrorTextField
                        error={errors.lease_start_date}
                        touched={touched.lease_start_date!}
                      />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <BasicDatePicker
                        label={"Lease End Date"}
                        name={"lease_end_date"}
                        val={values.lease_end_date}
                        valueChange={(
                          InputValue: any,
                          inputName: string,
                          currentIndex: number
                        ) => {
                          setFieldValue(inputName, InputValue);
                        }}
                      />
                      <ErrorTextField
                        error={errors.lease_end_date}
                        touched={touched.lease_end_date!}
                      />
                    </div>
                  </>
                ) : null}
                <div>
                  <CustomTextfield
                    label="Description"
                    name="description"
                    type="text"
                    valueChange={(
                      InputValue: any,
                      inputName: string,
                      currentIndex: number
                    ) => {
                      setFieldValue(inputName, InputValue);
                    }}
                    value={values.description}
                  />
                  <ErrorTextField
                    error={errors.description}
                    touched={touched.description!}
                  />
                </div>
              </div>
              <FieldArray name="units">
                {({ push, remove }) => (
                  <div>
                    <div>
                      <Box className="mt-4">
                        <Box className="d-flex justify-content-between mb-2">
                          <Typography className="poppins-family">
                            Unit List
                          </Typography>
                          <div
                            onClick={() => {
                              push({
                                unit_name: "",
                                bedroom: "",
                                bath: "",
                                kitchen: "",
                                square_feet: "",
                                amenities: "",
                                condition: "",
                                parking: "",
                                description: "",
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Typography className="poppins-family text-primary">
                              <AddIcon />
                              Add Unit
                            </Typography>
                          </div>
                        </Box>
                        {values.units.map((item, index) => {
                          return (
                            <div>
                              {index !== 0 && (
                                <Box className="d-flex justify-content-between mt-5">
                                  <Typography className="poppins-family">
                                    Unit {index + 1}
                                  </Typography>
                                  <div
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      src={cancelIcon}
                                      alt="FB"
                                      width="25"
                                      height="25"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {}}
                                    />
                                  </div>
                                </Box>
                              )}
                              <Grid
                                container
                                spacing={3}
                                className="mt-1"
                                key={index}
                              >
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Unit Name
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    name={`units.${index}.unit_name`}
                                    value={values.units[index].unit_name}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].unit_name`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Bedroom
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    type="number"
                                    name={`units.${index}.bedroom`}
                                    value={values.units[index].bedroom}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].bedroom`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Bathroom
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    type="number"
                                    name={`units.${index}.bath`}
                                    value={values.units[index].bath}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].bath`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Kitchen
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    type="number"
                                    name={`units.${index}.kitchen`}
                                    value={values.units[index].kitchen}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].kitchen`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Square Feet
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    type="number"
                                    name={`units.${index}.square_feet`}
                                    value={values.units[index].square_feet}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].square_feet`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Amentities
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    name={`units.${index}.amenities`}
                                    value={values.units[index].amenities}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].amenities`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Condition
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    name={`units.${index}.condition`}
                                    value={values.units[index].condition}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].condition`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Parking
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    type="number"
                                    name={`units.${index}.parking`}
                                    value={values.units[index].parking}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].parking`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography className="poppins-family">
                                    Description
                                  </Typography>
                                  <TextField
                                    className="w-100"
                                    variant="standard"
                                    placeholder="Type here..."
                                    name={`units.${index}.description`}
                                    value={values.units[index].description}
                                    onChange={handleChange}
                                  />

                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].description`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <ImageSelectField
                                    label={"Upload Images"}
                                    name={"image"}
                                    valueChange={(newValue:any,name:string) => {
                                      setFieldValue(
                                        `units.${index}.image`,
                                        newValue[0]
                                      );
                                    }}
                                    currentIndex={index}
                                  />
                                  <ErrorTextField
                                    error={
                                      <ErrorMessage
                                        name={`units[${index}].image`}
                                      />
                                    }
                                    touched={true}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          );
                        })}
                      </Box>
                    </div>
                  </div>
                )}
              </FieldArray>
              <div className={styles.btn}>
                <FormButton1 backBtnClick={props.backBtn} />
              </div>
            </form>
          )}}
        </Formik>
      </div>
    </>
  );
};

export default UnitScreen;
