import React, { useState, useEffect, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableFooter, Typography ,InputAdornment} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DeleteApiMethod,
  PostApiMethod,
  PutApiMethod,
} from "../../Utils/ApiService";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { showToaster } from "../../Store/toastSlice";
import { DisplayErrorMessages, errorHandling } from "../../Utils/HelperService";
import { showLoader } from "../../Store/loaderSlice";
import { useFormik } from "formik";
import { maintainerSchema } from "../../Components/Validation";
import Actions from "../../Components/Actions/Actions";
import Pagination from "../../Components/Pagination/Pagination";
import ColorText from "../../Components/Text/ColorText";
import IconSearch from "../../Assets/images/search.svg"

const AddMaintainers = (props: any) => {
  // const [property, setProperty] = useState("")
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    id: "",
  });
  const [loading, setLoading] = useState(false);
  const { addMaintainers, handleMaintainers } = props;
  const user = useAppSelector((state) => state.auth);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState({ page: 1, limit: 5, count: 0 });

  useEffect(() => {
    getUsers(page.page, page.limit);
  }, [searchValue]);

  const rowsPerPage = useCallback(
    (val: any) => {
      setPage({ ...page, page: val.page, limit: val.limit });
      getUsers(val.page, val.limit);
    },
    [page]
  );

  const getUsers = async (page: any, limit: any) => {
    dispatch(showLoader({ showLoader: true }));
    setLoading(true);
    try {
      let res = await PostApiMethod("user/getUser", {
        role: ["PMH"],
        page: page,
        limit: limit,
        searchString: searchValue,
      });
      if (res["data"]["code"] === 200) {
        setData(res["data"]["results"]["data"]);
        setPage((prevPage) => ({
          ...prevPage,
          count: res["data"]["results"]["totalCount"],
        }));
      }
      dispatch(showLoader({ showLoader: false }));
    } catch (e) {
      const error = DisplayErrorMessages(e);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
    setLoading(false);
  };

  const handleSubmitMaintainers = async (values: any) => {
    dispatch(showLoader({ showLoader: true }));
    try {
      const res = await PostApiMethod("auth/create-user", {
        ...values,
        parentId: user.adminData.id,
        role: "PMH",
      });
      if (res["data"]["code"] === 201) {
        getUsers(page.page, page.limit);
        resetForm();
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: "Supplier created successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        backToTable();
      }
    } catch (error: any) {
      const e = errorHandling(error);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: e,
          showDialog: true,
          timer: "5000",
        })
      );
    }
    dispatch(showLoader({ showLoader: false }));
  };

  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
    useFormik({
      initialValues: state,
      validationSchema: maintainerSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        {
          edit
            ? handleEditMaintainers(values)
            : handleSubmitMaintainers(values);
        }
      },
    });

  const handleEdit = (val: any) => {
    setState(val);
    handleMaintainers();
    setEdit(true);
  };

  const handleEditMaintainers = async (values: any) => {
    dispatch(showLoader({ showLoader: true }));
    try {
      let res = await PutApiMethod(`user/${state.id}`, {
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
      });
      if (res["data"]["code"] === 200) {
        getUsers(page.page, page.limit);
        resetForm();
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: "Supplier updated successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        backToTable();
      }
    } catch (error: any) {
      const e = errorHandling(error);

      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: e,
          showDialog: true,
          timer: "5000",
        })
      );
    }
    dispatch(showLoader({ showLoader: false }));
  };

  const handleDelete = async (val: any) => {
    try {
      let res = await DeleteApiMethod(`user/${val.id}`);
      getUsers(page.page, page.limit);
      dispatch(
        showToaster({
          dialogBgColor: "bg-success",
          dialogMsg: "Supplier deleted successfully",
          showDialog: true,
          timer: "5000",
        })
      );
    } catch (error) {
      const e = DisplayErrorMessages(error);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: e,
          showDialog: true,
          timer: "5000",
        })
      );
    }
  };

  const backToTable = () => {
    resetForm();
    handleMaintainers();
    setEdit(false);
    setState({
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      id: "",
    });
  };

  return (
    <>
      {!addMaintainers ? (
        <Box sx={{ mt: 3 }}>
            <Box
            className="w-full max-w-400 my-30"
            sx={{
              width: "400px",
              margin: "30px 0",
            }}
          >
            <Typography variant="body2">Search</Typography>
            <TextField
              variant="standard"
              placeholder="Type here"
              sx={{
                width: "100%",
              }}
              onChange={(e: any) => {
                setSearchValue(e.target.value)
              }}
              className="w-full"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconSearch} width="18px" height="18px" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  {/* <TableCell>Assigned Property</TableCell> */}
                  <TableCell>Email</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  data.map((val: any, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell className="table-text-color">
                          {val.firstName}
                        </TableCell>
                        <TableCell className="table-text-color">
                          {val.email}
                        </TableCell>
                        <TableCell className="table-text-color">
                          {val.phoneNumber}
                        </TableCell>
                        <TableCell className="table-text-color active-text">
                          {val.isActive ? (
                            <ColorText color={"#24BC94"}>Active</ColorText>
                          ) : (
                            <ColorText color={"red"}>Deactive</ColorText>
                          )}
                        </TableCell>

                        <TableCell>
                          <Actions
                            name={"More"}
                            data={[
                              {
                                name: "Edit",
                                onClick: () => handleEdit(val),
                                color: "text-primary",
                              },
                              {
                                name: "Delete",
                                onClick: () => handleDelete(val),
                                color: "text-danger",
                              },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {data.length !== 0 && (
                <TableFooter>
                  <TableRow>
                    <Pagination
                      pagination={page}
                      colSpan={6}
                      rowsPerPageChange={rowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
            {!loading && data.length === 0 && (
              <Box className="d-flex justify-content-center w-100">
                <Typography>No Data</Typography>
              </Box>
            )}
          </TableContainer>
        </Box>
      ) : (
        <>
          <Box
            className="my-30 d-flex justify-content-between"
            sx={{ margin: "30px 0" }}
          >
            <Box className="card-title">
              <Typography variant="h5">
                {edit ? "Update" : "Add New"} Supplier
              </Typography>
              <Typography> Supplier Information</Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography className="poppins-family">First Name</Typography>
                  <TextField
                    type="text"
                    variant="standard"
                    placeholder="Type here..."
                    className="form-control"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                  />
                  {errors.firstName && touched.firstName && (
                    <Typography className="text-danger">
                      {errors.firstName}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography className="poppins-family">Last Name</Typography>
                  <TextField
                    type="text"
                    variant="standard"
                    placeholder="Type here..."
                    className="form-control"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                  />
                  {errors.lastName && touched.lastName && (
                    <Typography className="text-danger">
                      {errors.lastName}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography className="poppins-family">
                    Contact Number
                  </Typography>
                  <TextField
                    type="number"
                    variant="standard"
                    placeholder="Type here..."
                    className="form-control"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={values.phoneNumber}
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <Typography className="text-danger">
                      {errors.phoneNumber}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography className="poppins-family">Email</Typography>
                  <TextField
                    type="email"
                    variant="standard"
                    placeholder="Type here..."
                    className="form-control"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <Typography className="text-danger">
                      {errors.email}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }} className="d-flex">
                <Button className="btn-white" onClick={backToTable}>
                  <ChevronLeftIcon className="fs-20" />
                  Back
                </Button>
                <Button
                  className="btn-danger ms-2"
                  type="submit"
                  disabled={loading}
                >
                  {edit ? "Update Supplier" : "Add Supplier"}
                </Button>
              </Box>
            </form>
          </Box>
        </>
      )}
    </>
  );
};

export default AddMaintainers;
