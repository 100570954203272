import React, { useCallback, useState, createContext, useEffect } from 'react';
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import { Box, Typography } from '@mui/material';
import AmenitiesIcon from "../../Assets/images/amenities.png";
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import Bookings from './Bookings';
import AmenityList from './AmenityList';
import AddIcon from '@mui/icons-material/Add';
import AddBooking from './AddBooking';
import AddAmenities from './AddAmenities';
import { DeleteApiMethod, PostApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";

export const AmenitiesContext = createContext();

const Amenities = () => {
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState({page:1,limit:5,count:0})
  const [alPage, setAlPage] = useState({page:1,limit:5,count:0})
  const [bookingList, setBookingList] = useState([]);
  const [amenityList, setAmenityList] = useState([]);
  const [allAmenityList, setAllAmenityList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [addBooking, setAddBooking] = useState(false);
  const [addAmenities, setAddAmenities] = useState(false);
  const [editAmenities, setEditAmenities] = useState({});

  const dispatch = useAppDispatch();

  const handleChange = useCallback((e,newValue) => {
    setTabValue(newValue)
    setAddBooking(false)
    setAddAmenities(false)
    setEditAmenities({})
  },[tabValue])

  useEffect(() => {
    getPropertiesList();
    getAllAmenitiesList();
    getUnitList();
    getAmenitiesList(alPage)
  },[])

  const getAllAmenitiesList = useCallback(async() => {
    dispatch(showLoader({showLoader:true}))
    try{
      let res = await PostApiMethod("property/properties/list",{});
      if(res["data"]["error"] === false){
        allAmenityList(res["data"]["results"]["data"])
      }
    }catch(error){
      dispatch(showToaster({dialogBgColor: "bg-danger",
        dialogMsg: "Failed to fetch amenities list",
        showDialog: true,
        timer: "3000",}))
    }
  },[])

  const getUnitList = async() => {
    try{
      let res = await PostApiMethod("property/unit/list",{});
      if(res["data"]["error"] === false){
        setUnitList(res["data"]["results"]["data"])
      }
    }catch(error){
      dispatch(showToaster({dialogBgColor: "bg-danger",
        dialogMsg: "Failed to fetch unit list",
        showDialog: true,
        timer: "3000",}))
    }
  }

  const openBooking = useCallback(() => {
    setAddBooking(true)
    setTabValue(0)
  },[addBooking])

  const onBack = useCallback(() => {
    setAddBooking(false)
    setTabValue(0)
  },[addBooking])

  const onAmenitiesBack = useCallback(() => {
    setAddAmenities(false);
    setTabValue(1)
    setEditAmenities({});
  },[addAmenities])

  const addNewAmenities = useCallback(() => {
    setAddAmenities(true)
  },[addAmenities])

  const getPropertiesList = useCallback(async() => {
    dispatch(showLoader({showLoader:true}))
    try{
      let res = await PostApiMethod("property/properties/list",{});
      if(res["data"]["error"] === false){
        setPropertiesList(res["data"]["results"]["data"])
      }
    }catch(error){
      dispatch(showToaster({dialogBgColor: "bg-danger",
        dialogMsg: "Failed to fetch properties list",
        showDialog: true,
        timer: "3000",}))
    }
  },[propertiesList])

  const getAmenitiesList = useCallback(async(page,str) => {
    dispatch(showLoader({showLoader:true}))
    var parameters = page.limit === -1 ? {} : {page:page.page,limit:page.limit};
    try{
      let res = await PostApiMethod("property/amenities/list",{...parameters,searchString:str});
      if(res["data"]["error"] === false){
        setAmenityList(res["data"]["results"]["data"])
        setAlPage((prevPage) => ({...prevPage,count: res["data"]["results"]["totalCount"]}))
        getAllAmenitiesList();
      }
    }catch(error){
      dispatch(showToaster({dialogBgColor: "bg-danger",
        dialogMsg: "Failed to fetch amenities list",
        showDialog: true,
        timer: "3000",}))
    }
    dispatch(showLoader({showLoader:false}))
  },[amenityList])

  const amenitiesEdit = useCallback((val) => {
    setAddAmenities(true)
    setEditAmenities(val)
  },[editAmenities])

  const deleteAmenities = async(id) => {
    dispatch(showLoader({showLoader:true}))
    try{
      let response = await DeleteApiMethod("property/amenities/"+id,{});
      dispatch(showToaster({dialogBgColor: "bg-success",
        dialogMsg: "Amenities has been deleted",
        showDialog: true,
        timer: "3000",}))
        getAmenitiesList(alPage)
        getAllAmenitiesList();
    }catch(error){
      dispatch(showToaster({dialogBgColor: "bg-success",
        dialogMsg: "Failed to delete amenities",
        showDialog: true,
        timer: "3000",}))
    }
    dispatch(showLoader({showLoader:false}))
  }

  const handleNextPage = useCallback((val) => {
      dispatch(showLoader({ showLoader: true }));
      setPage({...page,page:val.page,limit:val.limit})
      getAmenitiesList(val)
  },[page])

  return (
    <>
      <AuthorizedLayout >
        <AmenitiesContext.Provider value={{
          page:page,
          alPage:alPage,
          bookingList:bookingList,
          amenityList:amenityList,
          openBooking:openBooking,
          onBack:onBack,
          propertyList:propertiesList,
          onAmenitiesBack:onAmenitiesBack,
          getAmenitiesList:getAmenitiesList,
          amenitiesEdit:amenitiesEdit,
          editAmenities:editAmenities,
          deleteAmenities:deleteAmenities,
          handleNextPage:handleNextPage,
          unitList:unitList,
          allAmenityList:allAmenityList
        }}>
          <Card elevation={0}>
              <Box className="d-flex align-items-center justify-content-between flex-wrap">
                <Box className="d-flex align-items-center">
                  <img src={AmenitiesIcon} alt="Property" width={20} />
                  <Typography className="fw-bold fs-5 ms-1">Amenities</Typography>
                  <AntTabs value={tabValue} onChange={handleChange}>
                    <AntTab label="Bookings" className="tab-menu"/>
                    <AntTab label="Amenity List" className="tab-menu"/>
                  </AntTabs>
                </Box>
                {!addBooking && tabValue === 0 &&<button className='btn btn-danger' onClick={openBooking}><AddIcon/>Add New Booking</button>}
                {!addAmenities && tabValue === 1 && <button className='btn btn-danger' onClick={addNewAmenities}><AddIcon/>Add New Amenities</button>}
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                {!addBooking ? <Bookings /> : <AddBooking/>}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                {!addAmenities ? <AmenityList /> : <AddAmenities />}
              </CustomTabPanel>
          </Card>
        </AmenitiesContext.Provider>
      </AuthorizedLayout>
    </>
  )
}

export default Amenities