import React from "react";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";

const data = [
  { label: "Group A", value: 400, color: "#FFA95D" },
  { label: "Group B", value: 300, color: "#60A3F9" },
];

const DoughnutChart = () => {
  return (
    <>
      <Card className="h-100 custom-card dashboard-card">
        <Typography variant="body1">Property Overview</Typography>

        <Stack direction="row">
          <PieChart
            series={[
              {
                paddingAngle: 5,
                innerRadius: 60,
                outerRadius: 80,
                data,
              },
            ]}
            margin={{ right: 5 }}
            width={200}
            height={200}
            legend={{ hidden: true }}
          />
        </Stack>
      </Card>
    </>
  );
};

export default DoughnutChart;
