import { useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import AllInvoices from "./AllInvoices";
import RecurringSetting from "./RecurringSetting";
import IconSearch from "../../Assets/images/search.svg";
import BillingImage from "../../Assets/images/menu-icons/receipt_long_FILL_selected.png";
import AddIcon from "../../Assets/images/add_plus_icon.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PropertySearchComponent from "../../Components/AutoCompleteTextView/PropertySearchComponent";
import { PropertyDetailDTO } from "./NewInvoice";

const getInitialState = () => ({
  id: "",
  type: "",
  description: "",
  attachement: "",
  status: true,
  userId: "",
  property_id: {},
  unit_id: {},
});

const BillingCenter = () => {
  const [tabValue, setTabValue] = useState(0);
  const [reminder, setReminder] = useState(true);
  const [others, setOthers] = useState({
    groupreminder: false,
    addinvoice: false,
    recurringInvoice: false,
  });
  const [searchValue, setSearchValue] = useState("");
  const [propertyId, setPropertyId] = useState("");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setReminder(newValue === 1 ? false : true);
    setOthers({
      groupreminder: false,
      addinvoice: false,
      recurringInvoice: false,
    });
  };

  const handleChange = (e: any, data: any) => {
    setState({ ...state, [e]: data });
  };

  const handleOthers = (val: any) => {
    setOthers({ ...others, ...val });
  };

  const [state, setState] = useState<any>(getInitialState);
  const [propertyList, setPropertyList] = useState([]);
  return (
    <>
      <AuthorizedLayout>
        <Card elevation={0}>
          <Box className="d-flex justify-content-between align-items-center">
            <Box className="d-flex align-items-center">
              <img src={BillingImage} alt="Property" width={20} />
              <Typography className="psemiboldfont page-header-text">
                Billing Center
              </Typography>
              <AntTabs value={tabValue} onChange={handleTabChange}>
                <AntTab label="All Invoices" className="tab-menu" />
                <AntTab label="Recurring Setting" className="tab-menu" />
              </AntTabs>
            </Box>
            {!Object.values(others).includes(true) && (
              <Box>
                {reminder && (
                  <button
                    className="btn-outline-danger me-3"
                    onClick={() => handleOthers({ groupreminder: true })}
                  >
                    <NotificationsIcon
                      sx={{ width: "16px", marginRight: "10px" }}
                    />
                    Send Group Reminder
                  </button>
                )}
                {reminder ? (
                  <ButtonPrimary
                    text="Add New Invoice"
                    onClick={() => handleOthers({ addinvoice: true })}
                    imgSrc={AddIcon}
                  />
                ) : (
                  <ButtonPrimary
                    text="Add New Recurring Setting"
                    //  onClick={() => handleOthers({ addinvoice: true })}
                    imgSrc={AddIcon}
                  />
                )}
              </Box>
            )}
          </Box>
          {!Object.values(others).includes(true) && (
            <Box
              className="w-full d-flex my-30"
              sx={{
                margin: "30px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography className="medium-font">Search</Typography>
                  <TextField
                    variant="standard"
                    placeholder="Type here"
                    sx={{
                      width: "100%",
                    }}
                    onChange={(e: any) => {
                      setSearchValue(e.target.value);
                    }}
                    className="w-full"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={IconSearch} width="18px" height="18px" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography className="medium-font">
                    Select Property
                  </Typography>
                  <PropertySearchComponent
                    onChange={(val: PropertyDetailDTO) => {
                      setPropertyId(val ? val.id : "");
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Box>
            <CustomTabPanel value={tabValue} index={0}>
              {/* <AllInvoices others={others} handleOthers={handleOthers} /> */}
              <AllInvoices
                others={others}
                handleOthers={handleOthers}
                searchString={searchValue}
                propertyId={propertyId}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <RecurringSetting
                screenType={others.recurringInvoice}
                handleOthers={handleOthers}
                searchString={searchValue}
                propertyId={propertyId}
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </AuthorizedLayout>
    </>
  );
};

export default BillingCenter;
