import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';

interface ApiSearchProps {
  options: any;
  getOptionLabel: any;
  searchApi: (value: string) => void;
  loading: boolean;
  onChange: (newValue: any) => void;
  value: any;
  disableClearable?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;

}

const ApiSearch = (props: ApiSearchProps) => {
  const { options, getOptionLabel, searchApi, loading, value , disableClearable = true, isOptionEqualToValue } = props;
  return (
    <>
      <Autocomplete
        options={options}
        id="size-small-standard"
        size="small"
        disableClearable={disableClearable}
        value={value}
        getOptionLabel={getOptionLabel} // Assuming 'name' is the label property in fetched data
        onInputChange={(event, newValue, reason) => {
          if (reason === 'input') {

          searchApi(newValue);
        }

        }}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(event, newValue,reason) => {
          props.onChange(newValue);
          
        }}
        renderInput={(params) => <TextField
           {...params} 
           variant="standard"
           placeholder="Choose"
           InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />}
      />
    </>
  );
}

export default ApiSearch;