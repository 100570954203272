import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Autocomplete, Card, Grid, TextField, Typography } from "@mui/material";
import propertyImg from "../../../../Assets/images/icons/property.png";
import { useAppDispatch } from "../../Store/hooks";
import { isApiConnected } from "../property/controls";
import { showLoader } from "../../Store/loaderSlice";
import { PutApiMethod } from "../../Utils/ApiService";
import { showToaster } from "../../Store/toastSlice";
import { PostApiMethod1 } from "../property/api_service";
import RadioButton1 from "../property/components/radioButton";
import CustomTextfield from "../../Pages/property/components/textfield1";
import BasicDatePicker from "../property/components/datePicker";
import { FormButton1 } from "../property/components/formButton";
import styles from "../../Pages/property/addNewProperty/forms/propertyInformation.module.css";
import QRCode from "../../Assets/images/qr-code.png";
import BarCode from "../../Assets/images/barcode.png";

interface AssetFormType {
  backBtn: Function;
  submitBtn: Function;
  forUpdatePropertyValues?: any;
  isForUpdate?: boolean;
}
interface ApiBody {
  property_type: string;
  property_name: string;
  number_of_unit: string;
  description: string;
  lease_amount: string;
  lease_start_date: string;
  lease_end_date: string;
  id: string;
}
const AddNewAssetForm: React.FunctionComponent<AssetFormType> = ({
  backBtn,
  submitBtn,
  forUpdatePropertyValues,
  isForUpdate = false,
}) => {
  const [Own_or_lease, setOwn_or_lease] = useState("own");
  const [FormContents, setFormContents] = useState(ownPropertyContents); //leasePropertyContents
  const [ApiBodyFinal, setApiBodyFinal] = useState<ApiBody>({
    property_type: Own_or_lease,
    property_name: "",
    number_of_unit: "",
    description: "",
    lease_amount: "",
    lease_start_date: "",
    lease_end_date: "",
    id: "",
  });
  const [LeaseStartDate, setLeaseStartDate] = useState();
  const dispatch = useAppDispatch();

  function setInputValue(
    InputValue: any,
    inputName: string,
    currentIndex: number
  ) {
    setApiBodyFinal({ ...ApiBodyFinal, [inputName]: InputValue });
  }

  function datePicker(key: any, value: any) {
    setApiBodyFinal({ ...ApiBodyFinal, [key]: value });
    setLeaseStartDate(value);
  }

  useEffect(() => {
    if (forUpdatePropertyValues) setApiBodyFinal(forUpdatePropertyValues);
  }, [forUpdatePropertyValues]);
  function changePropertyType(keyValueName: any, selectedProperty: any) {
    setInputValue(keyValueName, selectedProperty, 0);
    console.log("selectedProperty: " + selectedProperty, keyValueName);
    console.log("ApiBodyFinal: " + JSON.stringify(ApiBodyFinal));
    const emptyOBj = {};
    setOwn_or_lease(selectedProperty);
    // setApiBodyFinal(emptyOBj);
    setApiBodyFinal({ ...ApiBodyFinal, [keyValueName]: selectedProperty });
    setFormContents(
      selectedProperty === "own"
        ? ownPropertyContents
        : selectedProperty === "lease"
        ? leasePropertyContents
        : ownPropertyContents
    );
  }

  function backBtnFunc() {
    backBtn("Property Information");
  }

  async function submmitForm(event: any) {
    event.preventDefault();

    if (isApiConnected) {
    } else {
      submitBtn("Property Information", 1);
      return;
    }
    dispatch(showLoader({ showLoader: true }));
    // sampleRealDataForDevelopment
    // ApiBodyFinal
    if (isForUpdate) {
      PutApiMethod("property/properties/" + ApiBodyFinal.id, ApiBodyFinal)
        .then((res) => {
          const PropertyDetails: any = ApiBodyFinal;
          dispatch(
            showToaster({
              dialogBgColor: "bg-success",
              dialogMsg: "Updated successfully",
              showDialog: true,
              timer: "3000",
            })
          );
          submitBtn(
            "Property Information",
            ApiBodyFinal.id,
            PropertyDetails.owner_user_id,
            PropertyDetails.country_id,
            PropertyDetails.number_of_unit,
            ApiBodyFinal
          );
          dispatch(showLoader({ showLoader: false }));
        })
        .catch((e) => {
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              // dialogMsg: e.response.data.message,
              dialogMsg: "Error, Try again",
              showDialognull: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    } else {
      try {
        const res = await PostApiMethod1(
          "property/properties/add",
          ApiBodyFinal
        );

        if (res["data"]["code"] === 201) {
          const PropertyDetails = res["data"]["results"]["data"];
          dispatch(
            showToaster({
              dialogBgColor: "bg-success",
              dialogMsg: "Property Created",
              showDialog: true,
              timer: "3000",
            })
          );
          submitBtn(
            "Property Information",
            PropertyDetails.id,
            PropertyDetails.owner_user_id,
            PropertyDetails.country_id,
            PropertyDetails.number_of_unit,
            ApiBodyFinal
          );
          dispatch(showLoader({ showLoader: false }));
        }
      } catch (e: any) {
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            // dialogMsg: e.response.data.message,
            dialogMsg: "Error, Try again",
            showDialog: true,
            timer: "5000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      }
    }
  }

  const [selectedProperty, setSelectedProperty] = useState(null);

  // Static property options
  const propertyList = [
    { id: 1, property_name: "Option 1" },
    { id: 2, property_name: "Option 2" },
    { id: 3, property_name: "Option 3" },
  ];

  const handlePropertyChange = (event: any, newValue: any) => {
    setSelectedProperty(newValue);
  };

  return (
    <>
      <Card elevation={0}>
        <Typography className="pb-10 mb-2">Select Property Type</Typography>
        <form onSubmit={submmitForm} className={styles.form}>
          {ApiBodyFinal.property_type.toLocaleLowerCase() === "own" ? (
            <>
              {/* <div className={`mb-3 ${styles.inputTotalBox}`}> */}
              <Grid container className="row my-3">
                <Grid item xs={4}>
                  <CustomTextfield
                    label="Asset Model No"
                    name="asset_model_no"
                    type="text"
                    valueChange={setInputValue}
                    value={ApiBodyFinal.property_name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextfield
                    label="Asset Name"
                    name="asset_name"
                    type="number"
                    valueChange={setInputValue}
                    value={ApiBodyFinal.number_of_unit}
                    disable={isForUpdate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextfield
                    label="Description"
                    name="description"
                    type="text"
                    valueChange={setInputValue}
                    value={ApiBodyFinal.description}
                  />
                </Grid>
              </Grid>
              <Grid container className="row my-3">
                <Grid item xs={4}>
                  <Typography className="poppins-family">
                    Asset Status
                  </Typography>
                  <Autocomplete
                    id="size-small-standard1"
                    size="small"
                    options={propertyList}
                    getOptionLabel={(option) => option.property_name}
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Choose"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className="poppins-family">
                    Assign Status
                  </Typography>
                  <Autocomplete
                    id="size-small-standard1"
                    size="small"
                    options={propertyList}
                    getOptionLabel={(option) => option.property_name}
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Choose"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BasicDatePicker
                    label={"Date of Purchase"}
                    name={"date_of_purchase"}
                    val={ApiBodyFinal.lease_start_date}
                    valueChange={setInputValue}
                    // defaultValue={ApiBodyFinal.lease_start_date}
                  />
                </Grid>
              </Grid>
              <Grid container className="row my-3">
                <Grid item xs={4}>
                  <CustomTextfield
                    label="QR Code"
                    name="qr_code"
                    type="number"
                    valueChange={setInputValue}
                    value={ApiBodyFinal.number_of_unit}
                    disable={isForUpdate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className="poppins-family">Category</Typography>
                  <Autocomplete
                    id="size-small-standard1"
                    size="small"
                    options={propertyList}
                    getOptionLabel={(option) => option.property_name}
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Choose"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className="poppins-family">
                    Sub Category
                  </Typography>
                  <Autocomplete
                    id="size-small-standard1"
                    size="small"
                    options={propertyList}
                    getOptionLabel={(option) => option.property_name}
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Choose"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container className="row my-3">
                <Grid item xs={4}>
                  <Typography className="poppins-family">Supplier</Typography>
                  <Autocomplete
                    id="size-small-standard1"
                    size="small"
                    options={propertyList}
                    getOptionLabel={(option) => option.property_name}
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Choose"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className="poppins-family">Department</Typography>
                  <Autocomplete
                    id="size-small-standard1"
                    size="small"
                    options={propertyList}
                    getOptionLabel={(option) => option.property_name}
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Choose"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className="poppins-family">
                    Sub Department
                  </Typography>
                  <Autocomplete
                    id="size-small-standard1"
                    size="small"
                    options={propertyList}
                    getOptionLabel={(option) => option.property_name}
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Choose"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          <div className={styles.btn}>
            <FormButton1 backBtnClick={backBtnFunc} />
          </div>
        </form>
        <div className="d-flex justify-content-end">
          <img src={BarCode} alt="" className="me-4" />
          <img src={QRCode} alt="" />
        </div>
      </Card>
    </>
  );
};
export default AddNewAssetForm;

interface PropertyContent {
  type: "text" | "number";
  lable: string;
  name: string;
}
const ownPropertyContents: PropertyContent[] = [
  {
    type: "text",
    lable: "Property Name",
    name: "property_name",
  },
  {
    type: "number",
    lable: "Number of Units",
    name: "number_of_unit",
  },
  {
    type: "text",
    lable: "Description",
    name: "description",
  },
];

const leasePropertyContents: PropertyContent[] = [
  {
    type: "text",
    lable: "Property Name",
    name: "property_name",
  },
  {
    type: "number",
    lable: "Number of Units",
    name: "number_of_unit",
  },
  {
    type: "number",
    lable: "Lease Amount",
    name: "lease_amount",
  },
  // {
  //   type: "text",
  //   lable: "Lease Start Date",
  //   name: "lease_start_date",
  // },
  // {
  //   type: "number",
  //   lable: "Lease End Date",
  //   name: "lease_end_date",
  // },
  // {
  //   type: "text",
  //   lable: "Description",
  //   name: "description",
  // },
];

const sampleRealDataForDevelopment = {
  property_type: "own",
  property_name: "qqqq",
  number_of_unit: "2",
  description: "descr",
};
