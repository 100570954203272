import React, { useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AntTabs, AntTab } from "../../Components/Tab/AntTab";
import Grid from "@mui/material/Grid";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import AddIcon from "../../Assets/images/add_plus_icon.svg"
import FormControl from "@mui/material/FormControl";
import AddNewNoticeBoard from "./AddNewNoticeBoard";
import AllNoticeBoard from "./AllNoticeBoard";  
import ShowNoticeBoard from "./ShowNoticeBoard"
import EditNoticeBoard from "./EditNoticeBoard";

interface NoticeBoardOptions {
  listNotice: boolean;
  addNotice: boolean;
  addRequest: boolean;
  showNotice: boolean;
  assignVendors: boolean;
  editNotice: boolean;
  [key: string]: boolean; // Index signature
}


const NoticeBoard = () => {
  const [tabValue, setTabValue] = useState(0);
  const [addNew, setAddNew] = useState({
    listNotice: true,
    addNotice: false,
    addRequest: false,
    showNotice: false,
    assignVendors: false,
    editNotice: false,
  });
  const [NoticeBoardData, setNoticeBoardData] = useState("");
  const [listNotice, setListNotice] = useState(true);
  const [search, setSearch] = useState("");


  const handlePageDataChange = (data: any) => {
    setNoticeBoardData(data.data);
  };

  const handleChange = (e: any, val: any) => {
    setTabValue(val);
  };

  const pageStatus = (data: any) => {
    let tmpData: NoticeBoardOptions = { ...addNew };
    for (let prop in tmpData) {
      if (prop !== data) {
        tmpData[prop] = false; // Set all properties to false except the one specified
      } else {
        tmpData[prop] = true;
      }
    }
    setAddNew(tmpData);
  };

  const handleSearchChange = (e: any) => {
    setSearch(e);
  };
  return (
      <>
      <AuthorizedLayout>
        <Card
          variant="outlined"
          sx={{ borderRadius: "8px" }}
          className="border-0 default-page-height"
        >
          <Box className="d-flex justify-content-between align-items-center">
            <Box className="d-flex align-items-center">
             
              <Typography className="psemiboldfont page-header-text">
                Notice Board
              </Typography>
              <AntTabs value={tabValue} onChange={handleChange}>
                <AntTab
                  className="tab-menu"
                  label={
                    (addNew.addNotice
                      ? "Add"
                      : addNew.editNotice 
                      ? "Edit"
                      : addNew.showNotice
                      ? "Show"
                      : "All") + " Notice Board"
                  }
                />
              </AntTabs>
            </Box>
            {addNew["listNotice"] && (
              <ButtonPrimary
                text="Add New Notice board"
                onClick={() => pageStatus("addNotice")}
                imgSrc={AddIcon}
              />
            )}
          </Box>
          {!Object.values(addNew).includes(true) && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {listNotice ? (
                  <Grid item lg={2} md={6} xs={12} sm={12}>
                    <FormControl variant="standard" className="w-100">
                      <TextField
                        type="text"
                        variant="standard"
                        placeholder="Search here..."
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          handleSearchChange(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Box>
          )}
          <Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box>
                {addNew["listNotice"] ? (
                  <AllNoticeBoard
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                  />
                ) : addNew["addNotice"] ? (
                  <AddNewNoticeBoard
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                    NoticeBoardData={NoticeBoardData}
                    tenantsearch={search}
                  />

                ) : addNew["showNotice"] ? (
                  <ShowNoticeBoard
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                    NoticeBoardData={NoticeBoardData}
                  />

                ) : (
                  addNew["editNotice"] && (
                    <EditNoticeBoard
                      pageStatus={pageStatus}
                      pageData={(e: any) => {
                        handlePageDataChange(e);
                      }}
                      NoticeBoardData={NoticeBoardData}
                    />

                  )
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
           </CustomTabPanel>
          </Box>
        </Card>
      </AuthorizedLayout>
    </>
  );
};

export default NoticeBoard;
