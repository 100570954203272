import React, { useCallback, useState } from "react";
import styles from "./image.module.css";
import { FormButton1 } from "../../components/formButton";
import fileUpload from "../../../../Assets/images/icons/fileUpload.png";
import { useAppDispatch } from "../../../../Store/hooks";
import ImageSelectField from "../../components/imageField";
import { Formik, Form, Field } from "formik";
import { showLoader } from "../../../../Store/loaderSlice";
import { PutApiMethod } from "../../../../Utils/ApiService";
import { errorHandling } from "../../../../Utils/HelperService";
import { showToaster } from "../../../../Store/toastSlice";
import { PostApiMethod } from "../../../../Utils/ApiService";

interface unitFormType {
  backBtn: Function;
  submitBtn: Function;
  CreatedUnitIdFromApi: any;
  CreatedNo_of_unitsFromApi: string;
  CreatedPropertyIdFromApi: string;
}
interface ApiBody {
  [key: string]: string; // Index signature to allow additional key-value pairs
}
const ImageForm: React.FunctionComponent<unitFormType> = ({
  backBtn,
  submitBtn,
  CreatedUnitIdFromApi,
  CreatedNo_of_unitsFromApi,
  CreatedPropertyIdFromApi,
}) => {
  const [ApiBodyFinal, setApiBodyFinal] = useState<ApiBody>({});
  const dispatch = useAppDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);

  function backBtnFunc() {
    console.log("back btn called");
    backBtn("Unit");
  }

  function addImages() {}

  function submmitForm(event: any) {
    event.preventDefault();
    console.log("submit form: " + JSON.stringify(ApiBodyFinal));
    console.log("form submitted");
    submitBtn("Unit");
  }

  const uploadImage = useCallback(
    (values: any) => {
      dispatch(showLoader({ showLoader: true }));
      PostApiMethod(
        "property/properties/updateImage/" + CreatedPropertyIdFromApi,
        { image: values.files },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((res) => {
          dispatch(showLoader({ showLoader: false }));
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Image uploaded successfully",
              showDialog: true,
              timer: "5000",
            })
          );
          backBtnFunc();
        })
        .catch((e) => {
          dispatch(showLoader({ showLoader: false }));
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );

        });
    },
    [CreatedPropertyIdFromApi]
  );

  return (
    <>
      <div className={styles.topBox}>
        <Formik initialValues={{ files: [] }} onSubmit={(values) => {
          uploadImage(values);
        }}>
          {({ setFieldValue, values, errors, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div>
                <img
                  src={fileUpload}
                  alt="imgBox"
                  className={styles.iconImg}
                  width={50}
                />
                <p>
                  Drag and Drop Image or{" "}
                  <span className={styles.chooseFile}>
                    <ImageSelectField
                      label={""}
                      name={"files"}
                      valueChange={(files: any, name: string) => {
                        console.log(files);
                        setSelectedFiles(files);
                        setFieldValue(name, files);
                      }}
                      currentIndex={0}
                    />
                  </span>
                </p>
                <ul>
                  {/* {values.files.map((file: any) => (
                    <>
                  </>
                  ))} */}
                </ul>
              </div>
              <FormButton1 backBtnClick={backBtnFunc} />
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ImageForm;

//
