import React, { ReactNode, useState } from "react";
import SideBar from "../../Components/SideBarNavigation/SideBar";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Toaster from "../../Components/Toaster/Toaster";
import Loader from "../../Components/Loader/Loader";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}
export const AuthorizedLayout = (props: Props) => {
  const { children } = props;
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const location = useLocation();
  const isDashboardNoData =
    location.pathname === "/dashboard" ||
    location.pathname === "/helpDesk" ||
    location.pathname === "/assets";
  const removePadding = location.pathname === "/document";

  return (
    <div>
      <SideBar openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <Box className="layout-sec">
        <Box
          className={`layout-inner ${
            isDashboardNoData ? "dashboard-sec" : ""
          } ${removePadding ? "p-0" : ""}`}
        >
          {children}
        </Box>
      </Box>
      <Toaster />
      <Loader />
    </div>
  );
};
