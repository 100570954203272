import * as Yup from "yup";

export const bookingSchema = Yup.object().shape({
    property_id: Yup.string().required("Property field is required"),
    unit_id: Yup.string().required("Unit field is required"),
    name: Yup.string().required("First name field is required"),
    amenities: Yup.string().required("Amenities field is required"),
    date: Yup.string().required("Date field is required"),
    timings: Yup.string().required("Timings field is required"),
    no_of_people: Yup.string().required("No of people field is require")
}) 

export const amenitiesSchema = Yup.object().shape({
    name: Yup.string().required("Amenity field is required"),
    propertyId: Yup.string().required("Property field is required"),
    openingTime: Yup.string().required("Open Timing field is required"),
    closingTime: Yup.string().required("Close Timing field is required")
})
