import React, { useState } from "react";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Actions from "../../Components/Actions/Actions";
import ColorText from "../../Components/Text/ColorText";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const TopFiveTable = () => {
  const [colors, setColors] = useState({
    assigned: "#24BC94",
    pending: "#FFA95D",
    completed: "#CA4FE8",
  });
  const [data, setData] = useState([
    {
      id: 1,
      resident: "Addie Bradford",
      property: "A-123",
      issue: "Plumbing",
      status: "assigned",
      assigned_to: "Trend Mariow",
    },
    {
      id: 2,
      resident: "Addie Bradford",
      property: "A-123",
      issue: "Plumbing",
      status: "pending",
      assigned_to: "Trend Mariow",
    },
    {
      id: 3,
      resident: "Addie Bradford",
      property: "A-123",
      issue: "Plumbing",
      status: "completed",
      assigned_to: "Trend Mariow",
    },
    {
      id: 4,
      resident: "Addie Bradford",
      property: "A-123",
      issue: "Plumbing",
      status: "assigned",
      assigned_to: "Trend Mariow",
    },
    {
      id: 5,
      resident: "Addie Bradford",
      property: "A-123",
      issue: "Plumbing",
      status: "assigned",
      assigned_to: "Trend Mariow",
    },
  ]);
  return (
    <>
      <Card className="h-100 custom-card">
        <Box className="dashboard-card flex_row_between">
          <Typography variant="body1">Help Desk</Typography>
          <button
            style={{
              background: "white",
              border: "1px solid #FF7D73",
              color: "#FF7d73",
              padding: "5px 15px 5px 15px",
              borderRadius: "25px",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          >
            View all
          </button>
        </Box>
        <Box className="dashboard-card">
          <Typography variant="subtitle1">Recently Raised tickets</Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Resident</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Issue</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((val, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell sx={{ fontSize: "13px" }}>
                      {val.resident}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }}>
                      {val.property}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }}>{val.issue}</TableCell>
                    <TableCell>
                      {val["status"] === "assigned" && (
                        <ColorText color={"#24BC94"}>
                          <div style={{ fontSize: "13px" }} className="fw-bold">
                            Assigned
                          </div>
                        </ColorText>
                      )}
                      {val["status"] === "pending" && (
                        <ColorText color={"#FFA95D"}>
                          <div style={{ fontSize: "13px" }} className="fw-bold">
                            Pending
                          </div>
                        </ColorText>
                      )}
                      {val["status"] === "completed" && (
                        <ColorText color={"#CA4FE8"}>
                          <div style={{ fontSize: "12px" }} className="fw-bold">
                            Completed
                          </div>
                        </ColorText>
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }}>
                      {val.assigned_to}
                    </TableCell>
                    <TableCell>
                      <Actions
                        name={
                          <div style={{ fontSize: "13px" }} className="fw-bold">
                            More
                          </div>
                        }
                        data={[
                          {
                            name: (
                              <div
                                style={{ fontSize: "13px" }}
                                className="fw-bold"
                              >
                                Edit
                              </div>
                            ),
                            color: "text-primary",
                          },
                          {
                            name: (
                              <div
                                style={{ fontSize: "13px" }}
                                className="fw-bold"
                              >
                                Delete
                              </div>
                            ),
                            color: "text-danger",
                          },
                        ]}
                      />
                    </TableCell>
                    {/* <TableCell></TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default TopFiveTable;
