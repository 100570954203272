import React, { useEffect, useState } from "react";
import CustomTextfield from "../../components/textfield1";
import styles from "./propertyInformation.module.css";
import RadioButton1 from "../../components/radioButton";
import { radioButton1 } from "../../types/interface";
import { FormButton1 } from "../../components/formButton";
import DropdownSelect from "../../components/dropdown1";
import { PostApiMethod, PutApiMethod } from "../../../../Utils/ApiService";
import { useAppDispatch } from "../../../../Store/hooks";
import { showLoader } from "../../../../Store/loaderSlice";
import { showToaster } from "../../../../Store/toastSlice";
import { isApiConnected } from "../../controls";
import { ListOfstate } from "../../utlity/country";
import { PutApiMethod1 } from "../../api_service";
import { Grid } from '@mui/material';

interface LocationType {
  backBtn: Function;
  submitBtn: Function;
  CreatedPropertyDetailsFromApi: string;
  CreatedCountryIdFromApi: string;
  forUpdatePropertyValues?: any;
  isForUpdate?: boolean;
}
const LocationForm: React.FunctionComponent<LocationType> = ({
  backBtn,
  submitBtn,
  CreatedPropertyDetailsFromApi,
  CreatedCountryIdFromApi,
  forUpdatePropertyValues,
  isForUpdate,
}) => {
  const [ApiBodyFinal, setApiBodyFinal] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
    zip_code: "",
    address: "",
    map_link: "",
  });
  const [StateDropDown, setStateDropDown] = useState<DropdownOptionType[]>([
    { value: "", label: "Choose" },
  ]);
  const dispatch = useAppDispatch();
  const countryDropDown: DropdownOptionType[] = [
    { value: "", label: "Choose" },
    { value: CreatedCountryIdFromApi, label: CreatedCountryIdFromApi },
  ];
  const stateDropdown: DropdownOptionType[] = [{ value: "", label: "Choose" }];

  function setInputValue(
    InputValue: any,
    inputName: string,
    currentIndex: number
  ) {
    setApiBodyFinal({ ...ApiBodyFinal, [inputName]: InputValue });
  }

  function handleSelectChange(key: string, value: String) {
    setApiBodyFinal({ ...ApiBodyFinal, [key]: value });
  }

  function backBtnFunc() {
    backBtn("Location");
  }

  async function submmitForm(event: any) {
    event.preventDefault();
    if (isApiConnected) {
    } else {
      console.log("api off in controler");
      submitBtn("Location", 1);
      return;
    }
    dispatch(showLoader({ showLoader: true }));
    // ApiBodyFinal
    // sampleRealWorkingData
    try {
      const res = await PutApiMethod1(
        "property/properties/" + CreatedPropertyDetailsFromApi,
        {
          country_id: ApiBodyFinal.country_id,
          state_id:  ApiBodyFinal.state_id,
          city_id:  ApiBodyFinal.city_id,
          zip_code:  ApiBodyFinal.zip_code,
          address:  ApiBodyFinal.address,
          map_link:  ApiBodyFinal.map_link,
        }
      );
      if (res["data"]["code"] === 201) {
        console.log("res: " + JSON.stringify(res["data"]["results"]["data"]));
        // const PropertyUpdateDetails = res["data"]["results"]["data"];
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: "location updated success",
            showDialog: true,
            timer: "5000",
          })
        );
        submitBtn("Location");
        dispatch(showLoader({ showLoader: false }));
      }
    } catch (e: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          // dialogMsg: e.toString(),
          dialogMsg: "location not updated, Try again",
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  }

  useEffect(() => {
    ListOfstate.india.map(
      () => (val: string, index: number) =>
        setStateDropDown([{ value: val, label: val }])
    );
  }, [stateDropdown]);

  useEffect(() => {
    if (forUpdatePropertyValues) setApiBodyFinal(forUpdatePropertyValues);
  }, [forUpdatePropertyValues]);

  return (
    <>
      <div className={styles.topBox}>
        <form onSubmit={submmitForm}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <CustomTextfield
                label={"Country"}
                name={"country_id"}
                valueChange={setInputValue}
                value={ApiBodyFinal.country_id}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <CustomTextfield
                label={"State"}
                name={"state_id"}
                valueChange={setInputValue}
                value={ApiBodyFinal.state_id}
              />
            </Grid>
            <Grid item sm={12} xs={12} lg={4} md={4}>
              <CustomTextfield
                label={"City"}
                name={"city_id"}
                valueChange={setInputValue}
                value={ApiBodyFinal.city_id}
              />
            </Grid>
            <Grid item sm={12} xs={12} lg={4} md={4}>
              <CustomTextfield
                label={"Zip Code"}
                name={"zip_code"}
                valueChange={setInputValue}
                value={ApiBodyFinal.zip_code}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomTextfield
                label={"Address"}
                name={"address"}
                valueChange={setInputValue}
                value={ApiBodyFinal.address}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomTextfield
                label={"Map Link"}
                name={"map_link"}
                valueChange={setInputValue}
                value={ApiBodyFinal.map_link}
              />
            </Grid>
          </Grid>
          <FormButton1 backBtnClick={backBtnFunc} />
        </form>
        {/* <input type="text" placeholder="place" name="lllll" /> */}
      </div>
    </>
  );
};
export default LocationForm;

interface LocationContent {
  type: "text" | "number";
  lable: string;
  name: string;
}

interface DropdownOptionType {
  value: string;
  label: string;
}


// interface {
//   "createdAt":"","status":true,"country_id":"India","id":25,"property_type":"own","property_name":"ssd","description":"2dsa","number_of_unit":"1","owner_user_id":8,"updatedAt":"2024-04-10T10:20:27.003Z"
// }
