import * as Yup from "yup";

export const visitorSchema = Yup.object().shape({
    visitor_name: Yup.string().required("Visitor Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    room_id: Yup.string().required("Room is required"),
    date: Yup.string().required("Date is required"),
    from: Yup.string().required("From is required"),
    to: Yup.string().required("To is required"),
}) 

// export const personalVisitorSchema = Yup.object().shape({
//     visitor_name: Yup.string()
//         .required("Visitor Name is required"),
//     email: Yup.string()
//         .email("Invalid email")
//         .required("Email is required"),
//     phone: Yup.string()
//         .min(10,"To short")
//         .max(10,"To long")
//         .required("Phone number is required"),
//     // parking_id: Yup.string()
//     //     .required("Parking no is required"),
//     unit_id: Yup.string()
//         .required("Unit is required"),
//     parking_requirement: Yup.string()
//         .required("Parking is required"),
//     description: Yup.string()
//         .required("Description is required"),
//     tenant_id: Yup.string()
//         .required("Tenant Name is required")
// });


// export const officialVisitorSchema = Yup.object().shape({
//     visitor_name: Yup.string()
//         .required("Visitor Name is required"),
//     visitor_type: Yup.string()
//         .required("Visitor is required"),
//     email: Yup.string()
//         .email("Invalid email address format")
//         .required("Email is required"),
//     // unit_id: Yup.string()
//     //     .required("Unit is required"),
//     phone: Yup.string()
//         .min(10,"To short")
//         .max(10,"To long")
//         .required("Phone no is required"),
//     parking_requirement: Yup.string()
//         .required("Parking is required"),
//     location: Yup.string()
//         .required("Location field is required"),
//     // room_id: Yup.string()
//     //     .required("Room field is required"),
//     date: Yup.string()
//         .required("Date field is required"),
//     timing: Yup.string()
//         .required("Timing field is required"),
//     tenant_id: Yup.string()
//         .required("Host Name is required"),
//     description: Yup.string()
//         .required("Description field is required")
// });
