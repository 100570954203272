import React, { useEffect, useState , useCallback} from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  DialogProps,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  InputAdornment,
  TableFooter
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DeleteApiMethod,
  GetApiMethod,
  PostApiMethod,
  PutApiMethod,
} from "../../Utils/ApiService";
import { errorHandling, isNotEmpty } from "../../Utils/HelperService";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import Upload from "../../Components/Upload/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import ShowHelpDesk from "./ShowHelpDesk";
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";
import { showToaster } from "../../Store/toastSlice";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AssignVendors from "../Vendors/AssignVendor";
import IconSearch from "../../Assets/images/search.svg"
import Pagination from "../../Components/Pagination/Pagination";
const ITEM_HEIGHT = 48;
const ListHelpDesk = (props: any) => {
  // const [property, setProperty] = useState("") 
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const getInitialState = () => ({
    id: "",
    type: "",
    description: "",
    attachement: "",
    status: true,
    userId: 1,
    property_id: "",
    unit_id: "",
    vendor_id: {},
  });
  const [state, setState] = useState<any>(getInitialState);
  const [addHelpDesk, setAddHelpDesk] = useState(false);
  const [helpDeskList, setHelpDeskList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [helpDeskCount, setHelpDeskCount] = useState(0);
  const [label, setLabel] = useState("Add");
  const [btnLabel, setBtnLabel] = useState("Add");
  const [upData, setUpData] = useState<any>([]);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [showHelpDesk, setShowHelpDesk] = useState(false);
  const [assignVendors, setAssignVendors] = useState(false);
  const [limit, setLimit] = useState(10);
  const [vendorsList, setVendorsList] = useState([]);
  const [vendorsCount, setVendorsCount] = useState([]);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [open1, setOpen1] = React.useState(false);
  const [vendorAssignData, setVendorAssignData] = useState<any>({});
  const [page, setPage] = useState({ page: 1, limit: 5, count: 0 });
  const [vendorAssignErrors, setVendorAssignErrors] = useState(false);
  const [vendorAssignErrorMessage, setVendorAssignErrorMessage] = useState("");
  const [popopen, setPopopen] = useState<any>(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });

  const fetchVendors = async () => {
    dispatch(showLoader({ showLoader: true }));
    let formdata = {
      role: "PMA",
      // page: page,
      // limit: limit,
      // searchString: search
    };
    let response = await PostApiMethod("user/getUser", formdata);
    if (response["data"]["error"] === false) {
      setVendorsList(response.data.results.data);
      setVendorsCount(response.data.results.totalCount);
      dispatch(showLoader({ showLoader: false }));
    } else {
      console.log("response", response);
      dispatch(showLoader({ showLoader: false }));
    }
  };
  const rowsPerPage = useCallback(
    (val: any) => {
      setPage({ ...page, page: val.page, limit: val.limit });
      fetchHelpDeskSearchList(val.page, val.limit);
    },
    [page]
  );
  const fetchHelpDeskSearchList = async (page: any , limit:any) => {
    dispatch(showLoader({ showLoader: true }));
    let formdata = {
      page: page,
      limit: limit,
      searchString: searchValue,
    };
    let response: any;
    try {
      response = await PostApiMethod("property/help-desk/list", formdata);
      if (response["data"]["error"] === false) {
        setHelpDeskList(response.data.results.data);
        setPage((prevPage) => ({
          ...prevPage,
          count: response["data"]["results"]["totalCount"],
        }));
        setHelpDeskCount(response.data.results.totalCount);
        dispatch(showLoader({ showLoader: false }));
      } else {
        console.log("response", response);
        dispatch(showLoader({ showLoader: false }));
      }
    } catch (err: any) {
      dispatch(showLoader({ showLoader: false }));
    }
  };
  useEffect(() => {
    fetchHelpDeskSearchList(page.page, page.limit);
    fetchVendors();
  }, [searchValue]);


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [cOpen, setcOpen] = useState<Number>(0);
  const handleClick = (id: Number, event: React.MouseEvent<HTMLElement>) => {
    setcOpen(cOpen === id ? 0 : id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any, data: any) => {
    setcOpen(0);
    setAnchorEl(null);
    if (e === "View") {
      props.pageStatus("showHelpDesk");
      // fetchHelpDeskById(data)
      console.log("data", data);
      let tmpdata = {
        page: e,
        data: data,
      };
      props.pageData(tmpdata);
    }
    if (e === "Assign") {
      // props.pageStatus('assignVendors');
      let tmpdata = {
        page: e,
        data: data,
      };
      // props.pageData(tmpdata)
      setVendorAssignData(data);
      setOpen1(true);
      // setPopopen(true)
    }
    if (e === "Delete") {
      deleteHelpDesk(data.id);
    }
  };

  const deleteHelpDesk = async (id: any) => {
    dispatch(showLoader({ showLoader: true }));
    let response: any;
    DeleteApiMethod("property/help-desk/" + id)
      .then((res) => {
        fetchHelpDeskSearchList(page.page, page.limit);
        // setHelpDeskList(response.data.results.data);
        // setHelpDeskCount(response.data.results.totalCount);
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: "Deleted successfully",
            showDialog: true,
            timer: "3000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
  };
  const options = ["View", "Assign", "Delete"];

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleVendorChange = (e: any, data: any) => {
    setVendorAssignErrors(false);
    console.log({ ...vendorAssignData, [e]: data });
    setVendorAssignData({ ...vendorAssignData, [e]: data });
  };
  const handleVendorSubmit = async (e: any) => {
    // vendorAssignErrors
    if (vendorAssignData) {
      if (!vendorAssignData.id) {
        setVendorAssignErrors(true);
        setVendorAssignErrorMessage("Helpdesk Id Missing");
        return;
      }
      if (!vendorAssignData.vendor_id) {
        setVendorAssignErrors(true);
        setVendorAssignErrorMessage("Kindly Select Vendor");
        return;
      }
    }
    dispatch(showLoader({ showLoader: true }));
    let formdata = {
      help_desk_id: vendorAssignData.id,
      vendor_id: vendorAssignData.vendor_id.id.toString(),
      message: vendorAssignData.message
        ? vendorAssignData.message.toString()
        : "",
      priority: vendorAssignData.priority,
    };
    let response: any;
    try {
      response = await PostApiMethod(
        "property/help-desk/assign-help-desk",
        formdata
      );
      if (response && response["data"]["error"] === false) {
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: response["data"]["message"],
            showDialog: true,
            timer: "3000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
        fetchHelpDeskSearchList(page.page, page.limit);
        setOpen1(false);
      } else {
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: response["data"]["message"],
            showDialog: true,
            timer: "5000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };
  const handlePopup = (e: any) => {
    setPopopen(e);
  };
  return (
    <>
      <Box className="card-title" sx={{ padding: "0 22px" }}>
        <Typography>Recently Raised tickets</Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Paper elevation={0}>
        <Box
            className="w-full max-w-400 my-30"
            sx={{
              width: "400px",
              margin: "30px 0",
              padding: "0 22px"
            }}
          >
            <Typography variant="body2">Search</Typography>
            <TextField
              variant="standard"
              placeholder="Type here"
              sx={{
                width: "100%",
              }}
              onChange={(e: any) => {
                setSearchValue(e.target.value)
              }}
              className="w-full"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconSearch} width="18px" height="18px" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TableContainer className="table-striped">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ticked No</TableCell>
                  <TableCell>Raised by</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Assigned to</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helpDeskList &&
                  helpDeskList.map((helpDesk: any, index: any) => (
                    <TableRow key={index}>
                           <TableCell>
                        {isNotEmpty(helpDesk.ticketNo) ? helpDesk.ticketNo : ""}
                      </TableCell>
                      <TableCell>
                        {helpDesk &&
                          isNotEmpty(helpDesk.userDetail) &&
                          (isNotEmpty(helpDesk.userDetail.firstName)
                            ? helpDesk.userDetail.firstName
                            : "") +
                            " " +
                            (isNotEmpty(helpDesk.userDetail.lastName)
                              ? helpDesk.userDetail.lastName
                              : "")}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(helpDesk.unit) &&
                        isNotEmpty(helpDesk.unit.unit_name)
                          ? helpDesk.unit.unit_name
                          : ""}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(helpDesk.typeDetail) &&
                        isNotEmpty(helpDesk.typeDetail.name)
                          ? helpDesk.typeDetail.name
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          color: `${
                            helpDesk.status === "Pending"
                              ? "#FFA95D"
                              : helpDesk.status === "Completed"
                              ? "#CA4FE8"
                              : helpDesk.status === "Assigned"
                              ? "#24BC94"
                              : helpDesk.status === "Urgent"
                              ? "#FF7D73"
                              : helpDesk.status === "Re-Assigned"
                              ? "#60A3F9"
                              : helpDesk.status === "Closed"
                              ? "#FFA95D"
                              : ""
                          }`,
                        }}
                      >
                        {isNotEmpty(helpDesk.status) ? helpDesk.status : ""}
                      </TableCell>
                      <TableCell>
                        {isNotEmpty(helpDesk.priority) ? (
                          <>
                            <FiberManualRecordIcon
                              style={{
                                fontSize: 12,
                                color: `${
                                  helpDesk.priority === "Low"
                                    ? "#FFA95D"
                                    : helpDesk.priority === "High"
                                    ? "#60A3F9"
                                    : helpDesk.priority === "Medium"
                                    ? "#24BC94"
                                    : helpDesk.priority === "Urgent"
                                    ? "#FF7D73"
                                    : ""
                                }`,
                              }}
                            />
                            {helpDesk.priority}{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </TableCell>
                 
                      <TableCell>
                        {isNotEmpty(helpDesk.assignDetail) &&
                          (isNotEmpty(helpDesk.assignDetail.firstName)
                            ? helpDesk.assignDetail.firstName
                            : "") +
                            " " +
                            (isNotEmpty(helpDesk.assignDetail.lastName)
                              ? helpDesk.assignDetail.lastName
                              : "")}
                      </TableCell>
                      <TableCell
                      // onClick={(e) => { handleHelpDeskEdit(helpDesk) }}
                      >
                        {/* <EditCalendarTwoToneIcon /> */}
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(helpDesk.id, e);
                          }}
                        >
                          <Typography
                            className="poppins-family"
                            color="primary"
                          >
                            Action
                          </Typography>
                          <KeyboardArrowDownTwoToneIcon color="primary" />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={cOpen === helpDesk.id}
                          onClose={handleClose}
                          // PaperProps={{
                          //     style: {
                          //         maxHeight: ITEM_HEIGHT * 4.5,
                          //         width: '20ch',
                          //     },
                          // }}
                        >
                          {options.map((option: any) => (
                            <MenuItem
                              key={option}
                              onClick={() => {
                                handleClose(option, helpDesk);
                              }}
                            >
                              {option === "Assign" &&
                              isNotEmpty(helpDesk.assignDetail)
                                ? "Reassign"
                                : option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
           
          {helpDeskCount ? (
            <TableFooter>
            <TableRow>
              <Pagination
                pagination={page}
                colSpan={6}
                rowsPerPageChange={rowsPerPage}
              />
            </TableRow>
          </TableFooter>
          ) : (
            "No data"
          )}
           </Table>
          </TableContainer>
          {/* <Dialog
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="xs"
                        fullWidth={true}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Assign Vendor"}
                        </DialogTitle>
                        <DialogContent>

                            <Typography className='poppins-family'>Vendors List</Typography>
                            <Autocomplete
                                id="size-small-standard"
                                size="small"
                                options={vendorsList}
                                getOptionLabel={(option: any) => option.firstName.toString()}
                                onChange={(event: any, newValue: any) => { newValue !== null ? handleVendorChange("vendor_id", newValue) : handleVendorChange("vendor_id", {}) }}
                                // value={options && options.find((option:any) => option.id === state["vendor_id"])}
                                inputValue={Object.keys(vendorAssignData).length ? vendorAssignData.firstName : ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        // label="Property"
                                        placeholder="Choose"
                                    />
                                )}
                            />
                            <Grid item lg={12} sx={{ mt: 3 }}>
                                <Typography className='poppins-family'>Message</Typography>
                                <TextField type="text" variant='standard' placeholder=' here...' className='form-control' name="message" onChange={(e) => { handleVendorChange(e.target.name, e.target.value) }} value={state["message"]} />
                            </Grid>
                            {vendorAssignErrors ? <Typography className='poppins-family text-danger' sx={{ mt: 3 }}>{vendorAssignErrorMessage}</Typography> : ""}
                        </DialogContent>
                        <DialogActions>
                            <Button className='btn-danger ms-2' type="button" onClick={() => { setVendorAssignData(""); setOpen1(false); setVendorAssignErrors(false) }} >Cancel</Button>
                            <Button className='btn-danger ms-2' type="button" onClick={handleVendorSubmit} disabled={vendorAssignErrors}>Assign</Button>
                        </DialogActions>
                    </Dialog> */}
          <AssignVendors
            popup={open1}
            vendorAssigndata={vendorAssignData}
            popupClose={handleClose1}
            submit={() => {
              fetchHelpDeskSearchList(page.page, page.limit);
              handleClose1();
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ListHelpDesk;
