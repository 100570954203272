import React, { useState , useCallback , useEffect} from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import Pagination from '../../Components/Pagination/Pagination';
import { useAppDispatch } from '../../Store/hooks';
import {showLoader} from "../../Store/loaderSlice"
import { PostApiMethod , DeleteApiMethod} from '../../Utils/ApiService';
import { DisplayErrorMessages , isNotEmpty } from '../../Utils/HelperService';
import { showToaster } from "../../Store/toastSlice";
import Button from "@mui/material/Button";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";

const AllExpense = (props:any) => {
const ITEM_HEIGHT = 48;

    const [ExpenseList, setExpenseList] = useState<any[]>([]);
  const [page, setPage] = useState({ page: 1, limit: 5, count: 0 });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cOpen, setcOpen] = useState<Number>(0);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useAppDispatch();
  const options = ["Edit", "Delete"];

  
    const getUsers = async (page: any, limit: any) => {
        dispatch(showLoader({ showLoader: true }));
        try {
          let res = await PostApiMethod("property/expense/list", {
            page: page,
            limit: limit,
            searchString: props.propertysearch,
          });
          if (res["data"]["code"] === 200) {
            setExpenseList(res["data"]["results"]["data"]);
            setPage((prevPage) => ({
              ...prevPage,
              count: res["data"]["results"]["totalCount"],
            }));
            setTotalCount(res.data.results.totalCount);
          }
          dispatch(showLoader({ showLoader: false }));
        } catch (e) {
          const error = DisplayErrorMessages(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        }
      };
      const rowsPerPage = useCallback(
        (val: any) => {
          setPage({ ...page, page: val.page, limit: val.limit });
          getUsers(val.page, val.limit);
        },
        [page]
      );

      const handleClick = (id: Number, event: React.MouseEvent<HTMLElement>) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
      };
      const handleClose = (e: any, data: any) => {
        setcOpen(0);
        setAnchorEl(null);
        if (e === "View") {
          props.pageStatus("showExpense");
          // fetchTenantById(data)
          let tmpdata = {
            page: e,
            data: data,
          };
          props.pageData(tmpdata);
        }
        if (e === "Edit") {
          props.pageStatus("editExpense");
          let tmpdata = {
            page: e,
            data: data,
          };
          props.pageData(tmpdata);
        }
        if (e === "Delete") {
          deleteExpense(data.id);
        }
      };
      const deleteExpense = async (id: any) => {
        DeleteApiMethod("property/expense/" + id)
          .then((res) => {
            dispatch(
              showToaster({
                dialogBgColor: "success",
                dialogMsg: "Deleted successfully",
                showDialog: true,
                timer: "5000",
              })
            );
            getUsers(page.page, page.limit);
          })
          .catch((e) => {
            const error = DisplayErrorMessages(e);
          });
      };
      
      useEffect(()=>{
        getUsers(page.page, page.limit);
      },[])
    return (
        <>
            <Box className="mt-3">
      {totalCount > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Expense Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Responsibility</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>View Details</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ExpenseList.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.name}</TableCell>
                  <TableCell>{val.property.property_name}</TableCell>
                  <TableCell>{val.unit.unit_name}</TableCell>
                  <TableCell>{val.expenseType.name}</TableCell>
                  <TableCell>{val.description}</TableCell>
                  <TableCell>{val.responsibility}</TableCell>
                  <TableCell>{val.amount}</TableCell>
                  <TableCell>
                  {" "}
                        <Button
                          variant="contained"
                          onClick={() => handleClose("View", val)}
                          style={{ borderRadius: 25 }}
                        >
                          view
                        </Button>
                  </TableCell>
                <TableCell>
                        <IconButton
                          aria-label="more"
                          id={"long-button_" + val.id}
                          aria-controls={
                            cOpen === val.id
                              ? "long-menu_" + val.id
                              : undefined
                          }
                          aria-expanded={
                            cOpen === val.id ? "true" : undefined
                          }
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(val.id, e);
                          }}
                        >
                          <Typography
                            className="poppins-family"
                            color="primary"
                          >
                            Action
                          </Typography>
                          <KeyboardArrowDownTwoToneIcon color="primary" />
                        </IconButton>
                        <Menu
                          id={"long-menu_" + val.id}
                          MenuListProps={{
                            "aria-labelledby": "long-button_" + val.id,
                          }}
                          anchorEl={anchorEl}
                          open={cOpen === val.id}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          {options.map((option: any) => (
                            <MenuItem
                              key={option}
                              onClick={() => {
                                handleClose(option, val);
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {ExpenseList.length !== 0 && (
                <TableFooter>
                  <TableRow>
                    <Pagination
                      pagination={page}
                      colSpan={6}
                      rowsPerPageChange={rowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              )}
          </Table>
        </TableContainer>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Typography>No data</Typography>
        </div>
      )}
    </Box>
        </>
    )
}

export default AllExpense