import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Autocomplete, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DeleteApiMethod,
  GetApiMethod,
  PostApiMethod,
  PutApiMethod,
} from "../../Utils/ApiService";
import { isNotEmpty } from "../../Utils/HelperService";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TablePagination from "@mui/material/TablePagination";
import Upload from "../../Components/Upload/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import ShowHelpDesk from "./ShowHelpDesk";
import AssignVendors from "../Vendors/AssignVendorsList";
import { showToaster } from "../../Store/toastSlice";
import { showLoader } from "../../Store/loaderSlice";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropertySearchComponent from "../../Components/AutoCompleteTextView/PropertySearchComponent";
import { PropertyDetailDTO } from "../BillingCenter/NewInvoice";

export const statusList = [
  { id: "Pending", name: "Pending" },
  { id: "Assigned", name: "Assigned" },
  { id: "Re-Assigned", name: "Re-Assigned" },
  { id: "Completed", name: "Completed" },
  { id: "Closed", name: "Closed" },
];
export const priorityList = [
  { id: "Low", name: "Low" },
  { id: "High", name: "High" },
  { id: "Medium", name: "Medium" },
  { id: "Urgent", name: "Urgent" },
];
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ITEM_HEIGHT = 48;
const AddHelpDesk = (props: any) => {
  // const [property, setProperty] = useState("")
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const getInitialState = () => ({
    id: "",
    type_id: {},
    description: "",
    attachement: [],
    status: {},
    priority: {},
    userId: "",
    property_id: {},
    unit_id: {},
  });
  const [state, setState] = useState<any>(getInitialState);
  const [loading, setLoading] = useState(false);
  const [addHelpDesk, setAddHelpDesk] = useState(false);
  const [helpDeskList, setHelpDeskList] = useState([]);
  const [helpDeskCount, setHelpDeskCount] = useState(0);
  const [label, setLabel] = useState("Add");
  const [btnLabel, setBtnLabel] = useState("Create");
  const [upData, setUpData] = useState<any>([]);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [showHelpDesk, setShowHelpDesk] = useState(false);
  const [assignVendors, setAssignVendors] = useState(false);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [uploadData, setUploadData] = useState<any>([]);
  const [errors, setErrors] = useState({
    property: "",
    unit: "",
    type: "",
    status: "",
    priority: "",
    description: "",
  });

  useEffect(() => {
    if (
      props.helpDeskdata &&
      propertyList.length &&
      typeList.length &&
      unitList.length
    ) {
      setLabel("Edit");
      setBtnLabel("Update");
      fetchHelpDeskById(props.helpDeskdata.id);
    }
  }, [props.helpDeskdata, propertyList, typeList, unitList]);
  const fetchProperty = async () => {
    let response: any;
    try {
      response = await PostApiMethod("property/properties/list", {});
      if (response["data"]["error"] === false) {
        setPropertyList(response.data.results.data);
        setLoading(false);
      } else {
        console.log("response", response);
        setLoading(false);
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };
  const fetchType = async () => {
    let response: any;
    try {
      response = await PostApiMethod("helpDeskType/list", {});
      if (response["data"]["error"] === false) {
        setTypeList(response.data.results.data);
        setLoading(false);
      } else {
        console.log("response", response);
        setLoading(false);
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };
  const fetchUnit = async () => {
    let response: any;
    try {
      response = await PostApiMethod("property/unit/list", {});
      if (response["data"]["error"] === false) {
        setUnitList(response.data.results.data);
        setLoading(false);
      } else {
        console.log("response", response);
        setLoading(false);
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };
  useEffect(() => {
    fetchProperty();
    fetchUnit();
    fetchType();
  }, []);

  const handleChange = (name: any, value: any) => {
    setState({ ...state, [name]: value });
  };

  const handleImageChange = (e: any) => {
    // let tmpUpload = [...uploadData]
    // tmpUpload.push(e.target.files)
    // setUploadData(tmpUpload)
    setState({ ...state, attachement: e.target.files });
  };

  const validation = useCallback(
    (e: any) => {
      e.preventDefault();
      let tState: any = { ...state };
      if (Object.keys(tState.property_id).length === 0) {
        setErrors((prev) => ({ ...prev, property: "Property is required" }));
      } else {
        setErrors((prev) => ({ ...prev, property: "" }));
      }
      if (Object.keys(tState.unit_id).length === 0) {
        setErrors((prev) => ({ ...prev, unit: "Unit is required" }));
      } else {
        setErrors((prev) => ({ ...prev, unit: "" }));
      }
      if (Object.keys(tState.type_id).length === 0) {
        setErrors((prev) => ({ ...prev, type: "Type is required" }));
      } else {
        setErrors((prev) => ({ ...prev, type: "" }));
      }
      if (Object.keys(tState.status).length === 0) {
        setErrors((prev) => ({ ...prev, status: "Status is required" }));
      } else {
        setErrors((prev) => ({ ...prev, status: "" }));
      }
      if (Object.keys(tState.priority).length === 0) {
        setErrors((prev) => ({ ...prev, priority: "Priority is required" }));
      } else {
        setErrors((prev) => ({ ...prev, priority: "" }));
      }
      if (
        errors.property === "" &&
        errors.unit === "" &&
        errors.type === "" &&
        errors.status === "" &&
        errors.priority === ""
      ) {
        onSubmit(e);
      }
    },
    [state, errors]
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(showLoader({ showLoader: true }));

    // form.append()
    const form = new FormData();
    let tState: any = { ...state };
    console.log("tState", tState);
    Object.keys(state).map((val: any, key) => {
      if (val !== "attachement") {
        if (val !== "description" && val !== "id" && val !== "userId") {
          form.append(val, tState[val].id);
        } else {
          form.append(val, tState[val]);
        }
      } else {
        Object.keys(tState[val]).forEach((data: any) => {
          form.append("attachement[]", tState[val][data]);
        });
      }
    });
    let res;
    try {
      if (isNotEmpty(tState.id)) {
        // res = await PutApiMethod('property/help-desk/' + tState.id, form)
        res = await PutApiMethod("property/help-desk/" + state.id, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        res = await PostApiMethod("property/help-desk/add", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      if (res && res["data"]["code"] === 201) {
        console.log("success");
        setState(getInitialState);
        navigate("/helpDesk");
        props.pageStatus("listHelpDesk");
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: "Tickets Created successfully",
            showDialog: true,
            timer: "3000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      } else {
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: res.data.message,
            showDialog: true,
            timer: "5000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      }
    } catch (err: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    props.parentData(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    props.parentData(event.target.value);
    props.limit(parseInt(event.target.value, 10));
  };
  const fetchHelpDeskById = async (id: any) => {
    dispatch(showLoader({ showLoader: true }));
    try {
      let response = await GetApiMethod("property/help-desk/" + id);
      if (response && response["data"]["error"] === false) {
        let tmpData = response.data.results.data;
        let tmpstate = { ...state };
        tmpstate.property_id = propertyList.find(
          (data: any) => data.id == tmpData.propertyId
        );
        tmpstate.unit_id =
          tmpData.unitId &&
          unitList.find((data: any) => data.id == tmpData.unitId);
        tmpstate.type_id = typeList.find(
          (data: any) => data.id == tmpData.typeId
        );
        tmpstate.status = statusList.find(
          (data: any) => data.id == tmpData.status
        );
        tmpstate.priority = priorityList.find(
          (data: any) => data.id == tmpData.priority
        );
        tmpstate.description = tmpData.description;
        tmpstate.id = tmpData.id;
        setState(tmpstate);
        setLoading(false);
        dispatch(showLoader({ showLoader: false }));
      } else {
        console.log("response", response);
        setLoading(false);
      }
    } catch (e) {
      dispatch(showLoader({ showLoader: false }));
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [input, setInput] = useState("");
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Box className="card-title">
          <Typography>Create New Ticket</Typography>
        </Box>
        <form onSubmit={validation}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Property</Typography>
              <PropertySearchComponent
                onChange={(val: PropertyDetailDTO) => {
                  handleChange("property_id", val ? val.id : "");
                }}
              />
              {errors.property && (
                <Typography className="text-danger">
                  {errors.property}
                </Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Unit</Typography>
              <Autocomplete
                id="size-small-standard"
                size="small"
                options={unitList && unitList}
                getOptionLabel={(option: any) => option.unit_name.toString()}
                onChange={(event: any, newValue: any) => {
                  newValue !== null
                    ? handleChange("unit_id", newValue)
                    : handleChange("unit_id", {});
                }}
                inputValue={
                  Object.keys(state["unit_id"]).length
                    ? state["unit_id"].unit_name
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    // label="Property"
                    placeholder="Choose"
                  />
                )}
              />
              {errors.unit && (
                <Typography className="text-danger">{errors.unit}</Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Type</Typography>
              <Autocomplete
                id="size-small-standard"
                size="small"
                options={typeList}
                getOptionLabel={(option: any) => option.name.toString()}
                onChange={(event: any, newValue: any) => {
                  newValue !== null
                    ? handleChange("type_id", newValue)
                    : handleChange("type_id", {});
                }}
                inputValue={
                  Object.keys(state["type_id"]).length
                    ? state["type_id"].name
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    // label="Property"
                    placeholder="Choose"
                  />
                )}
              />
              {errors.type && (
                <Typography className="text-danger">{errors.type}</Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Status</Typography>
              <Autocomplete
                id="size-small-standard"
                size="small"
                options={statusList}
                getOptionLabel={(option: any) => option.name.toString()}
                onChange={(event: any, newValue: any) => {
                  newValue !== null
                    ? handleChange("status", newValue)
                    : handleChange("status", {});
                }}
                // inputValue={
                //   Object.keys(state["status"]).length
                //     ? state["status"].name
                //     : ""
                // }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    // label="Property"
                    placeholder="Choose"
                  />
                )}
              />
              {errors.status && (
                <Typography className="text-danger">{errors.status}</Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Priority</Typography>
              <Autocomplete
                id="size-small-standard"
                size="small"
                options={priorityList}
                getOptionLabel={(option: any) => option.name.toString()}
                onChange={(event: any, newValue: any) => {
                  newValue !== null
                    ? handleChange("priority", newValue)
                    : handleChange("priority", {});
                }}
                inputValue={
                  Object.keys(state["priority"]).length
                    ? state["priority"].name
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    // label="Property"
                    placeholder="Choose"
                  />
                )}
              />
              {errors.priority && (
                <Typography className="text-danger">
                  {errors.priority}
                </Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Attachement</Typography>
              {/* <Upload files={upData} setfiles={setUpData} /> */}
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={handleImageChange}
              >
                Upload file
                <VisuallyHiddenInput type="file" multiple />
              </Button>
              {/* <TextField type="file" variant='standard' placeholder=' here...' className='form-control' name="image" onChange={handleImageChange}/> */}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Typography className="poppins-family">Description</Typography>
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here..."
                className="form-control"
                name="description"
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                value={state["description"]}
              />
            </Grid>
            {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>Status</Typography>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="status"
                                defaultValue={state["status"]}
                                onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Active" />
                                <FormControlLabel value={false} control={<Radio />} label="InActive" />
                            </RadioGroup>
                        </Grid> */}
          </Grid>
          <Box sx={{ mt: 4 }} className="d-flex">
            <Button
              className="btn-white btn-back-default"
              onClick={(e) => {
                props.pageStatus("listHelpDesk");
              }}
            >
              <ChevronLeftIcon className="fs-20" />
              Back
            </Button>
            <Button
              className="btn-danger ms-2"
              type="submit"
              disabled={loading}
            >
              {btnLabel} Request
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddHelpDesk;
