import React from 'react';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';

const UserTable = () => {
    return (
        <AuthorizedLayout>
            <p>Home page</p>
        </AuthorizedLayout>
    );
};
export default UserTable;
