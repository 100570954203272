import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Actions from "../../Components/Actions/Actions";
import { formatDate } from "../../Components/Utilities";
import Pagination from "../../Components/Pagination/Pagination";

interface InvoiceListProps {
  listValues: any;
  rowsPerPage: (val: any) => void;
  page: { page: number; limit: number; count: number };
  deleteInvoice: (id: string) => void;
  editInvoice: (val: any) => void;
}

export const InvoiceList = (props: InvoiceListProps) => {
  const { listValues, rowsPerPage, page, deleteInvoice, editInvoice } = props;
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Month</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listValues.map((val: any, key: any) => {
              return (
                <TableRow key={key}>
                  <TableCell>{val.invoice_no}</TableCell>
                  <TableCell>{val.property.property_name}</TableCell>
                  <TableCell>{val.unit?.unit_name}</TableCell>
                  <TableCell>{val.month}</TableCell>
                  <TableCell>{formatDate(val.due_date)}</TableCell>
                  <TableCell>
                    {val.type === "Normal" ? (
                      val.status === "Paid" ? (
                        <Typography className="text-success poppins-family d-flex align-items-center">
                          {val.status}
                        </Typography>
                      ) : (
                        <Typography className="text-danger poppins-family d-flex align-items-center">
                          {val.status}
                        </Typography>
                      )
                    ) : val.recurring_status === "Active" ? (
                      <Typography className="text-success poppins-family d-flex align-items-center">
                        {val.recurring_status}
                      </Typography>
                    ) : (
                      <Typography className="text-danger poppins-family d-flex align-items-center">
                        {val.recurring_status}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Actions
                      name={"More"}
                      data={[
                        {
                          name: "Edit",
                          color: "text-primary",
                          onClick: () => {
                            editInvoice(val);
                          },
                        },
                        {
                          name: "Delete",
                          color: "text-danger",
                          onClick: () => {
                            deleteInvoice(val.id);
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            {props.listValues.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <Pagination pagination={page} rowsPerPageChange={rowsPerPage} />
        </Table>
      </TableContainer>
    </>
  );
};
