import { Typography } from "@mui/material";
import { useEffect } from "react";

const FormTitle = (props: { text: string, extenalCss?: string}) => {

    useEffect(() => {} ,[]);
    
  return (
    <>
      <Typography className={`poppins-family ${props.extenalCss && props.extenalCss}`}>{props.text}</Typography>
    </>
  );
};

export default FormTitle;
