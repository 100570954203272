import * as Yup from "yup";

export const NewPropertyValidation = Yup.object().shape({
    property_type:Yup.string().required("Property type is required"),
    property_name:Yup.string().required("Property name is required"),
    number_of_unit:Yup.string().required("Number of unit is required"),
    description:Yup.string().required("Description field is required")
});

export const LocationValidation = Yup.object().shape({
    country_id:Yup.string().required("Country field is required"),
    state_id:Yup.string().required("State field is required"),
    city_id:Yup.string().required("City field is required"),
    zip_code:Yup.string().required("Zip code field is required"),
    address:Yup.string().required("Address field is required"),
    // map_link:Yup.string().required("Map field is required"),
})

export const UnitFormValidation = Yup.object().shape({
    units: Yup.array().of(
        Yup.object().shape({
          unit_name: Yup.string().required("Unit name is required"),
          bedroom: Yup.string().required("Bedroom is required"),
          bath: Yup.string().required("Bathroom is required"),
          kitchen: Yup.string().required("Kitchen is required"),
          square_feet: Yup.string().required("Square Feet is required"),
          amenities: Yup.string().required("Amenities is required"),
          condition: Yup.string().required("Condition is required"),
          parking: Yup.string().required("Parking is required"),
          description:Yup.string().required("Description is required"),
          image: Yup
            .mixed()
            .required("Image field is required")
            // .test('fileType', 'Only PNG and JPEG files are allowed', (value) => {
            //     if (!value) return true;
            //     return (
            //     value && ['image/png', 'image/jpeg'].includes(value.type)
            //     );
            // }),
        })
    ),
});

export const RentFormValidation = Yup.object().shape({
    rents:Yup.array().of(
        Yup.object().shape({
            unit_name:Yup.string().required("Unit name is required"),
            general_rent:Yup.string().required("General rent is required"),
            security_deposit:Yup.string().required("Security deposit is required"),
            late_fee:Yup.string().required("Late fee is required"),
            incident_receipt:Yup.string().required("Incident receipt is required"),
            rent_type:Yup.string().required("Rent type field is required"),
            monthly_due_date:Yup.string().when('rent_type',{
                is:'Monthly',
                then:(schema) =>
                    schema.required("Monthly due field is required"),
                otherwise:(schema) => 
                    schema.notRequired(),
            }),
            yearly_due_date:Yup.string().when('rent_type',{
                is:'Yearly',
                then:(schema) =>
                    schema.required("Yearly due field is required"),
                otherwise:(schema) => 
                    schema.notRequired(),
            }),
            lease_start_date:Yup.string().when('rent_type',{
                is:'Custom',
                then:(schema) =>
                    schema.required("Lease start date field is required"),
                otherwise:(schema) => 
                    schema.notRequired(),
            }),
            lease_end_date:Yup.string().when('rent_type',{
                is:'Custom',
                then:(schema) =>
                    schema.required("Lease end date field is required"),
                otherwise:(schema) => 
                    schema.notRequired(),
            }),
            lease_payment_due_date:Yup.string().when('rent_type',{
                is:'Custom',
                then:(schema) =>
                    schema.required("Lease payment due date field is required"),
                otherwise:(schema) => 
                    schema.notRequired(),
            })
        })
    )
});

export const ImageValidation = Yup.object().shape({
    'image[0]':Yup.string().required("Image field is required")
})

