import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import FormTitle from "../../Components/FormTitle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { addTenantSchema } from "../../Components/Validation";
import { useFormik } from "formik";
import { PutApiMethod } from "../../Utils/ApiService";
import { showLoader } from "../../Store/loaderSlice";
import { errorHandling } from "../../Utils/HelperService";
import { showToaster } from "../../Store/toastSlice"; 

const EditTenant = (props: any) => {  
  const loader = useAppSelector((state) => state.loader);

  const [initialValue, setInitialValiue] = useState({
    id: "",
    email: "",
    familyMember: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    job: "",
    age: "",
  });
  const dispatch = useAppDispatch();

  const submit = useCallback(
    async (value: any) => {
      dispatch(showLoader({ showLoader: true }));
      const param = {
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        phoneNumber: value.phoneNumber,
        job: value.job,
        age: value.age,
        familyMember: value.familyMember,
      };
      await PutApiMethod("user/" + initialValue.id, param)
        .then((res) => {
          props.clearTenantData();
          dispatch(showLoader({ showLoader: false }));
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Updated successfully",
              showDialog: true,
              timer: "5000",
            })
          );
          props.pageStatus("listTenant");

        })
        .catch((err) => {
          const error = errorHandling(err);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    },
    [initialValue.id]
  );

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: addTenantSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      submit(values);
    },
  });
  useEffect(() => {
    if (props.tenantdata) {
      setInitialValiue(props.tenantdata);
    }
  }, [props.tenantdata]);
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <FormTitle
          text="Update Tenant Information"
          extenalCss="subtitle-color"
        />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item lg={12} md={4} sm={12} xs={12}>
              <FormTitle text="Personal Information" />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="First Name" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here"
                className="form-control"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
              />
              {errors.firstName && touched.firstName && (
                <Typography className="text-danger">
                  {errors.firstName}
                </Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Last Name" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here"
                className="form-control"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
              />
              {errors.lastName && touched.lastName && (
                <Typography className="text-danger">
                  {errors.lastName}
                </Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text=" Contact Number" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here"
                className="form-control"
                name="phoneNumber"
                onChange={handleChange}
                value={values.phoneNumber}
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <Typography className="text-danger">
                  {errors.phoneNumber}
                </Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Job" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here"
                className="form-control"
                name="job"
                onChange={handleChange}
                value={values.job}
              />
              {errors.job && touched.job && (
                <Typography className="text-danger">{errors.job}</Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Age" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here"
                className="form-control"
                name="age"
                onChange={handleChange}
                value={values.age}
              />
              {errors.age && touched.age && (
                <Typography className="text-danger">{errors.age}</Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text=" Family Members" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here"
                className="form-control"
                name="familyMember"
                onChange={handleChange}
                value={values.familyMember}
              />
              {errors.familyMember && touched.familyMember && (
                <Typography className="text-danger">
                  {errors.familyMember}
                </Typography>
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormTitle text="Email" />
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here"
                className="form-control"
                name="email"
                onChange={handleChange}
                value={values.email}
              />
              {errors.email && touched.email && (
                <Typography className="text-danger">{errors.email}</Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }} className="d-flex">
            <Button
              className="btn-white"
              onClick={() => {
                props.pageStatus("listTenant");
              }}
            >
              <ChevronLeftIcon className="fs-20" />
              Back
            </Button>
            <Button
              className="btn-danger ms-2"
              type="submit"
              disabled={loader.showLoader}
            >
              Save & Proceed Next
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditTenant;
