import axios from 'axios';

export const userLoginFunc = (email: string,password: string) => {
    const apiBases = process.env.REACT_APP_API_URL;

    return axios
        .post(apiBases + "/admin/auth/login",{
            email,
            password,
        })
        .then((response: any) => {
            if (response.data.accessToken) {
                localStorage.setItem('accessToken',response.data.accessToken);
            }
            return response.data;
        });
};

export const emailCredentials = (email: string) => {
    return email.trim();
};

export const isLogin = () => {
    //if (localStorage.getItem('accessToken')) {
    const loginCheck = localStorage.getItem("login");
    if (loginCheck && loginCheck === 'true') {  // Need to change the key
        return true;
    }

    return false;
}