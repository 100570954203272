import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Box,
  Button,
} from "@mui/material";
import BasicDatePicker from "../property/components/tenantdatePicker";
import unitImage from "../../Assets/images/unit-default-image.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  addTenantPropertySchema,
  addTenantSchema,
} from "../../Components/Validation";
import { useFormik } from "formik";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useCallback, useState , useEffect} from "react";
import { PutApiMethod } from "../../Utils/ApiService";
import { errorHandling } from "../../Utils/HelperService";
import { useAppDispatch } from "../../Store/hooks";
import { showToaster } from "../../Store/toastSlice";
import { showLoader } from "../../Store/loaderSlice";

interface TenantHomeDetailsProps {
  propertyList: any;
  handleHomeDetailsChange: (e: any) => void;
  handletenantHomeDetailsChange: (e: any, data: any) => void;
  filterUnitList: any;
  state: any;
  pageStatus: (e: any) => void;
  handleUnitChange: (e: any) => void;
  handleDateChange: (
    InputValue: any,
    inputName: string,
    currentIndex: number
  ) => void;
  unitDetailsShow: any;
  homeUnitDetails: any;
  tenantID: any;
  allData:any;
  setFinalData:any;
  setUnitId: React.Dispatch<React.SetStateAction<string>>;
  setPropertyId: React.Dispatch<React.SetStateAction<string>>;
  finalData:any;
}

const TenantHomeDetails = (props: TenantHomeDetailsProps) => {
  const {
    propertyList,
    handleHomeDetailsChange,
    handletenantHomeDetailsChange,
    filterUnitList,
    pageStatus,
    handleUnitChange,
    handleDateChange,
    unitDetailsShow,
    homeUnitDetails,
    tenantID,
    setUnitId,
    setPropertyId,
    allData,
    setFinalData,
    finalData
  } = props;
  const [state, setState] = useState<any>({
    leaseStartDate: "",
    leaseEndDate: "",
    leaseReminderDays: "",
    propertyId: {},
    unitId: {},
    general_rent: "",
    security_deposit: "",
    late_fee: "",
    incident_receipt: "",
  });
  const dispatch = useAppDispatch();
  const submit = useCallback(async (values: any) => {
    try {
    const param = {
      ...allData,
      leaseStartDate: values.leaseStartDate,
      leaseEndDate: values.leaseEndDate,
      leaseReminderDays: values.leaseReminderDays,
      propertyId: values.propertyId,
      unitId: values.unitId,
      general_rent: values.general_rent,
      security_deposit: values.security_deposit,
      late_fee: values.late_fee,
      incident_receipt: values.incident_receipt,
    };
    setFinalData(param)
    dispatch(showLoader({ showLoader: true }));
      // const res = await PutApiMethod("user/" + tenantID, param);
      setUnitId(values.unitId.id);
      setPropertyId(values.propertyId.id);
      pageStatus("documents");
    } catch (e) {
      console.log(e)
      const error = errorHandling(e);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
    } finally {
      dispatch(showLoader({ showLoader: false }));
    }
  }, []);
  const { values, handleChange, handleSubmit, errors, touched, setFieldValue,resetForm } =
    useFormik({
      initialValues:finalData ? finalData : state,
      validationSchema: addTenantPropertySchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        // handlFormSubmit(values);
        submit(values);
      },
    });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item lg={12} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">Home Details</Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">Property</Typography>
            <Autocomplete
              id="size-small-standard"
              size="small"
              options={propertyList}
              // name={"propertyId"}
              getOptionLabel={(option: any) => option.property_name.toString()}
              onChange={(event: any, newValue: any) => {
                if (newValue !== null) {
                  handletenantHomeDetailsChange("property_id", newValue);
                } else {
                  handletenantHomeDetailsChange("property_id", {});
                }

                setFieldValue('propertyId', newValue);

              }}
              // value={values.propertyId}
              inputValue={
                Object.keys(values["propertyId"]).length
                  ? values["propertyId"].property_name
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name={"propertyId"}
                  // label="Property"
                  placeholder="Choose"
                />
              )}
            />

            {errors.propertyId && touched.propertyId && (
              <Typography className="text-danger">
                Property is required
              </Typography>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">Unit Name</Typography>
            <Autocomplete
              id="size-small-standard"
              size="small"
              options={filterUnitList}
              getOptionLabel={(option: any) => option.unit_name.toString()}
              onChange={(event: any, newValue: any) => {
                if (newValue !== null) {
                  handletenantHomeDetailsChange("unit_id", newValue);
                  handleUnitChange(newValue);
                } else {
                  handletenantHomeDetailsChange("unit_id", {});
                }
                setFieldValue('unitId', newValue);

              }}
              // value={options && options.find((option:any) => option.id === state["unit_id"])}
              inputValue={
                Object.keys(values["unitId"]).length
                  ? values["unitId"].unit_name
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  // label="Property"
                  placeholder="Choose"
                />
              )}
            />
            {errors.unitId && touched.unitId && (
              <Typography className="text-danger">Unit is required</Typography>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">Lease Start Date</Typography>
            <BasicDatePicker
              //  label={"Lease End Date"}
              name={"leaseStartDate"}
              val={values.leaseStartDate}
              // valueChange={handleDateChange}
              valueChange={(  InputValue: any,
                inputName: string,
                currentIndex: number)=> {
                  setFieldValue('leaseStartDate', InputValue);
                }}
              // onChange={(date) => setFieldValue('date', date)}
              variant="standard"
            />
            {errors.leaseStartDate && touched.leaseStartDate && (
              <Typography className="text-danger">
                {errors.leaseStartDate.toString()}
              </Typography>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">Lease End Date</Typography>
            <BasicDatePicker
              //  label={"Lease End Date"}
              name={"leaseEndDate"}
              val={values.leaseEndDate}
              // valueChange={handleDateChange}
              valueChange={(  InputValue: any,
                inputName: string,
                currentIndex: number)=> {
                  setFieldValue('leaseEndDate', InputValue);
                }}
              variant="standard"
            />
            {errors.leaseEndDate && touched.leaseEndDate && (
              <Typography className="text-danger">
                {errors.leaseEndDate.toString()}
              </Typography>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">
              Lease Renewal Reminder (No. of days before end date)
            </Typography>
            <TextField
              type="number"
              variant="standard"
              placeholder="Type here..."
              className="form-control"
              name="leaseReminderDays"
              onChange={handleChange}
              value={values["leaseReminderDays"]}
            />
            {errors.leaseReminderDays && touched.leaseReminderDays && (
              <Typography className="text-danger">
                {errors.leaseReminderDays.toString()}
              </Typography>
            )}
          </Grid>
        </Grid>
        {unitDetailsShow ? (
          <>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item lg={12}>
                <div className="horizontal-card">
                  <div className="card">
                    <img
                      src={unitImage}
                      alt="FBP"
                      style={{
                        margin: "5px",
                        borderRadius: "8px",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                    <div className="card-content">
                      <p className="psemiboldfont fs-16">
                        {values.unitId.unit_name}
                      </p>
                      <div className="row-view">
                        <LocationOnIcon className="icon-primary-color" />
                        <p className="tab-menu">
                          {values.propertyId.property_name},
                          {values.propertyId.city_id}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Add more cards as needed */}
                </div>
                {/* </Typography> */}
              </Grid>
              <Grid item lg={12}>
                <Typography className="poppins-family" sx={{ mt: 3 }}>
                  Rental Information
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">General Rent</Typography>
                <TextField
                  type="number"
                  variant="standard"
                  placeholder="Type here..."
                  className="form-control"
                  name="general_rent"
                  onChange={handleChange}
                  value={values["general_rent"]}
                />
                {errors.general_rent && touched.general_rent && (
              <Typography className="text-danger">
                {errors.general_rent.toString()}
              </Typography>
            )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">
                  Security Deposit
                </Typography>
                <TextField
                  type="number"
                  variant="standard"
                  placeholder="Type here..."
                  className="form-control"
                  name="security_deposit"
                  onChange={handleChange}
                  value={values["security_deposit"]}
                />
                {errors.security_deposit && touched.security_deposit && (
                  <Typography className="text-danger">
                    {errors.security_deposit.toString()}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">Late Fee</Typography>
                <TextField
                  type="number"
                  variant="standard"
                  placeholder="Type here..."
                  className="form-control"
                  name="late_fee"
                  onChange={handleChange}
                  value={values["late_fee"]}
                />
                {errors.late_fee && touched.late_fee && (
                  <Typography className="text-danger">
                    {errors.late_fee.toString()}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">
                  Incident Receipt{" "}
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Type here..."
                  className="form-control"
                  name="incident_receipt"
                  onChange={handleChange}
                  value={values["incident_receipt"]}
                />
                {errors.incident_receipt && touched.incident_receipt && (
                  <Typography className="text-danger">
                    {errors.incident_receipt.toString()}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
        <Box sx={{ mt: 4 }} className="d-flex">
          <Button
            className="btn-white"
            onClick={() => {
              pageStatus("tenantInformation");
            }}
          >
            <ChevronLeftIcon className="fs-20" />
            Back
          </Button>
          <Button
            className="btn-danger ms-2"
            type="submit"
            disabled={state.showLoader}
          >
            Save & Proceed Next
          </Button>
        </Box>
      </form>
    </>
  );
};

export default TenantHomeDetails;
