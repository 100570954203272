import React, { useState, useRef, useEffect, useContext } from 'react';
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import { VisitorContext } from './Visitor';
import Modal from '@mui/material/Modal';
import { Box, Typography } from '@mui/material';
import { ModalStyle } from '../../Components/ModalStyle';
import CloseIcon from '@mui/icons-material/Close';
import { convertToAmPm } from '../../Components/CommonFormik';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './Calendar.css'

const VisitorCalendar = () => {
  const d = new Date();
  const visitor = useContext(VisitorContext);
  const [open, setOpen] = useState(false);
  const [visitDetails, setVisitDetails] = useState(null);
  const [selectDay, setSelectDay] = useState(new Date());

  const editEvent = async (e) => {
    setOpen(true);
    setVisitDetails(e.data)
  };

  

  const nextWeek = async() => {
    let date = new Date(selectDay)
    date.setDate(date.getDate() + 7);
    setSelectDay(date);
    let month = (date.getMonth()+1) < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
    let day = date.getDate() < 10 ? "0"+(date.getDate()) : date.getDate();
    date = date.getFullYear()+"-"+month+"-"+day
    visitor.getWeekList(date);
  }

  const previousWeek = async() => {
    let date = new Date(selectDay)
    date.setDate(date.getDate() - 7);
    setSelectDay(date);
    let month = (date.getMonth()+1) < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
    let day = date.getDate() < 10 ? "0"+(date.getDate()) : date.getDate();
    date = date.getFullYear()+"-"+month+"-"+day
    visitor.getWeekList(date);
  }

  const today = () => {
    let date = new Date();
    setSelectDay(date);
    visitor.getWeekList(date)
  }

  const [config, setConfig] = useState({
    viewType: "Week",
    durationBarVisible: true,
    timeRangeSelectedHandling: "Enabled",
    headerDateFormat: "dd/MM/yyyy",
    onEventClick: async args => {
      await editEvent(args.e);
    },
    onBeforeHeaderRender: args => {
      const today = new DayPilot.Date().getDatePart();
      if(args.column.start.equals(today)){
        args.header.backColor = "#6ea8fe";
        args.header.fontColor = "#ffffff";
      }
    },
    onBeforeCellRender: (args) => {
      const today = new DayPilot.Date().getDatePart();

      if (args.cell.start.equals(today)) {
        args.cell.cssClass = "current-date";
      }
    }
  });

  const dateChanged = (args) => {
    const date = args.day.value;
    
    if (new Date(date).getTime() !== new Date(selectDay).getTime()) {
      setSelectDay(date);
      
      if (visitor.calendarRef.current) {
        visitor.calendarRef.current.control.update({
          startDate: args.day
        });
      }
      let newDate = new Date(date);
      let month = (newDate.getMonth()+1) < 10 ? "0"+(newDate.getMonth()+1) : newDate.getMonth()+1;
      let day = newDate.getDate() < 10 ? "0"+(newDate.getDate()) : newDate.getDate();
      newDate = newDate.getFullYear()+"-"+month+"-"+day 
      console.log(newDate)
      visitor.getWeekList(newDate);
    }
  };

  return (
    <>
      <Box >
        <button className='btn btn-danger me-2' onClick={previousWeek}><ChevronLeftIcon /></button>
        <button className="btn btn-danger" onClick={today}>Today</button>
        <button className="btn btn-danger ms-2" onClick={nextWeek}><ChevronRightIcon /></button>
      </Box>
      <div className='mt-2 d-flex'>
        <DayPilotCalendar {...config} ref={visitor.calendarRef} events={visitor.weekList} />
        <DayPilotNavigator
          selectMode={"Week"}
          showMonths={1}
          startDate={selectDay}
          selectionDay={selectDay}
          onTimeRangeSelected={dateChanged}
        />
      </div>
      
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={ModalStyle({width:400,height:350})} className="p-3">
          <Box className="border-bottom d-flex justify-content-between">
            <h5>Visit Schedule</h5>
            <CloseIcon sx={{cursor:'pointer'}} onClick={() => setOpen(false)}/>
          </Box>
          <Box className="d-flex justify-content-between mt-1">
            <Box>
              <Typography component="div" className="fw-bold">Date</Typography>
              <Typography component="div">{visitDetails && visitDetails.start.value.split("T")[0]}</Typography>
            </Box>
            <Box>
              <Typography component="div" className="fw-bold">Timing</Typography>
              <Typography component="div">{visitDetails && convertToAmPm(visitDetails.start.value.split("T")[1]) +"-"+ convertToAmPm(visitDetails.end.value.split("T")[1])}</Typography>
            </Box>
          </Box>
            <table className='table table-striped mt-1'>
              <tbody>
                
                {(visitDetails && visitDetails.rooms.search(",") !== -1) 
                  ? visitDetails.rooms.split(",").map((val,key) => {
                    return (
                      <tr>
                        <td key={key}>{val}</td>
                      </tr>
                    )
                  }) : <tr><td>{visitDetails && visitDetails.rooms}</td></tr>}
                
              </tbody>
            </table>
        </Box>
      </Modal>
    </>
  )
}

export default VisitorCalendar