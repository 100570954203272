import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import { Pagination, TextField, Typography } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import  { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import AddVendors from './AddVendors';
import ListVendors from './ListVendors';
import { PostApiMethod } from '../../Utils/ApiService';
import { isNotEmpty } from '../../Utils/HelperService';
import { Button } from '@mui/base';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';

const AntTabs = styled(Tabs)({
    marginLeft: '15px',
    '& .MuiTabs-indicator': {
        backgroundColor: '#FF7D73',
    },
});

const AntTab = styled(Tab)({
    fontSize: "13px",
    fontFamily: "Poppins",
    '&.Mui-selected': {
        color: '#FF7d73'
    }
})
const Vendors = () => {
    const [tabValue, setTabValue] = useState(0);
    const [vendor, setVendor] = useState('');
    const [addNew, setAddNew] = useState({ listVendors: true,addVendors: false, addRequest: false });
    const [loading, setLoading] = useState(false);
    const [vendorsList, setVendorsList] = useState([]);
    const [vendorsCount, setVendorsCount] = useState(0);
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')

    // const handleChange = (e: any, val: any) => {
    //     setTabValue(val)
    //     setAddNew({ addVendors: false, addRequest: false })

    // }

    const handleVendor = (e: SelectChangeEvent) => {
        setVendor(e.target.value)
    }

    // useEffect(() => {
    //     fetchVendorsList();
    // }, [])
    const pagination = (e: any) => {
        setPage(e+1);
        // setLimit();
    }
    const rowsPerPage = (e: any) => {
        console.log("e",e)
        setLimit(e);
        // setLimit();
    }
    useEffect(() => {
        fetchVendorsSearchList();
    },[page])
    const fetchVendorsSearchList = async (search:any = "") => {
        setLoading(true)
        let formdata = {
            role: 'PMA',
            page: page,
            limit: limit,
            searchString: search
        }
        let response = await PostApiMethod('user/getUser', formdata)
        if (response["data"]["error"] === false) {
            setVendorsList(response.data.results.data)
            setVendorsCount(response.data.results.totalCount)
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    const handleSearchChange =(e:any) =>{
        if(e !== ""){
        setSearch(e);
        }else{
            fetchVendorsSearchList();
        }
    }
    const handleSearchClick =() =>{
        fetchVendorsSearchList(search)
    }
    return (
        <>
            <AuthorizedLayout>
                <Card variant="outlined" sx={{ borderRadius: '8px' }} className="border-0">
                    <CardContent>
                        <Box className="d-flex justify-content-between align-items-center">
                            <Box className="d-flex align-items-center">
                                <EngineeringIcon className='text-danger fs-20' />
                                <Typography className="poppins-family fw-bold">Vendors</Typography>
                                {/* <AntTabs value={tabValue} onChange={handleChange}>
                                    <AntTab label="All Vendors" />
                                    {/* <AntTab label="Maintenance Request" /> *}
                                </AntTabs> */}
                            </Box>
                            {addNew['listVendors'] ?
                            <Box>
                                <button className='btn-danger' onClick={() => setAddNew({ ...addNew, addVendors: !addNew['addVendors'],listVendors: !addNew['listVendors'] })}><AddIcon className='fs-23' />Add New Vendor</button>
                            </Box>
                            :
                            <Box>
                                <button className='btn-danger' onClick={() => setAddNew({ ...addNew, addVendors: !addNew['addVendors'],listVendors: !addNew['listVendors'] })}><ArrowBackTwoToneIcon className='fs-23' />Back</button>
                            </Box>
                            }
                        </Box>
                        {!Object.values(addNew).includes(true) && <Box sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item lg={2} md={6} xs={12} sm={12}>
                                    <FormControl variant="standard" className="w-100">
                                        <TextField type="text" variant='standard' placeholder='Search here...' className='form-control' name="search" onChange={(e)=>{handleSearchChange(e.target.value)}}/>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={2} md={6} xs={12} sm={12}>
                                        <Button className='btn-danger' type="button" onClick={()=>{handleSearchClick()}}>Search</Button>
                                </Grid>
                            </Grid>
                        </Box>}
                        <Box>
                            <CustomTabPanel value={tabValue} index={0} >
                                {addNew['listVendors'] ?
                                <ListVendors />
                                :addNew['addVendors'] ? 
                                <AddVendors addVendors={addNew["addVendors"]} vendorsList={vendorsList} vendorsCount={vendorsCount} parentData={pagination} limit={rowsPerPage}/>
                                : ""}
                            </CustomTabPanel>
                        </Box>
                    </CardContent>
                </Card>
            </AuthorizedLayout>
        </>
    )
}

export default Vendors