import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./datePicker.module.css";

interface BasicDatePickerProps {
//   label: string;
  name: string;
  val?: string;
  valueChange: Function;
  index?: number;
  variant?:string
}
export default function BasicDatePicker(props: BasicDatePickerProps) {
  const [DatePick, setDatePick] = useState<Dayjs | null>();
  const [DateSelect, setDateSelect] = useState();
  function setvalues(date: any) {
    // setDatePick(date);
    setDateSelect(date);
    props.valueChange(date, props.name);
  }
  const calendarSx = {
    "& .MuiPickersDay-dayWithMargin": {
      borderRadius: "0",
      outline: "1px solid black",
      margin: "0px 1px 0 0"
    },
    "& .MuiDayPicker-weekContainer": { margin: "1px" }
  };
  return (
    <>
      <div className={styles.box}>
        {/* <label className="">{props.label}</label> */}
        <div className={styles.datePickBox}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DemoContainer components={["DatePicker"]}> */}
            <DatePicker
              label=""
              value={DatePick}
              onChange={(newVal) => setvalues(newVal)}
              defaultValue={dayjs(props.val)} 
              slotProps={{ textField: { variant: "standard" } }}
            />
            {/* </DemoContainer> */}
          </LocalizationProvider>
        </div>
      </div>
    </>
  );
}
