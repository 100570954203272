import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height:150,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

const DeleteDialog = (props) => {
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" className="d-flex justify-content-between align-items-center p-2" component="h2" sx={{borderBottom:'1px solid gray'}}>
                        Error!<DisabledByDefaultIcon sx={{cursor:'pointer'}} onClick={props.handleClose} />
                    </Typography>
                    <Typography id="modal-modal-description" className="p-2">
                        {props.message && props.message.message}
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}

export default DeleteDialog