import { makeStyles, CSSProperties } from "@mui/styles";

// const generateBorderRadius = (
//     start = 0,
//     end = 100,
//     increament = 1,
//     unit = 'px',
// ) => {
//     let classList = {};

//     for (let i = start; i <= end; i += increament) {
//         classList[.radius - ${ i }] = {
//             borderRadius: ${ i }${ unit } !important,
//     };
//     }

//     return classList;
// };

const generateBorderRadius = (
  start = 0,
  end = 100,
  increment = 1,
  unit = "px"
) => {
  let classList: any = {};

  for (let i = start; i <= end; i += increment) {
    classList[`.radius-${i}`] = {
      borderRadius: `${i}${unit} !important`,
    };
  }

  return classList;
};

const generateMarginPadding = (
  start = 0,
  end = 25,
  increment = 1,
  unit = "px"
) => {
  let classList: any = {};

  for (let i = start; i <= end; i++) {
    classList[`.m-${i}${unit === "px" ? "px" : ""}`] = {
      margin: `${i * increment}${unit} !important`,
    };
    classList[`.mt-${i}${unit === "px" ? "px" : ""}`] = {
      marginTop: `${i * increment}${unit} !important`,
    };
  }

  return classList;
};

const StyleUtilities = makeStyles((theme) => ({
  "@global": {
    ...generateBorderRadius(),
    ...generateMarginPadding(-16, -1, 1, "px"),
    // Include other utility styles here
  },
}));

export default StyleUtilities;

export const Months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  export const TaxTypes = [
    'Percentage'
  ];  

  export const InvoiceStatus = [
    "Paid",
    "Pending"
  ];

  export const RecurringInvoiceStatus = [
    "Active",
    "Deactivate"
  ];

 export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  export const YearDateFormat = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
  };