import React, { memo, useContext, useState } from 'react';
import { Box, Grid, MenuItem, TableFooter, TextField, Typography } from '@mui/material';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer, IconButton, Menu } from '@mui/material';
import { AmenitiesContext } from './Amenities';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import Pagination from '../../Components/Pagination/Pagination';

const Bookings = () => {
    const amenities = useContext(AmenitiesContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [cOpen, setcOpen] = useState(0);

    const handleClick = (id, event) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, data) => {
        setcOpen(0);
        setAnchorEl(null);
    }
    return (
        <>
            <Box>
                <Grid container className="mt-4">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className="fw-bold">Search</Typography>
                        <TextField 
                            className="w-100"
                            variant="standard"
                            placeholder='Type here'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Search />
                                    </InputAdornment>
                                ),
                            }}
                       />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Property</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Amenity</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Timings</TableCell>
                                <TableCell>No.of.People</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {amenities.bookingList.length !== 0 && amenities.bookingList.map((val,key) => {
                                return (
                                <TableRow key={key}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                    <IconButton
                                        aria-label="more"
                                        id={"long-button_" + val.id}
                                        aria-controls={
                                            cOpen === val.id
                                            ? "long-menu_" + val.id
                                            : undefined
                                        }
                                        aria-expanded={
                                            cOpen === val.id ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                            handleClick(val.id, e);
                                        }}
                                    >
                                        <Typography
                                            className="poppins-family"
                                            color="primary"
                                        >
                                            Action
                                        </Typography>
                                        <KeyboardArrowDownTwoToneIcon color="primary" />
                                    </IconButton>
                                    <Menu
                                        id={"long-menu_" + val.id}
                                        MenuListProps={{
                                            "aria-labelledby": "long-button_" + val.id,
                                        }}
                                        anchorEl={anchorEl}
                                        open={cOpen === val.id}
                                        onClose={handleClose}
                                    >
                                        <MenuItem className="text-success">Confirm</MenuItem>
                                        <MenuItem className="text-danger">Cancel</MenuItem>
                                    </Menu>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                            {amenities.bookingList.length === 0 && <TableRow>
                                <TableCell colspan={9} align="center">
                                    No Data
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                        {amenities.bookingList.length !== 0 && <TableFooter>
                            <TableRow>
                                <Pagination colSpan={9} pagination={amenities.page} rowsPerPage={10}/>
                            </TableRow>
                        </TableFooter>}
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default memo(Bookings)