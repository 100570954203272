import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { isNotEmpty } from "../../Utils/HelperService";
import IconEdit from "../../Assets/images/icon_edit.svg";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";

const ShowExpense = (props: any) => {
  const getInitialState = () => ({
    id: "",
    name: "",
    description: "",
    responsibility: "",
    amount: "",
    property: {property_name:""},
    unit: {unit_name:""},
    tag: {name:""},
    expenseType: {name:""},
  });
  const [state, setState] = useState(getInitialState);
  const [imgArray , setImgArray] = useState([]);


  const handleExpenseEdit = (e: any) => {
    props.pageStatus("editExpense");
    let tmpdata = {
      page: e,
      data: props.expenseData,
    };
    props.pageData(tmpdata);
  };

  useEffect(() => {
    setState(props.expenseData);
    setImgArray(props.expenseData.FileManagers)
  }, [props.expenseData]);


  console.log(state)
  return (
    <>
      <Box
        className="my-30 d-flex justify-content-between"
        sx={{ margin: "30px 0" }}
      >
        <Box className="card-title">
          <Typography variant="h5">Expense Information</Typography>
        </Box>
        <ButtonPrimary
          text="Edit Info"
          className=""
          onClick={() => {
            handleExpenseEdit("Add");
          }}
          imgSrc={IconEdit}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box className="d-flex justify-content-between"></Box>
        {imgArray.length > 0 && (
                imgArray.map((file: any) => (
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                  <img 
                  height={"30%"}
                width={"30%"}
                    src={`${process.env.REACT_APP_API_URL}/${file.folderPath}`} 
                    alt="" 
                    key={file.id} 
                  />
                  </Grid>
                ))
              )}
        <Grid container spacing={3} sx={{ mb: 2 }}>
        </Grid>
        <Box className="table-striped table-striped-header">
          <table>
            <tr>
              <th>Name</th>
              <td>
                {state &&
                  (isNotEmpty(state.name) ? state.name : "")}
              </td>
            </tr>
            <tr>
              <th>Responsibility</th>
              <td>
                {" "}
                {state && isNotEmpty(state.responsibility)
                  ? state.responsibility
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Amount</th>
              <td> {state && isNotEmpty(state.amount) ? state.amount : ""}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td> {state && isNotEmpty(state.description) ? state.description : ""}</td>
            </tr>
            <tr>
              <th>Expense Type</th>
              <td>
                {" "}
                {state && isNotEmpty(state.expenseType.name)
                  ? state.expenseType.name
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Property Name</th>
              <td> {state && isNotEmpty(state.property.property_name) ? state.property.property_name : ""}</td>
            </tr>
            <tr>
              <th>Tag Name</th>
              <td>
                {" "}
                {state && isNotEmpty(state.tag.name)
                  ? state.tag.name
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Unit Name</th>
              <td> {state && isNotEmpty(state.unit.unit_name) ? state.unit.unit_name : ""}</td>
            </tr>
          </table>
        </Box>

        <Button
          className="btn-white btn-back"
          onClick={() => {
            props.pageStatus("listExpense");
          }}
        >
          <ChevronLeftIcon className="fs-20" />
          Back
        </Button>
      </Box>
    </>
  );
};

export default ShowExpense;
