import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Profile from '../../Assets/images/profile.png';
import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Pagination, Radio, RadioGroup, Rating, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GetApiMethod, PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { errorHandling, isNotEmpty } from '../../Utils/HelperService';
import EditCalendarTwoToneIcon from '@mui/icons-material/EditCalendarTwoTone';
import { useLocation, useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import Upload from '../../Components/Upload/Upload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone';
import { Row } from 'antd';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import AssignVendors from '../Vendors/AssignVendor';
import { showLoader } from '../../Store/loaderSlice';
import { useDispatch } from 'react-redux';
import { showToaster } from '../../Store/toastSlice';
import { BackButton } from '../../Components/Button/BackButton';
const ITEM_HEIGHT = 48;


const AntTabs = styled(Tabs)({
    marginLeft: '15px',
    '& .MuiTabs-indicator': {
        backgroundColor: '#FF7D73',
    },
});
const AntTab = styled(Tab)({
    fontSize: "13px",
    fontFamily: "Poppins",
    '&.Mui-selected': {
        color: '#FF7d73'
    }
})
const style = {
    py: 0,
    width: '100%',
    maxWidth: 360,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
};
const ShowHelpDesk = (props: any) => {
    // const [property, setProperty] = useState("")
    const [tabValue, setTabValue] = useState(0);

    let navigate = useNavigate();
    let dispatch = useDispatch();
    const getInitialState = () => ({ id: "", type: "", description: "", attachement: "", status: true, userId: 1, property: {}, unit: {} })
    const [state, setState] = useState<any>(getInitialState)
    const [loading, setLoading] = useState(false);
    const [addHelpDesk, setShowHelpDesk] = useState(false);
    const [helpDeskList, setHelpDeskList] = useState(props.helpDeskList);
    const [helpDeskCount, setHelpDeskCount] = useState(0);
    const [label, setLabel] = useState("Add");
    const [btnLabel, setBtnLabel] = useState("Add");
    const [upData, setUpData] = useState<any>([]);
    const [propertyList, setPropertyList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [pageStatus, setPageStatus] = useState<any>("");
    const [vendorsList, setVendorsList] = useState([]);
    const [vendorsCount, setVendorsCount] = useState([]);
    const [open1, setOpen1] = React.useState(false);
    const [vendorAssignData, setVendorAssignData] = useState<any>({});
    const [vendorAssignErrors, setVendorAssignErrors] = useState(false);
    const [vendorAssignErrorMessage, setVendorAssignErrorMessage] = useState("");
    const [popopen, setPopopen] = useState<any>(false);

    useEffect(() => {
        if (props.helpDeskdata) {
            setState(props.helpDeskdata);
        fetchHelpDeskById(props.helpDeskdata.id);
        fetchVendors();
        }
    }, [props.helpDeskdata])
    const fetchHelpDeskById = async (id: any) => {
        let response = await GetApiMethod('property/help-desk/' + id)
        if (response && response["data"]["error"] === false) {
            let tmpData = response.data.results.data
            let tmpstate = { ...state }
            tmpstate.property_id = tmpData.propertyId
            tmpstate.unit_id = tmpData.unitId
            tmpstate.type_id = tmpData.typeId
            setState({ ...tmpstate, ...tmpData });
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    const fetchVendors = async () => {
        setLoading(true)
        let formdata = {
            role: 'PMA',
            // page: page,
            // limit: limit,
            // searchString: search
        }
        let response = await PostApiMethod('user/getUser', formdata)
        if (response["data"]["error"] === false) {
            setVendorsList(response.data.results.data)
            setVendorsCount(response.data.results.totalCount)
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    const handleHelpDeskEdit = (e: any) => {
        if (e === "Edit") {
            let tmpData = {
                data: state
            }
            props.pageData(tmpData)
            props.pageStatus('editHelpDesk')
        }
        else if (e === "Reassign") {
            let tmpData = {
                data: state
            }
            setOpen1(true);
            // setPopopen(true)
            setVendorAssignData(state)
            // props.pageData(tmpData)
            // props.pageStatus('assignVendors')
        }

    }
    const handleTicketDetailsChange = (e: any, val: any) => {
        setTabValue(val)
    }
    const handlePageDataChange = (data: any) => {
        // setHelpDeskData(data.data);
    }
    // Reassign
    const handleVendorSubmit = async (e: any) => {
        setLoading(true)
        // vendorAssignErrors
        if (vendorAssignData) {
            if (!vendorAssignData.id) {
                setVendorAssignErrors(true)
                setVendorAssignErrorMessage("Helpdesk Id Missing")
                return;
            }
            if (!vendorAssignData.vendor_id) {
                setVendorAssignErrors(true)
                setVendorAssignErrorMessage("Kindly Select Vendor")
                return;
            }
        }
        dispatch(showLoader({ showLoader: true }));
        let formdata = {
            help_desk_id: vendorAssignData.id,
            vendor_id: vendorAssignData.vendor_id.id.toString(),
            message: vendorAssignData.message ? vendorAssignData.message.toString() : "",
            priority: vendorAssignData.priority,
        }
        let response: any;
        try {
            response = await PostApiMethod('property/help-desk/assign-help-desk', formdata)
            if (response && response["data"]["error"] === false) {
                dispatch(
                    showToaster({
                        dialogBgColor: "bg-success",
                        dialogMsg: response["data"]["message"],
                        showDialog: true,
                        timer: "3000",
                    })
                );
                dispatch(showLoader({ showLoader: false }));
                setLoading(false)
                // fetchHelpDeskSearchList();
                fetchHelpDeskById(props.helpDeskdata.id);
                setOpen1(false);
            }
            else {
                dispatch(
                    showToaster({
                        dialogBgColor: "bg-danger",
                        dialogMsg: response["data"]["message"],
                        showDialog: true,
                        timer: "5000",
                    })
                );
                dispatch(showLoader({ showLoader: false }));
            }
        } catch (err: any) {
            const e = errorHandling(err);
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    dialogMsg:e,
                    showDialog: true,
                    timer: "5000",
                })
            );
            dispatch(showLoader({ showLoader: false }));
        }
    }
    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleVendorChange = (e: any, data: any) => {
        setVendorAssignErrors(false)
        console.log({ ...vendorAssignData, [e]: data })
        setVendorAssignData({ ...vendorAssignData, [e]: data })
    }
    console.log(state.assignDetail,"statestatestatestate");
    // 
    return (
        <>
            <Box>
                {/* <Box className="d-flex justify-content-end"> */}
                <Box>
                    <>
                        <Grid container spacing={1} sx={{ m: 1, }}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Row>
                                    <Box className="d-flex align-items-center">
                                        <EngineeringIcon className='text-danger fs-20' />
                                        <Typography className="poppins-family fw-bold">Tickets Details</Typography>
                                        <AntTabs value={tabValue} onChange={handleTicketDetailsChange}>
                                            <AntTab label="Ticket Details" />
                                            {/* <AntTab label="Ticket History" /> */}
                                        </AntTabs>
                                    </Box>
                                </Row>
                            </Grid>
                            <Grid item lg={8} md={4} sm={12} xs={12}>
                                {tabValue === 0 ?
                                    <Box className="d-flex align-items-center justify-content-end">
                                        <Row>
                                            {/* <Button variant="contained" style={{ background: "#FFF", color: "#FF7D73", margin: "5px" }}
                                                onClick={() => { handleHelpDeskEdit("Reassign") }}><EditNoteTwoToneIcon className='fs-23' /> { state.assignDetail ? "Reassign" : "Assign"} </Button> */}
                                            <Button variant="contained" style={{ background: "#FF7D73", margin: "5px" }} onClick={() => { handleHelpDeskEdit("Edit") }}><EditNoteTwoToneIcon className='fs-23' />Edit Info </Button>
                                        </Row>
                                    </Box>
                                    : ""}
                            </Grid>
                        </Grid>
                    </>
                </Box>
                <CustomTabPanel value={tabValue} index={0} >
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Ticket No</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>{state && isNotEmpty(state.ticketNo) ? state.ticketNo : ""}</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Raised by</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>{state && isNotEmpty(state.userDetail) && (isNotEmpty(state.userDetail.firstName) ? state.userDetail.firstName : "") + " "+ (isNotEmpty(state.userDetail.lastName) ? state.userDetail.lastName : "")}</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Raised on</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>{state && isNotEmpty(state.createdAt) ? state.createdAt : ""}</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Unit</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>{state && isNotEmpty(state.unit) && isNotEmpty(state.unit.unit_name) ? state.unit.unit_name : ""}</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />


                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Type</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>{state && isNotEmpty(state.typeDetail) && isNotEmpty(state.typeDetail.name) ? state.typeDetail.name : ""}</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Status</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'
                            style={{ color: 
                                `${state.status === "Pending" ? "#FFA95D" 
                                : state.status === "Completed" ? "#CA4FE8" 
                                : state.status === "Assigned" ? "#24BC94" 
                                : state.status === "Urgent" ? "#FF7D73" 
                                : state.status === "Re-Assigned" ? "#60A3F9" 
                                : state.status === "Closed" ? "#FFA95D" 
                                : ""}`
                            }}
                            >
                                {state && isNotEmpty(state.status) ? state.status: ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Priority</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'
                                style={{ color: 
                                    `${state.priority === "Low" ? "#FFA95D" 
                                    : state.priority === "High" ? "#60A3F9" 
                                    : state.priority === "Medium" ? "#24BC94" 
                                    : state.priority === "Urgent" ? "#FF7D73" 
                                    : ""}`
                                }}
                            >
                                {state && isNotEmpty(state.priority) ? state.priority: ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Description</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>{state && isNotEmpty(state.description) ? state.description : ""}</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Assigned to</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'
                            style={{ color: `${state.priority === "Not   Assigned" ? "#D44C59" : ""}`
                            }}
                            > 
                            {state.assignDetail? state.assignDetail.firstName : "Not assigned"}
                                {/* {state && isNotEmpty(state.assignDetail) && (isNotEmpty(state.assignDetail.firstName) ? state.assignDetail.firstName : "") + " " + (isNotEmpty(state.assignDetail.lastName) ? state.assignDetail.lastName : "")}*/}
                                </Typography> 
                        </Grid>
                    </Grid>
                    {/* <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Ratings</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>
                            <Rating name="read-only" value={state && isNotEmpty(state.rating) ? state.rating : 5} readOnly />
                                </Typography>
                        </Grid>
                    </Grid> 
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ m: 1, }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold'>Feedbacks</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>{state && isNotEmpty(state.feedback) ? state.feedback : ""}</Typography>
                        </Grid>
                    </Grid> */}
                    <Divider style={{ borderColor: "black" }} />
                    <Grid container spacing={3} sx={{ mt: 3,ml:1 }}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family text-bold mb-2'>Images</Typography>
                                 {state && isNotEmpty(state.FileManagers) ?state.FileManagers.map((data:any) => (<img src={process.env.REACT_APP_API_URL +"/"+ data.folderPath} alt='' width="100px" height="100px" style={{margin:"5px"}}/>)):""}


                            {/* </Typography> */}
                        </Grid>
                    </Grid>
                   <AssignVendors  popup={open1} vendorAssigndata={vendorAssignData} 
                   popupClose={handleClose1} />
 
                    <BackButton onClick={()=>{
                                        props.pageStatus("listHelpDesk");

                    }}/>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} >

                </CustomTabPanel>
            </Box>
        </>
    )
}

export default ShowHelpDesk