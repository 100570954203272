import React, { useState , useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useFormik } from "formik";
import { AddMaintenanceSchema } from "../../Components/Validation";
import { showLoader } from "../../Store/loaderSlice";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { showToaster } from "../../Store/toastSlice";
import { PostApiMethod } from "../../Utils/ApiService";
import { errorHandling } from "../../Utils/HelperService";

const AddMaintenance = (props: any) => {

  let dispatch = useDispatch();

  const [AreaTypeList, setAreaTypeList] = useState([]);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [userList, setUser] = useState([]);

  const getInitialState = {
    type: "Corrective",
    job_type_id: "",
    area_type_id: "",
    issue: "",
    date: "",
    timings: "",
    status: "",
    priority: "",
    no_of_people:"",
    assign_to:"",
    recurring_type:"",
    start_date:"",
    maintenance_remainder:"",
  };
  
  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
  useFormik({
    initialValues: getInitialState,
    validationSchema: AddMaintenanceSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handlFormSubmit(values);
    },
  });

  const fetchAreaType = async () => {
    let response = await PostApiMethod("maintenance/area-type/list", {});
    if (response["data"]["error"] === false) {
      setAreaTypeList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchUser = async () => {
    let response = await PostApiMethod("user/getUser", {});
    if (response["data"]["error"] === false) {
      setUser(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };

  const fetchJobType = async () => {
    let response = await PostApiMethod("maintenance/job-type/list", {});
    if (response["data"]["error"] === false) {
      setJobTypeList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const handlFormSubmit = async (values: any) => {
    try {
      dispatch(showLoader({ showLoader: true }));
      await PostApiMethod('maintenance/add', values);  
      dispatch(showLoader({ showLoader: false }));
  
      dispatch(
        showToaster({
          dialogBgColor: 'success',
          dialogMsg:'Maintenance added successfully',
          showDialog: true,
          timer: '5000',
        })
      );
      props.pageStatus("listMaintenance");
  } catch (err: any) {
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: 'bg-danger',
          dialogMsg: error,
          showDialog: true,
          timer: '5000',
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };


  useEffect(() => {
    fetchJobType();
    fetchAreaType();
    // fetchUser();
  }, []);

  const statusList = ["New Request" , "In Progress" , "Completed"]
  const priorityList = ["High" , "Medium" , "Low"]
  const RecurringTypeList = ["Cleaning" , "Plumbing" , "Electrical","Foods & Beverage"]
  return (
    <>
      <Box className="pt-3">
        <Box className="card-title mb-3">
          <Typography variant="h5">
            Add New Maintenance
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <FormControl className="mt-3">
            <FormLabel
              className="poppins-family text-black"
              id="responsibilty-type"
            >
              Maintenance Type
            </FormLabel>
            <RadioGroup row aria-labelledby="type" name="type" value={values.type} onChange={handleChange}>
              <FormControlLabel
                className="poppins-family"
                value="Corrective"
                control={<Radio />}
                label="Corrective"
              />
              <FormControlLabel
                className="poppins-family"
                value="Preventive"
                control={<Radio />}
                label="Preventive"
              />
            </RadioGroup>
          </FormControl>
          <Box className="mt-3">
          {values.type.toLocaleLowerCase() === "corrective" ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Job Type
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="job_type_id"
                    value={values.job_type_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {jobTypeList.map((data:any) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.job_type_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.job_type_id && touched.job_type_id && (
                    <Typography className="text-danger">{errors.job_type_id}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Area Type
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="area_type_id"
                    value={values.area_type_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {AreaTypeList.map((data:any) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.area_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.area_type_id && touched.area_type_id && (
                    <Typography className="text-danger">{errors.area_type_id}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Issue
                </FormLabel>
                <TextField
                  type="text"
                  variant="standard"
                  className="w-100"
                  placeholder="Type here..."
                  name="issue"
                  onChange={handleChange}
                  value={values.issue}
                />
                {errors.issue && touched.issue && (
                  <Typography className="text-danger">
                    {errors.issue}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Date
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="standard"
                  name="date"
                  placeholder="dd/mm/yyyy"
                  type="date"
                  value={values.date}
                  onChange={handleChange}
                />
                {errors.date && touched.date && (
                  <Typography className="text-danger">
                    {errors.date}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Timings
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <TextField type="time" variant="standard" name="timings" onChange={handleChange} value={values.timings} />
                  {errors.timings && touched.timings && (
                    <Typography className="text-danger">{errors.timings}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mt-2">
                <FormLabel className="poppins-family text-black">
                  Status
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {statusList.map((data:any) => (
                      <MenuItem key={data} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.status && touched.status && (
                    <Typography className="text-danger">{errors.status}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mt-2">
                <FormLabel className="poppins-family text-black">
                  Priority
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="priority"
                    value={values.priority}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {priorityList.map((data:any) => (
                      <MenuItem key={data} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.priority && touched.priority && (
                    <Typography className="text-danger">{errors.priority}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  No of People
                </FormLabel>
                <TextField
                  variant="standard"
                  className="w-100"
                  placeholder="Type here..."
                  name="no_of_people"
                  onChange={handleChange}
                  value={values.no_of_people}
                />
                {errors.no_of_people && touched.no_of_people && (
                  <Typography className="text-danger">
                    {errors.no_of_people}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mt-2">
                <FormLabel className="poppins-family text-black">
                  Assign to
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="assign_to"
                    value={values.assign_to}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="58">
                      None
                    </MenuItem>
                    {userList.map((data:any) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.firstName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.assign_to && touched.assign_to && (
                    <Typography className="text-danger">{errors.assign_to}</Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          ) : null}
          {values.type.toLocaleLowerCase() === "preventive" ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Job Type
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="job_type_id"
                    value={values.job_type_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {jobTypeList.map((data:any) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.job_type_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.job_type_id && touched.job_type_id && (
                    <Typography className="text-danger">{errors.job_type_id}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Area Type
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="area_type_id"
                    value={values.area_type_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {AreaTypeList.map((data:any) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.area_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.area_type_id && touched.area_type_id && (
                    <Typography className="text-danger">{errors.area_type_id}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Issue
                </FormLabel>
                <TextField
                  type="text"
                  variant="standard"
                  className="w-100"
                  placeholder="Type here..."
                  name="issue"
                  onChange={handleChange}
                  value={values.issue}
                />
                {errors.issue && touched.issue && (
                  <Typography className="text-danger">
                    {errors.issue}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Recurring Type
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="recurring_type"
                    value={values.recurring_type}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {RecurringTypeList.map((data:any) => (
                      <MenuItem key={data} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.recurring_type && touched.recurring_type && (
                    <Typography className="text-danger">{errors.recurring_type}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Start Date
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="standard"
                  name="start_date"
                  placeholder="dd/mm/yyyy"
                  type="date"
                  value={values.start_date}
                  onChange={handleChange}
                />
                {errors.start_date && touched.start_date && (
                  <Typography className="text-danger">
                    {errors.start_date}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Timings
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <TextField type="time" variant="standard" name="timings" onChange={handleChange} value={values.timings} />
                  {errors.timings && touched.timings && (
                    <Typography className="text-danger">{errors.timings}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mt-2">
                <FormLabel className="poppins-family text-black">
                  Status
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {statusList.map((data:any) => (
                      <MenuItem key={data} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.status && touched.status && (
                    <Typography className="text-danger">{errors.status}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mt-2">
                <FormLabel className="poppins-family text-black">
                  Priority
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="priority"
                    value={values.priority}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {priorityList.map((data:any) => (
                      <MenuItem key={data} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.priority && touched.priority && (
                    <Typography className="text-danger">{errors.priority}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  No of People
                </FormLabel>
                <TextField
                  variant="standard"
                  className="w-100"
                  placeholder="Type here..."
                  name="no_of_people"
                  onChange={handleChange}
                  value={values.no_of_people}
                />
                {errors.no_of_people && touched.no_of_people && (
                  <Typography className="text-danger">
                    {errors.no_of_people}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mt-2">
                <FormLabel className="poppins-family text-black">
                  Assign to
                </FormLabel>
                <FormControl variant="standard" className="w-100">
                  <Select
                    name="assign_to"
                    value={values.assign_to}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {userList.map((data:any) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.firstName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.assign_to && touched.assign_to && (
                    <Typography className="text-danger">{errors.assign_to}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="poppins-family text-black">
                  Maintenance Reminder
                </FormLabel>
                <TextField
                  variant="standard"
                  className="w-100"
                  placeholder="Type here..."
                  name="maintenance_remainder"
                  onChange={handleChange}
                  value={values.maintenance_remainder}
                />
                {errors.maintenance_remainder && touched.maintenance_remainder && (
                  <Typography className="text-danger">
                    {errors.maintenance_remainder}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : null}
            <Box className="mt-5">
            <Button className="btn-white border-0" onClick={() => {
                  props.pageStatus("listMaintenance");
                }}>
              <ChevronLeftIcon sx={{ fontSize: "23px", marginRight: "-6px" }} />
              Back
            </Button>
            <button type="submit" className="btn-danger ms-2">Submit</button>
          </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddMaintenance;
