import { useCallback, useEffect, useState } from "react";
import { PostApiMethod } from "../../Utils/ApiService";
import { errorHandling } from "../../Utils/HelperService";
import { PropertyDetailDTO } from "../../Pages/BillingCenter/NewInvoice";
import ApiSearch from "./ApiSearch";

interface PropertySearchComponentProps {
    onChange : (value:PropertyDetailDTO) => void
    disableClearable?: boolean;
}

const PropertySearchComponent = (props:PropertySearchComponentProps) => {
  const [propertySearchLoader, setPropertySearchLoader] = useState(false);
  const [propertyList, setPropertyList] = useState<PropertyDetailDTO[]>([]);
  const [propertyValues, setPropertyValues] = useState();
  const searchProperty = useCallback((value: string) => {
    setPropertySearchLoader(true);
    PostApiMethod("property/properties/list", {
      searchString: value,
      page: "1",
      limit: "20",
    })
      .then((res) => {
        setPropertySearchLoader(false);
        setPropertyList(res.data.results.data);
      })
      .catch((e) => {
        const error = errorHandling(e);
        setPropertySearchLoader(false);
        setPropertyList([]);
      });
  }, []);

  useEffect(()=>{
    searchProperty("");
  },[])

  return (
    <>
      <ApiSearch
        value={propertyValues}
        onChange={(newValue: any) => {
          setPropertyValues(newValue)
          props.onChange(newValue);
        }}
        disableClearable={props.disableClearable ? props.disableClearable :false}
        loading={propertySearchLoader}
        options={propertyList}
        searchApi={searchProperty}
        isOptionEqualToValue={(option, value) => option.property_name === value.property_name}
        getOptionLabel={(option: PropertyDetailDTO) => option.property_name}
      />
    </>
  );
};

export default PropertySearchComponent;
