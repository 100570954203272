import { useState, useEffect, useCallback } from "react";
import styles from "./rendAndCharges.module.css";
import { FormButton1 } from "../../components/formButton";
import { Formik, FieldArray, ErrorMessage } from "formik";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { RentAddAndEditSchema } from "../../../../Components/Validation";
import { TextFieldForArray } from "../../components/textfield1";
import { ErrorTextField } from "../../../../Components/Error/ErrorTextField";
import { PutApiMethod } from "../../../../Utils/ApiService";
import { errorHandling } from "../../../../Utils/HelperService";
import { useAppDispatch } from "../../../../Store/hooks";
import { showToaster } from "../../../../Store/toastSlice";
import { showLoader } from "../../../../Store/loaderSlice";

interface RentScreenProps {
  backBtn: (val: any) => void;
  submitBtn: (currentComponent: any, unitId: any) => void;
  // CreatedPropertyIdFromApi: any;
  // CreatedUserIdFromApi: any;
  // CreatedNo_of_unitsFromApi: any;
  // propertyDetails: any;
  unitDetails: any;
}

export const RentScreen = (props: RentScreenProps) => {
  const [initialValue, setInitialValue] = useState({
    rents: [
      {
        unit_name: "",
        general_rent: "",
        security_deposit: "",
        late_fee: "",
        incident_receipt: "",
      },
    ],
  });
  const dispatch = useAppDispatch();

  function backBtnFunc() {
    console.log("back btn called");
    props.backBtn("Rent & Charges");
  }

  useEffect(() => {
    setInitialValue((prevValue) => ({
      ...prevValue,
      rents: props.unitDetails,
    }));
  }, [props.unitDetails]);

  const updateRent = useCallback((values: any) => {
    const formData = new FormData();
    values.rents.forEach((obj: any, index: number) => {
      Object.entries(obj).forEach(([key, value]) => {
        formData.append(`data[${index}][${key}]`, value as string | Blob);
      });
    });
    PutApiMethod("property/unit/updateRate", formData)
      .then((res) => {
        const rentUpdateResponse = res["data"]["results"]["data"];
        dispatch(
          showToaster({
            dialogBgColor: "bg-success",
            dialogMsg: "Rates Updated Successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        props.submitBtn("Rent & Charges", rentUpdateResponse);
        dispatch(showLoader({ showLoader: false }));
      })
      .catch((e) => {
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "50000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      });
  }, []);

  return (
    <>
      <div className={styles.topBox}>
        <Formik
          initialValues={initialValue}
          validationSchema={RentAddAndEditSchema}
          enableReinitialize
          onSubmit={(values) => {
            updateRent(values);
          }}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <FieldArray name="rents">
                {({ push, remove }) => (
                  <>
                    <div>
                      {values.rents.map((item, index) => {
                        return (
                          <div>
                            <div className={styles.formItem}>
                              <TextFieldForArray
                                label={"Unit Name"}
                                name={`rents.${index}.unit_name`}
                                type={"text"}
                                value={props.unitDetails[index].unit_name}
                                valueChange={(
                                  InputValue: any,
                                  inputName: string,
                                  currentIndex: number
                                ) => {
                                  setFieldValue(inputName, InputValue);
                                }}
                                indexOfTextField={index}
                                nonEditable={true}
                              />
                            </div>
                            <div className={styles.rentAmountBox}>
                              <div>
                                <TextFieldForArray
                                  label={"General Rent"}
                                  name={`rents.${index}.general_rent`}
                                  type={"number"}
                                  valueChange={(
                                    InputValue: any,
                                    inputName: string,
                                    currentIndex: number
                                  ) => {
                                    setFieldValue(inputName, InputValue);
                                  }}
                                  value={values.rents[index].general_rent}
                                  indexOfTextField={index}
                                />
                                <ErrorTextField
                                  error={
                                    <ErrorMessage
                                      name={`rents[${index}].general_rent`}
                                    />
                                  }
                                  touched={true}
                                />
                              </div>
                              <div>
                                <TextFieldForArray
                                  label={"Security Deposit"}
                                  name={`rents.${index}.security_deposit`}
                                  type={"number"}
                                  value={values.rents[index].security_deposit}
                                  valueChange={(
                                    InputValue: any,
                                    inputName: string,
                                    currentIndex: number
                                  ) => {
                                    setFieldValue(inputName, InputValue);
                                  }}
                                  indexOfTextField={index}
                                />
                                <ErrorTextField
                                  error={
                                    <ErrorMessage
                                      name={`rents[${index}].security_deposit`}
                                    />
                                  }
                                  touched={true}
                                />
                              </div>
                              <div>
                                <TextFieldForArray
                                  label={"Late Fee"}
                                  name={`rents.${index}.late_fee`}
                                  type={"number"}
                                  valueChange={(
                                    InputValue: any,
                                    inputName: string,
                                    currentIndex: number
                                  ) => {
                                    setFieldValue(inputName, InputValue);
                                  }}
                                  value={values.rents[index].late_fee}
                                  indexOfTextField={index}
                                />
                                <ErrorTextField
                                  error={
                                    <ErrorMessage
                                      name={`rents[${index}].late_fee`}
                                    />
                                  }
                                  touched={true}
                                />
                              </div>
                            </div>
                            <div className={styles.formItem}>
                              <div>
                                <TextFieldForArray
                                  label={"Incident Receipt"}
                                  name={`rents.${index}.incident_receipt`}
                                  type={"text"}
                                  valueChange={(
                                    InputValue: any,
                                    inputName: string,
                                    currentIndex: number
                                  ) => {
                                    setFieldValue(inputName, InputValue);
                                  }}
                                  value={values.rents[index].incident_receipt}
                                  indexOfTextField={index}
                                />
                                <ErrorTextField
                                  error={
                                    <ErrorMessage
                                      name={`rents[${index}].incident_receipt`}
                                    />
                                  }
                                  touched={true}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </FieldArray>
              <FormButton1 backBtnClick={backBtnFunc} />
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
