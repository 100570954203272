import { radioButton1 } from "../types/interface";
import styles from "./radioButton.module.css";

interface RadioButtonProps {
  label: string;
  value: string;
  name: string;
  isChecked?: boolean;
  valueChange: Function;
  arrayIndex?: number;
  forUpdateRadioValue?: any;
  indexOfTextField?: number;
}

const RadioButton1: React.FC<RadioButtonProps> = ({
  label,
  value,
  name,
  isChecked = false,
  valueChange,
  arrayIndex,
  indexOfTextField,
}) => {
  function onChangeRadio(event: any) {
    const newValue = event.target.value;
    valueChange(newValue, name, indexOfTextField);
  }
  return (
    <div className={`${styles.radioBox} radio-btn`}>
      {/* <label>{title}</label> */}
      {/* {radioButtonContent.map((val, index) => ( */}
      <div className={`${styles.radioButton}`}>
        <input
          type="radio"
          id={label}
          name={name}
          value={value.toString()}
          checked={isChecked}
          onChange={onChangeRadio}
        />
        <label
          // htmlFor={label}
          className={styles.lable}
          htmlFor={label}
        >
          {label}
        </label>
      </div>
      {/* ))} */}
    </div>
  );
};

export default RadioButton1;
