import React, { useEffect, useState, createContext, useCallback, useRef } from 'react';
import Card from '@mui/material/Card';
import { Typography, Box  } from '@mui/material';
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import VisitorCalendar from './VisitorCalendar';
import VisitorRequest from './VisitorRequest';
import AddVisitor from './AddVisitor';
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import VisitorIcon from "../../Assets/images/visitor_color_profile.png";
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { convertTo24HourFormat } from '../../Components/CommonFormik';
import {
    PostApiMethod,
    DeleteApiMethod
  } from "../../Utils/ApiService";
import { showToaster } from '../../Store/toastSlice';
import AdditionalRequirement from '../Masters/AdditionalRequirement';

export const VisitorContext = createContext();

const Visitor = () => {
    const [tabValue, setTabValue] = useState(0)
    const [addVisitor, setAddVisitor] = useState(false);
    const [data, setData] = useState([]);
    const [unit, setUnit] = useState([]);
    const [parking, setParking] = useState([]);
    const [tenant, setTenant] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editData, setEditData] = useState({});
    const [additionalReq, setAdditionalReq] = useState([]);
    const [page, setPage] = useState({page:1,limit:5,count:0});
    const [weekList, setWeekList] = useState([]);
    const dispatch = useAppDispatch();
    const calendarRef = useRef(null)

    useEffect(() => {
        dispatch(showLoader({ showLoader: true }));
        getUnitDetails();
        getParkingRequirement();
        getAmenities();
        getTenant();
        getAdditionalRequirement();
        getRequestList(page);
    },[])

    const getWeekList = async(date) => {
        dispatch(showLoader({showLoader:true}))
        // console.log("date",date?date:new Date())
        // try{
            let res = await PostApiMethod("property/visitor/weeklist",{date:date});
            if(res["data"]["error"] === false){
                let result = res["data"]["results"]["data"];
                const events = [];
                result.length !== 0 && result.map((val,key) => {
                    let timing = val.timing && val.timing.split("-")
                    if(timing){
                        if(timing[0] !== undefined && timing[1] !== undefined){
                            events.push({
                            id: key+1,
                            text: val.roomCount > 1 ? val.rooms.split(",")[0] + " +"+(val.roomCount - 1)+" more..." : val.rooms,
                            start: val.date.split("T")[0]+"T"+convertTo24HourFormat(timing[0])+":00",
                            end: val.date.split("T")[0]+"T"+convertTo24HourFormat(timing[1])+":00",
                            rooms: val.rooms
                            })
                        }

                    }
                })
                setWeekList(events);
                let startDate = date;
                console.log(events)
                // calendarRef.current.control.update({startDate, events});
            }
        // }catch(error){
        //     dispatch(showLoader({}))
        // }
        dispatch(showLoader({showLoader:false}))
    }

    const getRequestList = async(params) => {
        var parameters = params.limit === -1 ? {} : {page:params.page,limit:params.limit};
        let response = await PostApiMethod("property/visitor/list",parameters);
        let date = new Date();
        if(response["data"]["error"] === false){
            setData(response["data"]["results"]["data"])
            setPage((prevPage) => ({...prevPage,count: response["data"]["results"]["totalCount"],}))
        }
        setLoading(false)
        getWeekList(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate());
        dispatch(showLoader({ showLoader: false }));
    }

    const getUnitDetails = async() => {
        let response = await PostApiMethod("property/unit/list",{});
        if(response["data"]["error"] === false){
            setUnit(response["data"]["results"]["data"])
        }
    }

    const getParkingRequirement = async() => {
        let response = await PostApiMethod("property/parking/list",{});
        if(response["data"]["error"] === false){
            setParking(response["data"]["results"]["data"])
        }
    }

    const getAmenities = async() => {
        let response = await PostApiMethod("property/amenities/list",{});
        if(response["data"]["error"] === false){
            setAmenities(response["data"]["results"]["data"])
        }
    }

    const getTenant = async() => {
        let response = await PostApiMethod("user/getUser",{"role":"PMU"});
        if(response["data"]["error"] === false){
            setTenant(response["data"]["results"]["data"])
        }
    }

    const handleChange = useCallback((e,newValue) => {
        setTabValue(newValue)
        setAddVisitor(false);
    },[tabValue])

    const handleAddVisitor = useCallback(() => {
        setAddVisitor(true);
        setTabValue(1);
        setEditData({});
    },[addVisitor])

    const handleEdit = useCallback((val) => {
        setAddVisitor(true);
        setTabValue(1);
        setEditData(val);
    },[editData])

    const handleNextPage = useCallback((val) => {
        dispatch(showLoader({ showLoader: true }));
        setPage({...page,page:val.page,limit:val.limit})
        getRequestList(val)
    },[page])

    const deleteRequestList = async(id) => {
        dispatch(showLoader({ showLoader: true }))
        try{
            let response = await DeleteApiMethod("property/visitor/"+id);
            dispatch(showToaster({dialogBgColor: "bg-success",
            dialogMsg: "Visitor Deleted",
            showDialog: true,
            timer: "3000",}))
            getRequestList(page)
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: "Failed",
            showDialog: true,
            timer: "3000",}))
        }
        dispatch(showLoader({ showLoader: false }))
    }

    const searchString = async(val) => {
        dispatch(showLoader({ showLoader:true }))
        let response = await PostApiMethod("property/visitor/list",{page:page.page,limit:page.limit,searchString:val});
        if(response["data"]["error"] === false){
            setData(response["data"]["results"]["data"])
            setPage((prevPage) => ({...prevPage,count: response["data"]["results"]["totalCount"],}))
        }
        dispatch(showLoader({ showLoader:false }))
    }

    const getAdditionalRequirement = async() => {
        try{
            let res = await PostApiMethod("/property/additional-requirement/list",{});
            if(res["data"]["error"] === false){
                setAdditionalReq(res["data"]["results"]["data"])
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: "Failed fetch additional requirement details",
            showDialog: true,
            timer: "3000",}))
        }
    }

  return (
    <AuthorizedLayout>
        <VisitorContext.Provider value={{
            reqData:data,
            unit:unit,
            parking:parking,
            tenant:tenant,
            amenities:amenities,
            getRequestList:getRequestList,
            handleEdit:handleEdit,
            editData:editData,
            handleNextPage:handleNextPage,
            page:page,
            deleteRequestList:deleteRequestList,
            searchString:searchString,
            additionalReq:additionalReq,
            weekList:weekList,
            getWeekList:getWeekList,
            calendarRef:calendarRef,
            getAdditionalRequirement:getAdditionalRequirement
        }}>
            <Card elevation={0} >
                <Box className="d-flex justify-content-between align-items-center flex-wrap">
                    <Box className="d-flex align-items-center flex-wrap">
                    <img src={VisitorIcon} alt="Property" width={20} />
                        <Typography className="fw-bold fs-5">Visitor Management</Typography>
                        <AntTabs value={tabValue} onChange={handleChange}>
                            <AntTab label="Visitor Calendar" className="tab-menu"/>
                            <AntTab label="Visitor Request" className="tab-menu"/>
                            <AntTab label="Additional Requirement" className='tab-menu'/>
                        </AntTabs>
                    </Box>
                    {tabValue !== 2 && !addVisitor && <button className='btn btn-danger' onClick={handleAddVisitor}><AddIcon/>Add New Visitor</button>}
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <VisitorCalendar />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    {!addVisitor ? <VisitorRequest /> : <AddVisitor onBack={() => {setTabValue(1);setAddVisitor(false)}} />}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <AdditionalRequirement />
                </CustomTabPanel>
            </Card>
        </VisitorContext.Provider>
    </AuthorizedLayout>
  )
}

export default Visitor