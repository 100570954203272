import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Typography, Link, Autocomplete, Breadcrumbs } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  GetApiMethod,
  PostApiMethod,
  PutApiMethod,
} from "../../Utils/ApiService";
import {
  DisplayErrorMessages,
  errorHandling,
  isNotEmpty,
} from "../../Utils/HelperService";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import { useLocation, useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import Upload from "../../Components/Upload/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ShowTenant from "./ShowTenant";
import AssignVendorsList from "../Vendors/AssignVendorsList";
import { showToaster } from "../../Store/toastSlice";
import { showLoader } from "../../Store/loaderSlice";
import { addTenantSchema, tenantSchema } from "../../Components/Validation";
import { useFormik } from "formik";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import BasicDatePicker from "../property/components/tenantdatePicker";
import { useAppSelector } from "../../Store/hooks";
import TenantHomeDetails from "./TenantHomeDetails";
import DocumentUploads from "./DocumentUploads";
import SubHeading1 from "../property/components/subHeading1";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
interface TenantAddOptions {
  tenantInformation: boolean;
  homeDetails: boolean;
  documents: boolean;
  [key: string]: boolean; // Index signature
}
interface TenantOptions {
  tenantInformation: boolean;
  homeDetails: boolean;
  documents: boolean;
  [key: string]: boolean; // Index signature
}
const AddTenant = (props: any) => {
  // const [property, setProperty] = useState("")
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const getInitialState = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    job: "",
    age: "",
    familyMember: "",
  };
  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
    useFormik({
      initialValues: getInitialState,
      validationSchema: addTenantSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        handlFormSubmit(values);
      },
    });
  const userData: any = useSelector<any>((state) => state.auth);
  const [state, setState] = useState<any>(getInitialState);
  const [addNew, setAddNew] = useState<any>({
    tenantInformation: true,
    homeDetails: false,
    documents: false,
  });
  const [addTenant, setAddTenant] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [tenantCount, setTenantCount] = useState(0);
  const [label, setLabel] = useState("Add");
  const [btnLabel, setBtnLabel] = useState("Add");
  const [upData, setUpData] = useState<any>([]);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [filterUnitList, setFilterUnitList] = useState([]);
  const [showTenant, setShowTenant] = useState(false);
  const [assignVendors, setAssignVendors] = useState(false);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [unitDetailsShow, setUnitDetailsShow] = useState(false);
  const [homeUnitDetails, setHomeUnitDetails] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [tenantId, setTenantId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [allData , setAllData] = useState([])
  const [finalData , setFinalData] = useState(null)

  const handleBreadCrumbsClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };
  const loaderState = useAppSelector((state) => state.loader);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color={addNew["tenantInformation"] ? "#FFA95D" : "inherit"}
      onClick={handleBreadCrumbsClick}
    >
      Tenant Information
    </Link>,
    <Link
      underline="hover"
      key="2"
      color={addNew["homeDetails"] ? "#FFA95D" : "inherit"}
      onClick={handleBreadCrumbsClick}
    >
      Home Details
    </Link>,
    <Typography key="3" color={addNew["homeDetails"] ? "#FFA95D" : "inherit"}>
      Document
    </Typography>,
  ];

  useEffect(() => {
    setAddTenant(props.addTenant);
    setShowTenant(props.showTenant);

    setSearch(props.tenantsearch);
    // setTenantList(props.tenantList);
    // setTenantCount(props.tenantCount);
    if (props.addTenant) {
      setLabel("Add");
    }
  }, [props]);
  useEffect(() => {
    // setState({ ...state, "attachement": upData[0] })
  }, [upData]);
  useEffect(() => {
    if (props.tenantdata) {
      setLabel("Edit");
      setBtnLabel("Update");
      fetchTenantById(props.tenantdata.id);
    }
  }, [props.tenantdata]);
  const fetchProperty = async () => {
    let response = await PostApiMethod("property/properties/list", {});
    if (response["data"]["error"] === false) {
      setPropertyList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchUnit = async () => {
    let response = await PostApiMethod("property/unit/list", {});
    if (response["data"]["error"] === false) {
      setUnitList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchTenantSearchList = async (search: any = "") => {
    let formdata = {
      page: page,
      limit: limit,
      searchString: search,
    };
    let response = await PostApiMethod("property/help-desk/list", formdata);
    if (response["data"]["error"] === false) {
      setTenantList(response.data.results.data);
      setTenantCount(response.data.results.totalCount);
    } else {
      console.log("response", response);
    }
  };
  useEffect(() => {
    fetchProperty();
    fetchUnit();
    // fetchTenantSearchList();
  }, []);

  useEffect(() => {
    if (state.property_id && state.property_id!.id!) {
      setFilterUnitList([]);
      const filteredData = unitList.filter(
        (unit: any) =>
          unit.property_id.toString() === state.property_id.id.toString()
      );
      console.log(filteredData, "filteredDatafilteredData");
      setFilterUnitList(filteredData);
      // console.log(state.property_id,unitList);
    }
  }, [state.property_id, unitList]);

  const pageStatus = (data: any) => {
    let tmpData: TenantOptions = { ...addNew };
    for (let prop in tmpData) {
      if (prop !== data) {
        tmpData[prop] = false; // Set all properties to false except the one specified
      } else {
        tmpData[prop] = true;
      }
    }
    setAddNew(tmpData);
  };

  function handleDateChange(
    InputValue: any,
    inputName: string,
    currentIndex: number
  ) {
    setState({ ...state, [inputName]: InputValue });
  }
  const handleHomeDetailsChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handletenantHomeDetailsChange = (e: any, data: any) => {
    setState({ ...state, [e]: data });
  };
  const handleImageChange = (e: any) => {
    setState({ ...state, attachement: e.target.files });
  };
  // const { values, handleChange, handleSubmit, errors, touched, resetForm } = useFormik({
  //     initialValues: state,
  //     validationSchema: tenantSchema,
  //     enableReinitialize: true,
  //     onSubmit: async (values) => {
  //       {handlFormSubmit(values)}
  //     },
  //   });

  const handlFormSubmit = async (value: any) => {
    dispatch(showLoader({ showLoader: true }));
    // form.append()
    let tmpData = { ...value };
    // if (!userData.adminData) {
    //   dispatch(
    //     showToaster({
    //       dialogBgColor: "bg-info",
    //       // dialogMsg: e.response.data.message,
    //       dialogMsg: "UserData Not Available",
    //       showDialog: true,
    //       timer: "5000",
    //     })
    //   );
    //   dispatch(showLoader({ showLoader: false }));
    //   return;
    // } else {
    tmpData.role = "PMU";
    tmpData.parentId = userData.adminData.id;
    //  }
    let res: any;
    try {
      if (addNew.tenantInformation) {
        if (tmpData.id) {
          res = await PutApiMethod("user/" + tmpData.id, tmpData);
          props.clearTenantData()
        } else {
          // res = await PostApiMethod("auth/create-user", tmpData);
          setState( value )
          setAllData( value )
          // console.log(res.data.results.data);
          // setTenantId(res.data.results.data.id);
        }
      }
          dispatch(showLoader({ showLoader: false }));
      pageStatus("homeDetails");
      // dispatch(
      //   showToaster({
      //     dialogBgColor: "success",
      //     dialogMsg: tmpData.id
      //       ? "User updated successfully"
      //       : "User added successfully",
      //     showDialog: true,
      //     timer: "5000",
      //   })
      // );

      //   if (addNew.homeDetails) {
      //     let tmpDataHomeDetails = {
      //       propertyId: tmpData.property_id.id,
      //       leaseStartDate: tmpData.leaseStartDate,
      //       leaseEndDate: tmpData.leaseEndDate,
      //       leaseReminderDays: tmpData.leaseReminderDays,
      //       unitId: tmpData.unit_id.id,
      //       general_rent: tmpData.general_rent,
      //       security_deposit: tmpData.security_deposit,
      //       late_fee: tmpData.late_fee,
      //       incident_receipt: tmpData.incident_receipt,
      //     };
      //     // res = await PutApiMethod("user/" + tmpData.id, tmpDataHomeDetails);
      //   }
      //   if (addNew.documents) {
      //     let documentFormData = new FormData();
      //     documentFormData.append("tenant_id", tmpData.id);
      //     documentFormData.append("unit_id", tmpData.unit_id.id);
      //     documentFormData.append("property_id", tmpData.property_id.id);
      //     documentFormData.append("document[]", tmpData.attachement[0]);
      //     documentFormData.append("documentType", "temp");
      //     res = await PostApiMethod(
      //       "property/document/addTenantDocument",
      //       documentFormData,
      //       {
      //         headers: {
      //           "Content-Type": "multipart/form-data",
      //         },
      //       }
      //     );
      //   }
      //   if ((res && res["data"]["code"] === 201) || res["data"]["code"] === 200) {
      //     let tmpTenantData = { ...state };
      //     tmpTenantData.id = res.data.results.data.id;
      //     setState(tmpTenantData);
      //     // props.pageStatus("listTenant")
      //     if (addNew.tenantInformation) {
      //       pageStatus("homeDetails");
      //     }
      //     if (addNew.homeDetails) {
      //       pageStatus("documents");
      //     }
      //     if (addNew.documents) {
      //       props.pageStatus("listTenant");
      //     }
      //     dispatch(
      //       showToaster({
      //         dialogBgColor: "bg-success",
      //         dialogMsg: "Tenant Updated successfully",
      //         showDialog: true,
      //         timer: "3000",
      //       })
      //     );
      //     dispatch(showLoader({ showLoader: false }));
      //   } else {
      //     setLoading(false);
      //     dispatch(
      //       showToaster({
      //         dialogBgColor: "bg-danger",
      //         //   dialogMsg: e.response.data.message,
      //         dialogMsg: "Error, Try again",
      //         showDialog: true,
      //         timer: "5000",
      //       })
      //     );
      //     dispatch(showLoader({ showLoader: false }));
      //   }
    } catch (err: any) {
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    props.parentData(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    props.parentData(event.target.value);
    props.limit(parseInt(event.target.value, 10));
  };
  const fetchTenantById = async (id: any) => {
    let response = await GetApiMethod("user/" + id);
    if (response["data"]["error"] === false) {
      setState(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUnitChange = async (value: any) => {
    setUnitDetailsShow(true);
    setHomeUnitDetails(value);
  };

  return (
    <>
      <Box
        className="my-30 d-flex justify-content-between"
        sx={{ margin: "30px 0" }}
      >
        <Box className="card-title">
          <Typography variant="h5"> New Tenant</Typography>
          <Typography>Tenant Information</Typography>
        </Box>
        <Breadcrumbs
          className="breadcrumb"
          separator={<KeyboardDoubleArrowRightIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Box sx={{ mt: 3 }}>
        {addNew["tenantInformation"] ? (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item lg={12} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">
                  Personal Information
                </Typography>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">First Name</Typography>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Type here"
                  className="form-control"
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                />
                {errors.firstName && touched.firstName && (
                  <Typography className="text-danger">
                    {errors.firstName}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">Last Name</Typography>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Type here"
                  className="form-control"
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                />
                {errors.lastName && touched.lastName && (
                  <Typography className="text-danger">
                    {errors.lastName}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">
                  Contact Number
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Type here"
                  className="form-control"
                  name="phoneNumber"
                  onChange={handleChange}
                  value={values.phoneNumber}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <Typography className="text-danger">
                    {errors.phoneNumber}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">Job</Typography>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Type here"
                  className="form-control"
                  name="job"
                  onChange={handleChange}
                  value={values.job}
                />
                {errors.job && touched.job && (
                  <Typography className="text-danger">{errors.job}</Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">Age</Typography>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Type here"
                  className="form-control"
                  name="age"
                  onChange={handleChange}
                  value={values.age}
                />
                {errors.age && touched.age && (
                  <Typography className="text-danger">{errors.age}</Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">
                  Family Members
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  placeholder="Type here"
                  className="form-control"
                  name="familyMember"
                  onChange={handleChange}
                  value={values.familyMember}
                />
                {errors.familyMember && touched.familyMember && (
                  <Typography className="text-danger">
                    {errors.familyMember}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="poppins-family">Email</Typography>
                <TextField
                  type="email"
                  variant="standard"
                  placeholder="Type here"
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <Typography className="text-danger">
                    {errors.email}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box sx={{ mt: 4 }} className="d-flex">
              <Button
                className="btn-white"
                onClick={() => {
                  props.pageStatus("listTenant");
                }}
              >
                <ChevronLeftIcon className="fs-20" />
                Back
              </Button>
              <Button
                className="btn-danger ms-2"
                type="submit"
                disabled={state.showLoader}
              >
                Save & Proceed Next
              </Button>
            </Box>
          </form>
        ) : addNew["homeDetails"] ? (
          <>
            <TenantHomeDetails
              propertyList={propertyList}
              handleHomeDetailsChange={handleHomeDetailsChange}
              handletenantHomeDetailsChange={handletenantHomeDetailsChange}
              filterUnitList={filterUnitList}
              state={state}
              pageStatus={pageStatus}
              handleUnitChange={handleUnitChange}
              handleDateChange={handleDateChange}
              unitDetailsShow={unitDetailsShow}
              homeUnitDetails={homeUnitDetails}
              tenantID={tenantId}
              allData={allData}
              setPropertyId={setPropertyId}
              setUnitId={setUnitId}
              setFinalData={setFinalData} 
              finalData={finalData ? finalData : null} 
            />
          </>
        ) : addNew["documents"] ? (
          <>
            <DocumentUploads
              pageStatus={pageStatus}
              propPageStatus={props.pageStatus}
              tenantId={tenantId}
              unitId={unitId}
              propertyId={propertyId}
              finalData={finalData}
            />
            {/* <form onSubmit={handlFormSubmit}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <Grid item lg={12} md={4} sm={12} xs={12}>
                  <Typography className="poppins-family">
                    Add New Tenant
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Typography className="poppins-family">Documents</Typography>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    onChange={handleImageChange}
                  >
                    Upload file
                    <VisuallyHiddenInput type="file" multiple />
                  </Button>
                </Grid>
              </Grid>

              <Box sx={{ mt: 4 }} className="d-flex">
                <Button
                  className="btn-white"
                  onClick={() => {
                    pageStatus("homeDetails");
                  }}
                >
                  <ChevronLeftIcon className="fs-20" />
                  Back
                </Button>
                <Button
                  className="btn-danger ms-2"
                  type="submit"
                  disabled={state.showLoader}
                >
                  Save & Proceed Next
                </Button>
              </Box>
            </form> */}
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default AddTenant;
