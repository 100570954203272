import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import IconEdit from "../../Assets/images/icon_edit.svg";
import { isNotEmpty } from "../../Utils/HelperService";
import { formatDate } from "../../Components/Utilities";

const ShowNoticeBoard = (props: any) => {
  const getInitialState = () => ({
    title: "",
    details: "",
    start_date: "",
    end_date: "",
    property_id: "",
    unit_id: "",
  });
  const [state, setState] = useState(getInitialState);
  const [imgArray , setImgArray] = useState([]);

  useEffect(() => {
    setState(props.NoticeBoardData);
    setImgArray(props.NoticeBoardData.FileManagers)
  }, [props.NoticeBoardData]);
  const handleNoticeBoardEdit = (e: any) => {       
    props.pageStatus("editNotice");
    let tmpdata = {
      page: e,
      data: props.NoticeBoardData,
    };
    props.pageData(tmpdata);
  };
  return (
    <>
      <Box
        className="my-30 d-flex justify-content-between"
        sx={{ margin: "30px 0" }}
      >
        <Box className="card-title">
          <Typography variant="h5">Notice Board Information</Typography>
        </Box>
        <ButtonPrimary
          text="Edit Info"
          className=""
          onClick={() => {
            handleNoticeBoardEdit("Add");
          }}
          imgSrc={IconEdit}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box className="d-flex justify-content-between"></Box>
        {imgArray.length > 0 && (
                imgArray.map((file: any) => (
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                  <img 
                  height={"30%"}
                width={"30%"}
                    src={`${process.env.REACT_APP_API_URL}/${file.folderPath}`} 
                    alt="" 
                    key={file.id} 
                  />
                  </Grid>
                ))
              )}
        <Grid container spacing={3} sx={{ mb: 2 }}>
        </Grid>
        <Box className="table-striped table-striped-header">
          <table>
            <tr>
              <th>Title</th>
              <td>
                {state &&
                  (isNotEmpty(state.title) ? state.title : "")}
              </td>
            </tr>
            <tr>
              <th>Detail</th>
              <td>
                {" "}
                {state && isNotEmpty(state.details  )
                  ? state.details   
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Start Date</th>
              <td> {state && isNotEmpty(state.start_date) ? formatDate(state.start_date) : ""}</td>
            </tr>
            <tr>
              <th>End Date</th>
              <td> {state && isNotEmpty(state.end_date) ? formatDate(state.end_date) : ""}</td>
            </tr>
            <tr>
              <th>Property Name</th>
              <td> {state && isNotEmpty(state.property_id) ? state.property_id : ""}</td>
            </tr>
            <tr>
              <th>Unit Name</th>
              <td>
                {" "}
                {state && isNotEmpty(state.unit_id)
                  ? state.unit_id
                  : ""}
              </td>
            </tr>
          </table>
        </Box>

        <Button
          className="btn-white btn-back"
          onClick={() => {
            props.pageStatus("listNotice");
          }}
        >
          <ChevronLeftIcon className="fs-20" />
          Back
        </Button>
      </Box>
    </>
  );
};

export default ShowNoticeBoard;
