import { Select } from '@mui/material';
import { ReactNode } from 'react';
import { Months } from '../Utilities';
import MenuItem from '@mui/material/MenuItem';

interface DropDownProps {
    value: string;
    handleChange: any;
    name: string;
    children: ReactNode; // Define a prop for the menu items
}

export const DropDown = (props: DropDownProps) => {
    const { value, name, handleChange, children } = props;
    return (
        <>
            <Select
                value={value}
                name={name}
                onChange={handleChange}
                fullWidth
                variant="standard"

            >
             {children}
            </Select>
        </>
    )
}