import React, { useCallback, useState } from "react";
import "./SideBar.css";

import { DtoMenuDefinition } from "../../Dto/menu";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../Assets/images/logo.png";
import Menu from "../../Assets/images/menu.svg";
import DashBoard from "../../Assets/images/dashboard.png";
import Apartment from "../../Assets/images/menu-icons/apartment.png";
import ManageAccount from "../../Assets/images/menu-icons/manage_accounts_FILL.png";
import TenantImage from "../../Assets/images/menu-icons/location_away_FILL.png";
import BillingImage from "../../Assets/images/menu-icons/receipt_long_FILL.png";
import CurrencyImage from "../../Assets/images/menu-icons/currency_exchange.png";
import DocumentImage from "../../Assets/images/menu-icons/description_FILL.png";
import AssetImage from "../../Assets/images/menu-icons/info_FILL.png";
import AssetsImage from "../../Assets/images/icon_assets_gray.svg";
import MaintImage from "../../Assets/images/menu-icons/engineering_FILL.png";
import TicketImage from "../../Assets/images/menu-icons/receipt_FILL.png";
import NoticeImage from "../../Assets/images/menu-icons/assignment.png";
import SelectedDashBoard from "../../Assets/images/menu-icons/selected-dashboard.png";
import SelectedUserMenu from "../../Assets/images/menu-icons/manage_accounts_selected.png";
import ApartmentSelected from "../../Assets/images/menu-icons/apartment_selected.png";
import TenantSelected from "../../Assets/images/menu-icons/location_away_selected.png";
import BillingImageSelected from "../../Assets/images/menu-icons/receipt_long_FILL_selected.png";
import CurrencyImageSelected from "../../Assets/images/menu-icons/currency_exchange_Selected.png";
import DocumentImageSelected from "../../Assets/images/menu-icons/description_FILL_selected.png";
import MaintSelectedImage from "../../Assets/images/menu-icons/engineering_FILL_Selected.png";
import TicketSelectedImage from "../../Assets/images/menu-icons/receipt_FILL_selected.png";
import NoticeSelectedImage from "../../Assets/images/menu-icons/assignment_selected.png";
import SettingsSelectedIcons from "../../Assets/images/menu-icons/settings.png";
import SettingsIcons from "../../Assets/images/menu-icons/settings_FILL.png";
import AssetsImageSelected from "../../Assets/images/icon_assets_red.svg";
import VisitorIcon from "../../Assets/images/visitor_color_profile.png";
import AmenitiesImage from "../../Assets/images/amenitieswoc.png";
import AmenitiesIcon from "../../Assets/images/amenities2x.png";
import Car from "../../Assets/images/car.png";
import CarColor from "../../Assets/images/car-orange.png";

import Header from "../Header/Header";

const drawerWidth = 345;

interface SideBarProps {
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuArray: DtoMenuDefinition[] = [
  {
    name: "Dashboard",
    redirectTo: "/dashboard",
    permission: "super-admin",
    icon: DashBoard,
    selectedIcon: SelectedDashBoard,
  },
  {
    name: "User Management",
    redirectTo: "/user/table",
    permission: "admin",
    icon: ManageAccount,
    selectedIcon: SelectedUserMenu,
  },
  {
    name: "Property",
    redirectTo: "/property",
    permission: "admin",
    icon: Apartment,
    selectedIcon: ApartmentSelected,
  },
  {
    name: "Tenants",
    redirectTo: "/tenant",
    permission: "super-admin",
    icon: TenantImage,
    selectedIcon: TenantSelected,
  },
  {
    name: "Billing Center",
    redirectTo: "/billing-center", 
    permission: "admin",
    icon: BillingImage,
    selectedIcon: BillingImageSelected,
  },
  {
    name: "Visitor Management",
    redirectTo: "/visitor-management",
    permission: "admin",
    icon: TenantImage,
    selectedIcon: VisitorIcon,
  },
  {
    name: "Amenities",
    redirectTo: "/amenities",
    permission: "admin",
    icon: AmenitiesImage,
    selectedIcon: AmenitiesIcon,
  },
  {
    name: "Parking",
    redirectTo: "/parking",
    permission: "admin",
    icon: CarColor,//AmenitiesImage,
    selectedIcon: Car,
  },
  // {
  //   name: "Invoice Type",
  //   redirectTo: "/invoice-type",
  //   permission: "admin",
  //   icon: BillingImage,
  //   selectedIcon: BillingImageSelected,
  // },
  {
    name: "Expenses",
    redirectTo: "/expenses",
    permission: "admin",
    icon: CurrencyImage,
    selectedIcon: CurrencyImageSelected,
  },
  {
    name: "Document",
    redirectTo: "/document",
    permission: "admin",
    icon: DocumentImage,
    selectedIcon: DocumentImageSelected,
  },
  {
    name: "Assets",
    redirectTo: "/assets",
    permission: "admin",
    icon: AssetsImage,
    selectedIcon: AssetsImageSelected,
  },
  // {
  //   name: "Information",
  //   redirectTo: "/dashboard",
  //   permission: "super-admin",
  //   icon: AssetImage,
  //   selectedIcon: AssetImage,
  // },
  {
    name: "Supplier",
    redirectTo: "/maintainers",
    permission: "admin",
    icon: MaintImage,
    selectedIcon: MaintSelectedImage,
  },
  {
    name: "Tickets",
    redirectTo: "/helpDesk",
    permission: "admin",
    icon: TicketImage,
    selectedIcon: TicketSelectedImage,
  },
  {
    name: "Maintenance",
    redirectTo: "/maintenance",
    permission: "admin",
    icon: TicketImage,
    selectedIcon: TicketSelectedImage,
  },
  //  {
  //   name: "Ticket Type",
  //   redirectTo: "/helpdesk-type",
  //   permission: "admin",
  //   icon: TicketImage,
  //   selectedIcon: TicketSelectedImage,
  // },
  {
    name: "Notice Board",
    redirectTo: "/notice-board",
    permission: "admin",
    icon: NoticeImage,
    selectedIcon: NoticeSelectedImage,
  },
  {
    name: "Settings",
    redirectTo: "/settings",
    permission: "admin",
    icon: SettingsIcons,
    selectedIcon: SettingsSelectedIcons,
  },
  // {
  //   name: "HelpDesk Type",
  //   redirectTo: "/helpdesk-type",
  //   permission: "admin",
  //   icon: TicketImage,
  //   selectedIcon: TicketSelectedImage,
  // },
  // {
  //   name: "Report",
  //   redirectTo: "/report",
  //   permission: "admin",
  //   icon: MaintImage,
  //   selectedIcon: MaintSelectedImage,
  // },
  // {
  //   name: "Setting",
  //   redirectTo: "/user/table",
  //   permission: "admin",
  //   icon: TicketImage,
  //   selectedIcon: TicketImage,
  // },
  // {
  //   name: "Profile",
  //   redirectTo: "/user/table",
  //   permission: "admin",
  //   icon: NoticeImage,
  //   selectedIcon: NoticeImage,
  // },
];

export default function SideBar({ openMenu, setOpenMenu }: SideBarProps) {
  let navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = useCallback(
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu((previousState: boolean) => !previousState);
    },
    [setOpenMenu]
  );

  const getMenuList = useCallback(() => {
    return (
      <>
        <div
          className="list-group"
          onClick={toggleDrawer()}
          onKeyDown={toggleDrawer()}
        >
          {MenuArray.map((menu, index) => (
            <button
              key={index}
              type="button"
              className={
                "list-group-item list-group-item-action " +
                (location.pathname === menu.redirectTo ? "active" : "")
              }
              aria-current="true"
              onClick={() => {
                navigate(menu.redirectTo);
              }}
            >
              {menu.icon}
              {menu.name}
            </button>
          ))}
        </div>
      </>
    );
  }, [location.pathname, navigate, toggleDrawer]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
          style={{ boxShadow: "none" }}
        >
          <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className="side-bar-logo ">
            <img src={Menu} alt="image" width={20} height={20} />
            <img src={Logo} alt="image" className="drawer-logo" onClick={()=>{navigate('/')}} />
          </div>
          <List className="p-0 m-0">
            {MenuArray.map((menu, index) => (
              <Link to={menu.redirectTo} className="navigateLink" key={index}>
                <ListItem
                  key={index}
                  disablePadding
                  className={`${
                    location.pathname === menu.redirectTo &&
                    "selected-dashboard"
                  }`}
                >
                  <ListItemButton
                    className={`  "medium-font" ${
                      location.pathname === menu.redirectTo
                        ? "selected-menu"
                        : "non-selected-menu"
                    }`}
                  >
                    {/* <ListItemIcon> */}
                    {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    <img
                      src={`${
                        location.pathname === menu.redirectTo
                          ? menu.selectedIcon
                          : menu.icon
                      }`}
                      alt="FB"
                      className={`drawer-icons`}
                    />
                    {/* </ListItemIcon> */}
                    {/* */}
                    <ListItemText>
                      <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                        {menu.name}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
        </Drawer>
      </Box>
    </>
  );
}
//
