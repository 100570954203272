import React from 'react';

export const LoadingComponent = () => {
  return (
    <div className="container">
      <div style={{ "paddingTop": "50vh"}}>
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};
