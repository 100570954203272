import React, { useState , useContext } from 'react';
import { Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { VisitorReqDetails } from './VisitorRequest';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { convertToAmPm } from '../../Components/CommonFormik';

const AntTabs = styled(Tabs)({
    '& .MuiTabs-flexContainer':{
        marginLeft:'0px !important'
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "#FF7D73",
      },
})

const RequestDetails = () => {
    const visitorReq = useContext(VisitorReqDetails);
    const visitor = visitorReq.visitReqDetails;

    return (
        <>
            <Typography className="fw-bold mt-4">Request Details</Typography>
            <table className='table table-striped mt-1'>
                <tbody>
                    <tr>
                        <td className='fw-bold p-3'>Purpose of Visit</td>
                        <td className="p-3">{visitor["purpose_of_visit"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Visitor Name</td>
                        <td className='p-3'>{visitor["visitor_name"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Email</td>
                        <td className='p-3'>{visitor["email"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Phone Number</td>
                        <td className='p-3'>{visitor["phone"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Parking Requirement</td>
                        <td className='p-3'>{visitor["parking_requirement"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Vehicle No/Make/Model/Color</td>
                        <td className='p-3'>{visitor["vehicle_no"]}/{visitor["make"]}/{visitor["model"]}/{visitor["color"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Date</td>
                        <td className='p-3'>{visitor["date"] && visitor["date"].split("T")[0]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Timings</td>
                        <td className='p-3'>{visitor["timing"] && visitor["timing"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Room</td>
                        <td className='p-3'>{visitor["room"] && visitor["room"]["name"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Host Name</td>
                        <td className='p-3'>{visitor["tenantDetail"] !== null && visitor["tenantDetail"]["firstName"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Description</td>
                        <td className='p-3'>{visitor["description"]}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold p-3'>Additional Requirement</td>
                        <td className='p-3'>
                           {visitor["visitorAdditionalRequirement"] !== null && 
                                visitor["visitorAdditionalRequirement"].map((val,key) => {
                                    return val.additionalRequirement.name+", "
                                })
                            } 
                        </td>
                    </tr> 
                </tbody>
            </table>
            <Typography className="fw-bold">Entry Details</Typography>
            <Box>
                <Typography className="fw-bold">Access Code</Typography>
                <Typography>{visitor["access_code"]}</Typography>
            </Box> 
        </>
    )
}

const VisitorHistory = () => {
    const visitorReq = useContext(VisitorReqDetails);
    const visitor = visitorReq.history;
    return (
        <>
            <table className='table table-striped mt-3'>
                <thead>
                    <tr>
                        <th>Purpose of visit</th>
                        <th>Date</th>
                        <th>Timings</th>
                        <th>Room</th>
                        <th>Description</th>
                        <th>Vehicle Details</th>
                        <th>Parking No</th>
                        <th>Tenant/Incharge</th>
                    </tr>
                </thead>
                <tbody>
                    {visitor.length !== 0 && visitor.map((val,key) => {
                        return (
                            <tr>
                                <td>{val.purpose_of_visit}</td>
                                <td>{val.date && val.date.split("T")[0]}</td>
                                <td>{val.timing}</td>
                                <td>{val.room && val.room.name}</td>
                                <td>{val.description}</td>
                                <td>{val.vehicle_no}</td>
                                <td>{val.parkingDetail && val.parkingDetail.parking_no}</td>
                                <td>{val.tenantDetail && val.tenantDetail.firstName + " " + val.tenantDetail.lastName}</td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </>
    )
}

const VisitorRequestDetails = () => {
    const [tabValue, setTabValue] = useState(0);
    const visitor = useContext(VisitorReqDetails);
    const handleChange = (e,newValue) => {
        setTabValue(newValue)
    }

    return (
        <>
            <Typography className="fw-bold fs-4 mt-4"><ArrowBackIcon size={25} className='me-1' sx={{cursor:'pointer'}} onClick={visitor.onBackVisitorReqList}/>Visitor Request Details</Typography>
            <Box sx={{width:'100%'}}>
                <AntTabs value={tabValue} onChange={handleChange} >
                    <Tab label="Request Details" className="tab-menu"/>
                    <Tab label="Visitor History" className="tab-menu"/>
                </AntTabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <RequestDetails />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <VisitorHistory />
            </CustomTabPanel>
        </>
    )
}

export default VisitorRequestDetails