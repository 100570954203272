import React, { useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AntTabs, AntTab } from "../../Components/Tab/AntTab";
import Grid from "@mui/material/Grid";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import AddIcon from "../../Assets/images/add_plus_icon.svg"
import FormControl from "@mui/material/FormControl";
import AddMaintenance from "./AddMaintenance";
// import AllNoticeBoard from "./";  
// import ShowMaintenanceBoard from "./ShowMaintenanceBoard"
// import EditMaintenanceBoard from "./EditMaintenanceBoard";

interface NoticeBoardOptions {
  listMaintenance: boolean;
  addMaintenance: boolean;
  addRequest: boolean;
  showMaintenance: boolean;
  assignVendors: boolean;
  editMaintenance: boolean;
  [key: string]: boolean; // Index signature
}


const Maintenance = () => {
  const [tabValue, setTabValue] = useState(0);
  const [addNew, setAddNew] = useState({
    listMaintenance: true,
    addMaintenance: false,
    addRequest: false,
    showMaintenance: false,
    assignVendors: false,
    editMaintenance: false,
  });
  const [MaintenanceData, setMaintenanceData] = useState("");
  const [listMaintenance, setListMaintenance] = useState(true);
  const [search, setSearch] = useState("");


  const handlePageDataChange = (data: any) => {
    setMaintenanceData(data.data);
  };

  const handleChange = (e: any, val: any) => {
    setTabValue(val);
  };

  const pageStatus = (data: any) => {
    let tmpData: NoticeBoardOptions = { ...addNew };
    for (let prop in tmpData) {
      if (prop !== data) {
        tmpData[prop] = false; // Set all properties to false except the one specified
      } else {
        tmpData[prop] = true;
      }
    }
    setAddNew(tmpData);
  };

  const handleSearchChange = (e: any) => {
    setSearch(e);
  };
  return (
      <>
      <AuthorizedLayout>
        <Card
          variant="outlined"
          sx={{ borderRadius: "8px" }}
          className="border-0 default-page-height"
        >
          <Box className="d-flex justify-content-between align-items-center">
            <Box className="d-flex align-items-center">
             
              <Typography className="psemiboldfont page-header-text">
                Maintenance
              </Typography>
              <AntTabs value={tabValue} onChange={handleChange}>
                <AntTab
                  className="tab-menu"
                  label={
                    (addNew.addMaintenance
                      ? "Add"
                      : addNew.editMaintenance 
                      ? "Edit"
                      : addNew.showMaintenance
                      ? "Show"
                      : "All") + "Maintenance"
                  }
                />
              </AntTabs>
            </Box>
            {addNew["listMaintenance"] && (
              <ButtonPrimary
                text="Add New Maintenance"
                onClick={() => pageStatus("addMaintenance")}
                imgSrc={AddIcon}
              />
            )}
          </Box>
          {/* {!Object.values(addNew).includes(true) && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {listMaintenance ? (
                  <Grid item lg={2} md={6} xs={12} sm={12}>
                    <FormControl variant="standard" className="w-100">
                      <TextField
                        type="text"
                        variant="standard"
                        placeholder="Search here..."
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          handleSearchChange(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Box>
          )} */}
          <Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box>
              <AddMaintenance pageStatus={pageStatus}/>
                {/* {addNew["listMaintenance"] ? (
                  // <AllNoticeBoard
                  //   pageStatus={pageStatus}
                  //   pageData={(e: any) => {
                  //     handlePageDataChange(e);
                  //   }}
                  // />
                ) : addNew["addMaintenance"] ? (
                  <AddMaintenance/>

                ) : addNew["showMaintenance"] ? (
                  // <ShowMaintenanceBoard
                  //   pageStatus={pageStatus}
                  //   pageData={(e: any) => {
                  //     handlePageDataChange(e);
                  //   }}
                  //   MaintenanceData={MaintenanceData}
                  // />

                ) : (
                  addNew["editMaintenance"] && (
                    // <EditMaintenanceBoard
                    //   pageStatus={pageStatus}
                    //   pageData={(e: any) => {
                    //     handlePageDataChange(e);
                    //   }}
                    //   MaintenanceData={MaintenanceData}
                    // />
                  )
                )} */}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
           </CustomTabPanel>
          </Box>
        </Card>
      </AuthorizedLayout>
    </>
  );
};

export default Maintenance;
