import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import { PropertyContext } from './Main';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ViewProperty = () => {
    const property = useContext(PropertyContext);
    return (
        <>
            <Box className="mt-3 d-flex align-items-center">
                <ArrowBackIcon size={25} className="me-1 mb-2" sx={{cursor:'pointer'}} onClick={() => property.setIsViewList(false)}/>
                <h5>Property Details</h5>
            </Box>
            <Box>
                <Box className="d-flex">
                    {property.viewData && property.viewData.property.FileManagers.length !== 0 &&
                        property.viewData.property.FileManagers.map((val,key) => {
                            return (
                                <img src={process.env.REACT_APP_API_URL+'/'+val.folderPath} alt="Property" width={125} height={125} style={{border:'2px solid #e6e3e3',borderRadius:'10px'}} className='me-2'/>
                            )
                        })
                    }
                    <Box className="ms-3">
                        <h5 className='fw-bold'>Unity Enclave</h5>
                        <Typography sx={{color:'gray'}}>{property.viewData.property.address}</Typography>
                        <h5 className='fw-bold mt-3'>Description</h5>
                        <Typography sx={{color:'gray'}}>{property.viewData.property.description}</Typography>
                    </Box>
                </Box>
                <Box className="mt-3">
                    <h6>Image Gallery</h6>
                    <Box className="d-flex flex-wrap">
                        {property.viewData && property.viewData.unit.map((val,key) => {
                            if(val.image){
                                return (<img src={process.env.REACT_APP_API_URL+'/'+val.image} alt={`Unit ${key}`} key={key} width={75} height={75} className='me-3' style={{border:'2px solid #e6e3e3',borderRadius:'10px'}}/>)
                            }
                        })}
                    </Box>
                </Box>
                <Typography className='mt-3'>Property Details</Typography>
                <TableContainer sx={{height:'425px'}}>
                    <Table className='table table-striped mt-3'>
                        <TableBody>
                            <TableRow>
                                <TableCell className="fw-bold p-3">Total Unit</TableCell>
                                <TableCell className="p-3">{property.viewData && property.viewData.property.number_of_unit}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="fw-bold p-3">Available for lease</TableCell>
                                <TableCell className="p-3">{property.viewData && property.viewData.property.number_of_unit}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="fw-bold p-3">Current Tenants</TableCell>
                                <TableCell className="p-3"></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="fw-bold p-3">Average Rent</TableCell>
                                <TableCell className="p-3">{property.viewData && property.viewData.property.lease_amount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="fw-bold p-3">Security Deposit</TableCell>
                                <TableCell className="p-3"></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="fw-bold p-3">Late Fee</TableCell>
                                <TableCell className="p-3"></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="fw-bold p-3">Maintainer Name</TableCell>
                                <TableCell className="p-3"></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography sx={{color:'gray'}} className='mt-3'>All Unit Details</Typography>
                <TableContainer component={Paper}>
                    <Table className='table table-striped mt-1'>
                        <TableHead>
                            <TableRow>
                                <TableCell>SI.NO</TableCell>
                                <TableCell>Unit Name</TableCell>
                                <TableCell>Bedroom</TableCell>
                                <TableCell>Bathroom</TableCell>
                                <TableCell>Kitchen</TableCell>
                                <TableCell>Square Feet</TableCell>
                                <TableCell>Amenities</TableCell>
                                <TableCell>Parking</TableCell>
                                <TableCell>Condition</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Availability</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {property.viewData && property.viewData.unit.map((val,key) =>(
                                <TableRow key={key}>
                                    <TableCell>{key+1}</TableCell>
                                    <TableCell>{val.unit_name}</TableCell>
                                    <TableCell>{val.bedroom}</TableCell>
                                    <TableCell>{val.bath}</TableCell>
                                    <TableCell>{val.kitchen}</TableCell>
                                    <TableCell>{val.square_feet}</TableCell>
                                    <TableCell>{val.amenities}</TableCell>
                                    <TableCell>{val.parking}</TableCell>
                                    <TableCell>{val.condition}</TableCell>
                                    <TableCell>{val.description}</TableCell>
                                    <TableCell>
                                        { val.image && <img src={process.env.REACT_APP_API_URL+'/'+val.image}  alt={`unit${key}`} width="50" height="50"/> }
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default ViewProperty