import React, { useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import {
  Grid,
  InputAdornment,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import IconSearch from "../../Assets/images/search.svg";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import propertyImg from "../../Assets/images/icons/property.png";

import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import AddIcon from "../../Assets/images/add_plus_icon.svg";
import NewProperty1 from "../property/addNewProperty/newProperty1";
import PropertyTable from "../property/tables/tables";
import PropertyTable2 from "../property/tables/tables2";
import Actions from "../../Components/Actions/Actions";
import IconAssets from "../../Assets/images/icon_assets_red.svg";
import NewAsset from "./AddNewAsset";
import SimpleCard from "../Dashboard/SimpleCard";
import DoughnutChart from "../../Components/Chart/DoughnutChart";
import Layer1 from "../../Pages/Dashboard/layer1.svg";
import Layer2 from "../../Pages/Dashboard/layer2.svg";
import Layer3 from "../../Pages/Dashboard/layer3.svg";
import Coins from "../../Pages/Dashboard/coins.svg";
import InvoiceBill from "../../Pages/Dashboard/invoice-bill.svg";
import SubHeading1 from "../property/components/subHeading1";
const AntTabs = styled(Tabs)({
  marginLeft: "15px",
  padding: "0",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D73",
  },
});

const AntTab = styled(Tab)({
  fontSize: "13px",
  fontFamily: "Poppins",
  "&.Mui-selected": {
    color: "#FF7d73",
  },
});

export default function Assets() {
  const [tabValue, setTabValue] = useState(0);
  const [addAsset, setaddAsset] = useState(true);
  const [isAddNewAssetVisible, setisAddNewAssetVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  const handleChange = (e: any, val: any) => {
    setisAddNewAssetVisible(false);
    setTabValue(val);
    setaddAsset(val === 1 ? false : true);
  };

  function show_AddNewAssets() {
    setisAddNewAssetVisible(true);
    setaddAsset(false);
  }

  function backBtnFunc() {
    setisAddNewAssetVisible(false);
    setaddAsset(true);
  }

  const [data, setDate] = useState([
    {
      id: 1,
      asset_name: "INV012345",
      asset_model_no: "Unity Enclave",
      asset_id: "A-123",
      date_of_purchase: "January",
      pincode: "06-04-2024",
      status: "active",
      assign_to: "test",
      description: "description",
    },
    {
      id: 2,
      asset_name: "INV012345",
      asset_model_no: "Unity Enclave",
      asset_id: "A-123",
      date_of_purchase: "January",
      pincode: "06-04-2024",
      status: "deactive",
      assign_to: "test",
      description: "description",
    },
    {
      id: 3,
      asset_name: "INV012345",
      asset_model_no: "Unity Enclave",
      asset_id: "A-123",
      date_of_purchase: "January",
      pincode: "06-04-2024",
      status: "active",
      assign_to: "test",
      description: "description",
    },
    {
      id: 4,
      asset_name: "INV012345",
      asset_model_no: "Unity Enclave",
      asset_id: "A-123",
      date_of_purchase: "January",
      pincode: "06-04-2024",
      status: "deactive",
      assign_to: "test",
      description: "description",
    },
    {
      id: 5,
      asset_name: "INV012345",
      asset_model_no: "Unity Enclave",
      asset_id: "A-123",
      date_of_purchase: "January",
      pincode: "06-04-2024",
      status: "active",
      assign_to: "test",
      description: "description",
    },
  ]);

  return (
    <AuthorizedLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <SimpleCard
            title="Expense"
            color="#24BC94"
            date="Feb 2024"
            amount="R15,000"
            image={Layer1}
            prefix={Coins}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <SimpleCard
            title="Paid Invoice"
            date="Last 30 Days"
            amount="R11,265"
            image={Layer3}
            prefix={InvoiceBill}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <SimpleCard
            title="Open Invoice"
            date="Last 30 Days"
            amount="R21,052"
            image={Layer3}
            prefix={InvoiceBill}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <SimpleCard
            title="Request Raised"
            date="Last 30 Days"
            amount="R21,052"
            image={Layer3}
            prefix={InvoiceBill}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8.5} lg={8.5}>
          <Box className="p-4 bg-white radius-12">
            <Box className="d-flex justify-content-between">
              <Box className="card-title">
                <Typography variant="h5">Request Raised</Typography>
                <Typography>Pending status</Typography>
              </Box>
              <button
                style={{
                  background: "white",
                  border: "1px solid #FF7D73",
                  color: "#FF7d73",
                  padding: "5px 15px 5px 15px",
                  borderRadius: "25px",
                  fontSize: "11px",
                  height: "35px",
                }}
              >
                View all
              </button>
            </Box>

            <TableContainer sx={{ height: "auto !important" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Asset Name</TableCell>
                    <TableCell>Asset Model No</TableCell>
                    <TableCell>Asset ID</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Raised by</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((val: any, key: any) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{val.asset_name}</TableCell>
                        <TableCell>{val.asset_model_no}</TableCell>
                        <TableCell>{val.asset_id}</TableCell>
                        <TableCell>{val.assign_to}</TableCell>
                        <TableCell>{val.description}</TableCell>
                        <TableCell>
                          {val.status === "active" ? (
                            <Typography className="text-success poppins-family d-flex align-items-center">
                              <Box
                                sx={{
                                  width: "5px",
                                  height: "5px",
                                  background: "green",
                                  borderRadius: "100%",
                                  marginRight: "3px",
                                }}
                              />
                              Action
                            </Typography>
                          ) : (
                            <Typography className="text-danger poppins-family d-flex align-items-center">
                              <Box
                                sx={{
                                  width: "5px",
                                  height: "5px",
                                  background: "red",
                                  borderRadius: "100%",
                                  marginRight: "3px",
                                }}
                              ></Box>
                              Deactivate
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Actions
                            name={"More"}
                            data={[
                              { name: "Edit", color: "text-primary" },
                              { name: "Delete", color: "text-danger" },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {data.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8} className="text-center">
                        No Data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
          <DoughnutChart />
        </Grid>
      </Grid>
      <Card elevation={0} sx={{ marginTop: "50px", borderRadius: "12px" }}>
        <Box className="d-flex justify-content-between align-items-center">
          <Box className="d-flex align-items-center p-4">
            <img src={IconAssets} alt="Property" width={20} />
            <Typography className="psemiboldfont page-header-text">
              Assets
            </Typography>
            <AntTabs value={tabValue} onChange={handleChange}>
              <AntTab label="All Assets" className="tab-menu" />
              <AntTab label="Assets Category" className="tab-menu" />
              <AntTab label="Assets Sub Category" className="tab-menu" />
            </AntTabs>
          </Box>
          <Box className="me-4">
            {addAsset ? (
              <ButtonPrimary
                text="Add New Asset"
                onClick={show_AddNewAssets}
                imgSrc={AddIcon}
              />
            ) : null}
          </Box>
        </Box>

        {isAddNewAssetVisible && <NewAsset backBtn={backBtnFunc} />}
        {addAsset && (
          <Box
            className="w-full max-w-400 my-30 mx-4"
            sx={{
              width: "400px",
              margin: "30px 0",
            }}
          >
            <Typography variant="body2">Search</Typography>
            <TextField
              variant="standard"
              placeholder="Type here"
              sx={{
                width: "100%",
              }}
              onChange={(e: any) => {
                setSearchString(e.target.value);
              }}
              className="w-full"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconSearch} width="18px" height="18px" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        {!isAddNewAssetVisible && (
          <>
            <CustomTabPanel value={tabValue} index={0}>
              <Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Asset Name</TableCell>
                        <TableCell>Asset Model No</TableCell>
                        <TableCell>Asset ID</TableCell>
                        <TableCell>Date of Purchase</TableCell>
                        <TableCell>Print Code</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Assign to</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((val: any, key: any) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>{val.asset_name}</TableCell>
                            <TableCell>{val.asset_model_no}</TableCell>
                            <TableCell>{val.asset_id}</TableCell>
                            <TableCell>{val.date_of_purchase}</TableCell>
                            <TableCell>{val.date_of_purchase}</TableCell>
                            <TableCell>
                              {val.status === "active" ? (
                                <Typography className="text-success poppins-family d-flex align-items-center">
                                  <Box
                                    sx={{
                                      width: "5px",
                                      height: "5px",
                                      background: "green",
                                      borderRadius: "100%",
                                      marginRight: "3px",
                                    }}
                                  />
                                  Action
                                </Typography>
                              ) : (
                                <Typography className="text-danger poppins-family d-flex align-items-center">
                                  <Box
                                    sx={{
                                      width: "5px",
                                      height: "5px",
                                      background: "red",
                                      borderRadius: "100%",
                                      marginRight: "3px",
                                    }}
                                  ></Box>
                                  Deactivate
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>{val.assign_to}</TableCell>
                            <TableCell>{val.description}</TableCell>
                            <TableCell>
                              <Actions
                                name={"More"}
                                data={[
                                  { name: "Edit", color: "text-primary" },
                                  { name: "Delete", color: "text-danger" },
                                ]}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {data.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8} className="text-center">
                            No Data
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <Pagination pagination={{ page: 1, limit: 5, count: 5 }} /> */}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              {/* <p>All Units</p> */}
              <PropertyTable2 />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Asset Name</TableCell>
                        <TableCell>Asset Model No</TableCell>
                        <TableCell>Asset ID</TableCell>
                        <TableCell>Date of Purchase</TableCell>
                        <TableCell>Print Code</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Assign to</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((val: any, key: any) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>{val.asset_name}</TableCell>
                            <TableCell>{val.asset_model_no}</TableCell>
                            <TableCell>{val.asset_id}</TableCell>
                            <TableCell>{val.date_of_purchase}</TableCell>
                            <TableCell>{val.date_of_purchase}</TableCell>
                            <TableCell>
                              {val.status === "active" ? (
                                <Typography className="text-success poppins-family d-flex align-items-center">
                                  <Box
                                    sx={{
                                      width: "5px",
                                      height: "5px",
                                      background: "green",
                                      borderRadius: "100%",
                                      marginRight: "3px",
                                    }}
                                  />
                                  Action
                                </Typography>
                              ) : (
                                <Typography className="text-danger poppins-family d-flex align-items-center">
                                  <Box
                                    sx={{
                                      width: "5px",
                                      height: "5px",
                                      background: "red",
                                      borderRadius: "100%",
                                      marginRight: "3px",
                                    }}
                                  ></Box>
                                  Deactivate
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>{val.assign_to}</TableCell>
                            <TableCell>{val.description}</TableCell>
                            <TableCell>
                              <Actions
                                name={"More"}
                                data={[
                                  { name: "Edit", color: "text-primary" },
                                  { name: "Delete", color: "text-danger" },
                                ]}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {data.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8} className="text-center">
                            No Data
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <Pagination pagination={{ page: 1, limit: 5, count: 5 }} /> */}
              </Box>
            </CustomTabPanel>
          </>
        )}
      </Card>
      {/* <Link to="/customer">Go to dashboard</Link> */}
    </AuthorizedLayout>
    // {/* // </UnAuthorizedLayout> */}
  );
}
